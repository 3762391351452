import { useCombobox } from "downshift";
import { produce } from "immer";
import { isEqual, uniq } from "lodash-es";
import { useId, useMemo, useReducer, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import { type OrderFilters } from "elevar-common-ts/src/apiTypes";
import {
  allPredefinedChannels,
  type Channel,
  facebookChannel,
  generalBestPracticeChannelsToAllow,
  generalBestPracticeChannelsToBlock,
  popularPredefinedChannelsToAllow,
  popularPredefinedChannelsToBlock,
  reChargeChannel,
  unifiedCheckoutChannel,
  userFacingPredefinedChannels
} from "elevar-common-ts/src/channels";
import {
  assertUnreachable,
  typedFromEntries
} from "elevar-common-ts/src/utils";

import { ButtonDropdown } from "elevar-design-system/src/buttons/ButtonDropdown";
import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconChevronDown,
  IconCircledPlus,
  IconCross
} from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LabeledRadioText } from "elevar-design-system/src/labeledRadios/LabeledRadioText";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { Option } from "elevar-design-system/src/Option";
import { scrollbarMixin } from "elevar-design-system/src/scrollbar";
import { useDownshiftEnvironment } from "elevar-design-system/src/shadowRootHandlers";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { PageCard } from "../../components/PageCard";
import { type ConfigMutationOptions } from "../../context/SetupFlowDetails";
import { type Destination } from "./data";
import {
  firstTimeSubscriptionTagName,
  recurringSubscriptionTagName
} from "./shared";
import { StepSection, type StepSectionProps } from "./StepSection";

/* ========================================================================== */

type FiltersCondition = OrderFilters["condition"] | "SEND_ALL" | null;

type FilterType =
  | "SALES_CHANNEL"
  | "APP_ID"
  | "ORDER_TAG"
  | "ORDER_GATEWAY"
  | "CUSTOMER_TAG";

type FiltersReducerItem = {
  type: FilterType;
  value: string;
  fromSubscriptionStep?: boolean;
};

type FiltersReducerStateType = {
  condition: FiltersCondition;
  items: Array<FiltersReducerItem>;
};

type FiltersReducerContextType = {
  usesUnifiedCheckout: boolean;
  subscriptionTagName: string | null;
  bestPracticeChannelsToBlock: Array<Channel>;
  bestPracticeChannelsToAllow: Array<Channel>;
};

type GetInitialFiltersStateArgs = {
  initialFilters: OrderFilters;
  isStepCompleted: boolean;
  context: FiltersReducerContextType;
};

const salesChannelToItem = (channel: string): FiltersReducerItem => {
  return { type: "SALES_CHANNEL", value: channel };
};
const orderTagToItem = (tag: string): FiltersReducerItem => {
  return { type: "ORDER_TAG", value: tag };
};
const orderGatewayToItem = (gateway: string): FiltersReducerItem => {
  return { type: "ORDER_GATEWAY", value: gateway };
};
const customerTagToItem = (tag: string): FiltersReducerItem => {
  return { type: "CUSTOMER_TAG", value: tag };
};
const appIdToItem = (appId: string): FiltersReducerItem => {
  return { type: "APP_ID", value: appId };
};

const getInitialFiltersState = ({
  initialFilters,
  isStepCompleted,
  context
}: GetInitialFiltersStateArgs): FiltersReducerStateType => {
  const someFiltersActive =
    initialFilters.channels.length > 0 ||
    initialFilters.tags.length > 0 ||
    initialFilters.gateways.length > 0 ||
    initialFilters.customerTags.length > 0 ||
    initialFilters.appIds.length > 0;

  return {
    condition: isStepCompleted
      ? initialFilters.condition === "ALLOW" || someFiltersActive
        ? initialFilters.condition
        : "SEND_ALL"
      : null,
    items: [
      ...initialFilters.channels
        .map(salesChannelToItem)
        .map(item =>
          item.value === unifiedCheckoutChannel.code ||
          item.value === reChargeChannel.code
            ? { ...item, fromSubscriptionStep: true }
            : item
        ),
      ...initialFilters.tags
        .map(orderTagToItem)
        .map(item =>
          item.value === firstTimeSubscriptionTagName ||
          item.value === context.subscriptionTagName ||
          item.value === recurringSubscriptionTagName
            ? { ...item, fromSubscriptionStep: true }
            : item
        ),
      ...initialFilters.gateways.map(orderGatewayToItem),
      ...initialFilters.customerTags.map(customerTagToItem),
      ...initialFilters.appIds.map(appIdToItem)
    ]
  };
};

type AddFilter = { type: FilterType };
type UpdateFilter = { index: number; value: string };
type RemoveFilter = { index: number };

type FiltersReducerActionType =
  | { type: "SET_CONDITION"; payload: FiltersCondition }
  | { type: "RESET_FILTERS" }
  | { type: "ADD_FILTER"; payload: AddFilter }
  | { type: "UPDATE_FILTER"; payload: UpdateFilter }
  | { type: "REMOVE_FILTER"; payload: RemoveFilter };

const filtersReducer = (
  state: FiltersReducerStateType,
  action: FiltersReducerActionType,
  context: FiltersReducerContextType,
  initial: FiltersReducerStateType
): FiltersReducerStateType => {
  if (action.type === "SET_CONDITION" || action.type === "RESET_FILTERS") {
    const newCondition =
      action.type === "SET_CONDITION" ? action.payload : state.condition;

    const subscriptionItemKeys = [
      "unifiedCheckout",
      "reCharge",
      "first",
      "recurring"
    ] as const;

    const possibleSubscriptionItems = {
      unifiedCheckout: {
        type: "SALES_CHANNEL",
        value: unifiedCheckoutChannel.code,
        fromSubscriptionStep: true
      },
      reCharge: {
        type: "SALES_CHANNEL",
        value: reChargeChannel.code,
        fromSubscriptionStep: true
      },
      first: {
        type: "ORDER_TAG",
        value: firstTimeSubscriptionTagName,
        fromSubscriptionStep: true
      },
      recurring: {
        type: "ORDER_TAG",
        value: context.subscriptionTagName ?? recurringSubscriptionTagName,
        fromSubscriptionStep: true
      }
    } satisfies Record<
      (typeof subscriptionItemKeys)[number],
      FiltersReducerItem
    >;

    const presentSubscriptionItems = typedFromEntries(
      subscriptionItemKeys.map(key => {
        const item = initial.items.find(item =>
          isEqual(item, possibleSubscriptionItems[key])
        );
        return [key, item ? { ...item, fromSubscriptionStep: true } : null];
      })
    );

    const bestPracticeItemsForNewCondition = (
      newCondition === "ALLOW"
        ? context.bestPracticeChannelsToAllow
        : newCondition === "BLOCK"
          ? context.bestPracticeChannelsToBlock
          : []
    ).map(channel => salesChannelToItem(channel.code));

    const subscriptionItemsForNewCondition =
      (initial.condition === "ALLOW" && newCondition === "ALLOW") ||
      (initial.condition === "BLOCK" && newCondition === "BLOCK") ||
      (initial.condition === "BLOCK" && newCondition === "SEND_ALL")
        ? [
            presentSubscriptionItems.unifiedCheckout,
            presentSubscriptionItems.reCharge,
            presentSubscriptionItems.first,
            presentSubscriptionItems.recurring
          ].filter(v => v !== null)
        : (initial.condition === "ALLOW" && newCondition === "BLOCK") ||
            (initial.condition === "BLOCK" && newCondition === "ALLOW") ||
            (initial.condition === "SEND_ALL" && newCondition === "ALLOW")
          ? context.usesUnifiedCheckout
            ? [
                ...(!presentSubscriptionItems.unifiedCheckout
                  ? [possibleSubscriptionItems.unifiedCheckout]
                  : []),
                ...(!presentSubscriptionItems.reCharge
                  ? [possibleSubscriptionItems.reCharge]
                  : []),
                ...(!presentSubscriptionItems.recurring
                  ? [possibleSubscriptionItems.recurring]
                  : [])
              ]
            : context.subscriptionTagName !== null
              ? [
                  ...(!presentSubscriptionItems.first
                    ? [possibleSubscriptionItems.first]
                    : []),
                  ...(!presentSubscriptionItems.recurring
                    ? [possibleSubscriptionItems.recurring]
                    : [])
                ]
              : []
          : [];

    return {
      condition: newCondition,
      items: [
        ...bestPracticeItemsForNewCondition,
        ...subscriptionItemsForNewCondition
      ]
    };
  } else {
    switch (action.type) {
      case "ADD_FILTER":
        return {
          ...state,
          items: [...state.items, { type: action.payload.type, value: "" }]
        };
      case "UPDATE_FILTER":
        return {
          ...state,
          items: produce(state.items, draft => {
            draft[action.payload.index] = {
              type: draft[action.payload.index]!.type,
              value: action.payload.value
            };
          })
        };
      case "REMOVE_FILTER":
        return {
          ...state,
          items: state.items.filter(
            (_, index) => index !== action.payload.index
          )
        };
    }
  }
};

type InnerFiltersReducer = React.Reducer<
  FiltersReducerStateType,
  FiltersReducerActionType
>;

const useFiltersReducer = (args: GetInitialFiltersStateArgs) => {
  const initial = useMemo(() => {
    return getInitialFiltersState(args);
  }, [args]);

  return useReducer<InnerFiltersReducer>(
    (...rArgs) => filtersReducer(...rArgs, args.context, initial),
    initial
  );
};

/* -------------------------------------------------------------------------- */

const docHref =
  "https://docs.getelevar.com/docs/how-to-block-orders-from-being-sent-via-my-server-side-integration";

type FilterTransactionsProps = {
  isLoading: boolean;
  isStepCompleted: boolean;
  setupGuideHref: StepSectionProps["setupGuideHref"];
  destinationName: Destination["name"];
  initialFilters: OrderFilters;
  usesUnifiedCheckout: boolean;
  subscriptionTagName: string | null;
  onSave: (
    data: { orderFilters: Partial<OrderFilters> },
    options: ConfigMutationOptions
  ) => void;
};

export const FilterTransactions: React.FC<FilterTransactionsProps> = ({
  isLoading,
  isStepCompleted,
  setupGuideHref,
  destinationName,
  initialFilters,
  usesUnifiedCheckout,
  subscriptionTagName,
  onSave
}) => {
  const theme = useTheme();
  const radioGroupName = useId();

  const bestPracticeChannelsToBlock = [
    ...generalBestPracticeChannelsToBlock,
    ...(destinationName === "Facebook" ? [facebookChannel] : [])
  ];
  const bestPracticeChannelsToAllow = generalBestPracticeChannelsToAllow;

  const [state, dispatch] = useFiltersReducer({
    initialFilters,
    isStepCompleted,
    context: {
      usesUnifiedCheckout,
      subscriptionTagName,
      bestPracticeChannelsToBlock,
      bestPracticeChannelsToAllow
    }
  });

  const setCondition = (condition: FiltersCondition) => {
    dispatch({ type: "SET_CONDITION", payload: condition });
  };

  const newFilters: OrderFilters = {
    condition: state.condition === "ALLOW" ? "ALLOW" : "BLOCK",
    channels: state.items
      .filter(item => item.type === "SALES_CHANNEL")
      .map(item => item.value.trim()),
    tags: state.items
      .filter(item => item.type === "ORDER_TAG")
      .map(item => item.value.trim()),
    gateways: state.items
      .filter(item => item.type === "ORDER_GATEWAY")
      .map(item => item.value.trim()),
    customerTags: state.items
      .filter(item => item.type === "CUSTOMER_TAG")
      .map(item => item.value.trim()),
    appIds: state.items
      .filter(item => item.type === "APP_ID")
      .map(item => item.value.trim())
  };

  const areFilterFilledIn = !state.items.some(i => i.value === "");

  const areFiltersUnique =
    newFilters.channels.length === uniq(newFilters.channels).length &&
    newFilters.tags.length === uniq(newFilters.tags).length &&
    newFilters.gateways.length === uniq(newFilters.gateways).length &&
    newFilters.customerTags.length === uniq(newFilters.customerTags).length &&
    newFilters.appIds.length === uniq(newFilters.appIds).length;

  const addedFiltersIncludeSubscriptionFilters = state.items.some(
    item =>
      !item.fromSubscriptionStep &&
      ((item.type === "SALES_CHANNEL" &&
        item.value === unifiedCheckoutChannel.code) ||
        (item.type === "SALES_CHANNEL" &&
          item.value === reChargeChannel.code) ||
        (item.type === "ORDER_TAG" &&
          item.value === firstTimeSubscriptionTagName) ||
        (item.type === "ORDER_TAG" && item.value === subscriptionTagName) ||
        (item.type === "ORDER_TAG" &&
          item.value === recurringSubscriptionTagName))
  );

  const filterFieldErrors =
    !areFilterFilledIn ||
    !areFiltersUnique ||
    addedFiltersIncludeSubscriptionFilters;

  const onSaveWithArgs = () => {
    onSave({ orderFilters: newFilters }, { disableGtmReimportTrigger: true });
  };

  return (
    <Wrapper>
      <PageCard>
        <StepSection
          title="Would you like to filter some transactions?"
          setupGuideHref={setupGuideHref}
          description={
            <Explainer>
              You can choose to filter orders when sending data to this
              destination. For example, you might want to only allow web channel
              orders, or block all point of sale channel orders.
            </Explainer>
          }
          media={{
            type: "VIDEO",
            url: "https://player.vimeo.com/video/669140422"
          }}
        >
          <SectionInnerWrapper>
            <div>
              <LabeledRadioText
                groupName={radioGroupName}
                isSelected={state.condition === "SEND_ALL"}
                setIsSelected={() => setCondition("SEND_ALL")}
                text="No, Send all"
                isDisabled={isLoading}
              />
              <LabeledRadioText
                groupName={radioGroupName}
                isSelected={state.condition === "BLOCK"}
                setIsSelected={() => setCondition("BLOCK")}
                text="Yes, Block some transactions"
                isDisabled={isLoading}
                tag={{ text: "Recommended", color: theme.palette.green }}
              />
              <LabeledRadioText
                groupName={radioGroupName}
                isSelected={state.condition === "ALLOW"}
                setIsSelected={() => setCondition("ALLOW")}
                text="Yes, allow specific transactions"
                isDisabled={isLoading}
              />
            </div>
            {state.condition === "SEND_ALL" ? (
              <Actions1Wrapper>
                <ButtonPrimary
                  variant="SMALL"
                  state={isLoading ? "LOADING" : "IDLE"}
                  onClick={onSaveWithArgs}
                >
                  {isStepCompleted ? "Save" : "Save & Continue"}
                </ButtonPrimary>
              </Actions1Wrapper>
            ) : null}
          </SectionInnerWrapper>
        </StepSection>
      </PageCard>
      {state.condition === "BLOCK" || state.condition === "ALLOW" ? (
        <PageCard>
          <StepSection
            title={
              state.condition === "BLOCK"
                ? "Block some transactions"
                : "Allow specific transactions"
            }
            description={
              <Explainer>
                {state.condition === "BLOCK" ? (
                  <p>
                    We recommend blocking exchange & historical import orders
                    from being sent to destinations - this is what our best
                    practices selection includes. You can also customize what
                    else to block if needed (point of sale orders, draft orders,
                    etc.).
                  </p>
                ) : (
                  <p>
                    Our best practices selection includes the "web" sales
                    channel to allow orders created on your website to be sent
                    to this destination. You can also customize what else to
                    allow if needed (point of sale orders, draft orders, etc.).
                  </p>
                )}
                <StyledLinkExternal
                  href={docHref}
                  text="How do I find channel codes and tag/gateway names?"
                />
              </Explainer>
            }
          >
            <FilterSelector
              state={state}
              dispatch={dispatch}
              subscriptionTagName={subscriptionTagName}
              bestPracticeChannelsToBlock={bestPracticeChannelsToBlock}
              bestPracticeChannelsToAllow={bestPracticeChannelsToAllow}
              isLoading={isLoading}
            />
          </StepSection>
          <Actions2Wrapper>
            <Tooltip
              placement="right"
              maxWidth={`${theme.gridBase * 39.5}px`}
              text={
                !areFilterFilledIn
                  ? "Please fill out all fields"
                  : !areFiltersUnique
                    ? "Please provide unique values per filter type"
                    : addedFiltersIncludeSubscriptionFilters
                      ? "Please remove any subscription filters - you can manage subscriptions in the Subscriptions step"
                      : ""
              }
              disabled={!filterFieldErrors}
            >
              <Actions2TooltipInner>
                <ButtonPrimary
                  variant="SMALL"
                  state={
                    filterFieldErrors
                      ? "DISABLED"
                      : isLoading
                        ? "LOADING"
                        : "IDLE"
                  }
                  onClick={onSaveWithArgs}
                >
                  {isStepCompleted ? "Save" : "Save & Continue"}
                </ButtonPrimary>
              </Actions2TooltipInner>
            </Tooltip>
          </Actions2Wrapper>
        </PageCard>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > p {
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

const SectionInnerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const Actions1Wrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const Actions2Wrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 3}px;
`;

const Actions2TooltipInner = styled.span`
  display: inline-block;
`;

/* ========================================================================== */

type ChannelSelectMode = "BEST_PRACTICES" | "CUSTOMIZE";

const getInitialSelectMode = (
  state: FiltersReducerStateType,
  bestPracticeChannels: Array<Channel>,
  subscriptionTagName: string | null
): ChannelSelectMode => {
  const salesChannels = state.items
    .filter(i => i.type === "SALES_CHANNEL")
    .map(i => i.value);

  if (!bestPracticeChannels.every(c => salesChannels.includes(c.code))) {
    return "CUSTOMIZE";
  }

  const orderTags = state.items
    .filter(i => i.type === "ORDER_TAG")
    .map(i => i.value);

  const noSubscriptionFiltersActive =
    state.items.length === bestPracticeChannels.length;

  const unifiedCheckoutFiltersActive =
    state.items.length === bestPracticeChannels.length + 3 &&
    salesChannels.includes(unifiedCheckoutChannel.code) &&
    salesChannels.includes(reChargeChannel.code) &&
    orderTags.includes(subscriptionTagName ?? recurringSubscriptionTagName);

  const separateCheckoutFiltersActive =
    subscriptionTagName !== null &&
    ((state.items.length === bestPracticeChannels.length + 1 &&
      (orderTags.includes(subscriptionTagName) ||
        orderTags.includes(firstTimeSubscriptionTagName))) ||
      (state.items.length === bestPracticeChannels.length + 2 &&
        orderTags.includes(subscriptionTagName) &&
        orderTags.includes(firstTimeSubscriptionTagName)));

  if (
    noSubscriptionFiltersActive ||
    unifiedCheckoutFiltersActive ||
    separateCheckoutFiltersActive
  ) {
    return "BEST_PRACTICES";
  } else {
    return "CUSTOMIZE";
  }
};

/* -------------------------------------------------------------------------- */

const option1 = {
  name: "Use Best Practices",
  value: "BEST_PRACTICES"
} as const;

const option2 = {
  name: "Customize",
  value: "CUSTOMIZE"
} as const;

type ChannelWithPopularFlag = Channel & { isPopular: boolean };

type FilterSelectorProps = {
  state: FiltersReducerStateType;
  dispatch: React.Dispatch<FiltersReducerActionType>;
  subscriptionTagName: string | null;
  bestPracticeChannelsToBlock: Array<Channel>;
  bestPracticeChannelsToAllow: Array<Channel>;
  isLoading: boolean;
};

const FilterSelector: React.FC<FilterSelectorProps> = ({
  state,
  dispatch,
  subscriptionTagName,
  bestPracticeChannelsToBlock,
  bestPracticeChannelsToAllow,
  isLoading
}) => {
  const theme = useTheme();

  const bestPracticeChannels =
    state.condition === "ALLOW"
      ? bestPracticeChannelsToAllow
      : bestPracticeChannelsToBlock;

  const [selectMode, setSelectMode] = useState(() =>
    getInitialSelectMode(state, bestPracticeChannels, subscriptionTagName)
  );

  const popularChannels = useMemo(
    () =>
      (state.condition === "ALLOW"
        ? popularPredefinedChannelsToAllow
        : popularPredefinedChannelsToBlock
      ).map<ChannelWithPopularFlag>(c => ({ ...c, isPopular: true })),
    [state.condition]
  );
  const nonPopularChannels = useMemo(
    () =>
      userFacingPredefinedChannels
        .filter(c1 => !popularChannels.some(c2 => c1.code === c2.code))
        .map<ChannelWithPopularFlag>(c => ({ ...c, isPopular: false })),
    [popularChannels]
  );

  const predefinedChannels = [...popularChannels, ...nonPopularChannels];

  const activeChannelCodes = useMemo(
    () =>
      state.items
        .filter(item => item.type === "SALES_CHANNEL")
        .map(item => item.value),
    [state.items]
  );

  const allItemsDisabled = isLoading || selectMode === "BEST_PRACTICES";

  const setValue = (index: number, value: string) => {
    dispatch({ type: "UPDATE_FILTER", payload: { index, value } });
  };

  const addFilter = (type: FilterType) => {
    dispatch({ type: "ADD_FILTER", payload: { type } });
  };

  return (
    <>
      <SelectModeWrapper>
        <Option
          variant="SMALL"
          firstOption={option1}
          secondOption={option2}
          activeOption={selectMode === option1.value ? option1 : option2}
          setActiveOption={option => {
            setSelectMode(option.value);
            if (option === option1) dispatch({ type: "RESET_FILTERS" });
          }}
        />
      </SelectModeWrapper>
      {state.items.map((item, index) => {
        const isDisabled =
          allItemsDisabled || item.fromSubscriptionStep === true;

        return (
          <Tooltip
            key={index}
            placement="top"
            followCursor={true}
            offset={theme.gridBase * 2}
            maxWidth={`${theme.gridBase * 22.5}px`}
            text="This mirrors your choices from the Subscriptions step"
            disabled={allItemsDisabled || !item.fromSubscriptionStep}
            hideOnClick={false}
          >
            <FilterSelectorItem index={index} totalCount={state.items.length}>
              <div>OR</div>
              <div>
                {(() => {
                  switch (item.type) {
                    case "SALES_CHANNEL":
                      return (
                        <ChannelSelector
                          value={item.value}
                          setValue={value => setValue(index, value)}
                          predefined={predefinedChannels}
                          activeCodes={activeChannelCodes}
                          isDisabled={isDisabled}
                        />
                      );
                    case "ORDER_TAG":
                      return (
                        <InputWrapper
                          labelText="Order Tag"
                          disabled={isDisabled}
                          tooltip={{
                            maxWidth: `${theme.gridBase * 29.5}px`,
                            render: () => (
                              <FilterTransactionsTooltipContent>
                                <p>
                                  You can filter orders based on Order Tags.
                                  Order tags must be present at order creation.
                                </p>
                                <StyledLinkExternal
                                  href={docHref}
                                  text="How do I find these?"
                                />
                              </FilterTransactionsTooltipContent>
                            )
                          }}
                        >
                          <InputFieldText
                            variant="SMALL"
                            disabled={isDisabled}
                            value={item.value}
                            onChange={event => {
                              setValue(index, event.target.value);
                            }}
                            spellCheck={false}
                          />
                        </InputWrapper>
                      );
                    case "ORDER_GATEWAY":
                      return (
                        <InputWrapper
                          labelText="Order Gateway"
                          disabled={isDisabled}
                          tooltip={{
                            maxWidth: `${theme.gridBase * 26.5}px`,
                            render: () => (
                              <FilterTransactionsTooltipContent>
                                <p>
                                  You can filter orders based on Order Gateways.
                                </p>
                                <StyledLinkExternal
                                  href={docHref}
                                  text="How do I find these?"
                                />
                              </FilterTransactionsTooltipContent>
                            )
                          }}
                        >
                          <InputFieldText
                            variant="SMALL"
                            disabled={isDisabled}
                            value={item.value}
                            onChange={event => {
                              setValue(index, event.target.value);
                            }}
                            spellCheck={false}
                          />
                        </InputWrapper>
                      );
                    case "CUSTOMER_TAG":
                      return (
                        <InputWrapper
                          labelText="Customer Tag"
                          disabled={isDisabled}
                          tooltip={{
                            maxWidth: `${theme.gridBase * 26.5}px`,
                            render: () => (
                              <FilterTransactionsTooltipContent>
                                <p>
                                  You can filter orders based on Customer Tags.
                                </p>
                                <StyledLinkExternal
                                  href={docHref}
                                  text="How do I find these?"
                                />
                              </FilterTransactionsTooltipContent>
                            )
                          }}
                        >
                          <InputFieldText
                            variant="SMALL"
                            disabled={isDisabled}
                            value={item.value}
                            onChange={event => {
                              setValue(index, event.target.value);
                            }}
                            spellCheck={false}
                          />
                        </InputWrapper>
                      );
                    case "APP_ID":
                      return (
                        <InputWrapper
                          labelText="App ID"
                          disabled={isDisabled}
                          tooltip={{
                            maxWidth: `${theme.gridBase * 26.5}px`,
                            render: () => (
                              <FilterTransactionsTooltipContent>
                                <p>
                                  You can filter orders based on the Shopify App
                                  ID.
                                </p>
                                <StyledLinkExternal
                                  href={docHref}
                                  text="How do I find these?"
                                />
                              </FilterTransactionsTooltipContent>
                            )
                          }}
                        >
                          <InputFieldText
                            variant="SMALL"
                            disabled={isDisabled}
                            value={item.value}
                            onChange={event => {
                              setValue(index, event.target.value);
                            }}
                            spellCheck={false}
                          />
                        </InputWrapper>
                      );
                    default: {
                      throw assertUnreachable(item.type);
                    }
                  }
                })()}
              </div>
              <div>
                <button
                  aria-label={`Remove item ${index + 1}`}
                  disabled={isDisabled || state.items.length === 1}
                  onClick={() => {
                    dispatch({ type: "REMOVE_FILTER", payload: { index } });
                  }}
                >
                  <IconCross size="16px" />
                </button>
              </div>
            </FilterSelectorItem>
          </Tooltip>
        );
      })}
      {selectMode === "CUSTOMIZE" ? (
        <FilterSelectorAddButton
          buttonProps={{ disabled: isLoading }}
          dropdownPlacement="bottom-start"
          dropdownWidthOverride={`${theme.gridBase * 16}px`}
          dropdownSections={[
            {
              options: [
                {
                  value: "Sales Channel",
                  type: "BUTTON",
                  onClick: () => addFilter("SALES_CHANNEL")
                },
                {
                  value: "Order Tag",
                  type: "BUTTON",
                  onClick: () => addFilter("ORDER_TAG")
                },
                {
                  value: "Order Gateway",
                  type: "BUTTON",
                  onClick: () => addFilter("ORDER_GATEWAY")
                },
                {
                  value: "Customer Tag",
                  type: "BUTTON",
                  onClick: () => addFilter("CUSTOMER_TAG"),
                  disabled: state.condition !== "BLOCK",
                  tooltipContent:
                    state.condition !== "BLOCK"
                      ? "Only possible when blocking"
                      : null
                },
                {
                  value: "App ID",
                  type: "BUTTON",
                  onClick: () => addFilter("APP_ID")
                }
              ]
            }
          ]}
        >
          <div>
            <IconCircledPlus size="24px" />
          </div>
          <div>Add Filter</div>
        </FilterSelectorAddButton>
      ) : null}
    </>
  );
};

const SelectModeWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

type FilterSelectorItemProps = {
  index: number;
  totalCount: number;
};

const FilterSelectorItem = styled.div<FilterSelectorItemProps>`
  display: flex;
  align-items: end;
  gap: ${props => props.theme.gridBase * 1.5}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:nth-child(1) {
    ${subheadingStyles};
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;
    display: ${props => (props.totalCount === 1 ? "none" : "block")};
    visibility: ${props => (props.index === 0 ? "hidden" : "visible")};
  }

  > div:nth-child(2) {
    flex: 1;
  }

  > div:nth-child(3) {
    > button {
      ${iconButtonStyles};
      color: ${props => props.theme.palette.grey3};
      margin-bottom: ${props => props.theme.gridBase}px;
    }
  }
`;

const FilterTransactionsTooltipContent = styled.div`
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

const FilterSelectorAddButton = styled(ButtonDropdown)`
  ${iconTextButtonStyles};
  margin-top: ${props => props.theme.gridBase * 2.5}px;
`;

/* ========================================================================== */

const customChannel = {
  name: "Custom",
  code: "Select to add custom channel code",
  isPopular: false
};

type ChannelSelectorProps = {
  value: string;
  setValue: (value: string) => void;
  predefined: Array<ChannelWithPopularFlag>;
  activeCodes: Array<string>;
  isDisabled: boolean;
};

const ChannelSelector: React.FC<ChannelSelectorProps> = ({
  value,
  setValue,
  predefined,
  activeCodes,
  isDisabled
}) => {
  const theme = useTheme();
  const downshiftEnvironment = useDownshiftEnvironment();

  const channelCodeInputRef = useRef<HTMLInputElement | null>(null);

  const selected = useMemo<ChannelWithPopularFlag | null>(() => {
    const selectable = predefined.find(c => c.code === value);
    if (selectable) return selectable;
    const nonSelectable = allPredefinedChannels.find(c => c.code === value);
    if (nonSelectable) return { ...nonSelectable, isPopular: false };
    return value ? customChannel : null;
  }, [predefined, value]);

  const getBaseFilterCondition = (
    c: Channel,
    current: ChannelWithPopularFlag | null
  ) => {
    return !activeCodes.includes(c.code) || c.code === current?.code;
  };

  const [filtered, setFiltered] = useState(() =>
    predefined.filter(c => getBaseFilterCondition(c, selected))
  );

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps
  } = useCombobox({
    environment: downshiftEnvironment,
    items: filtered,
    itemToString: item => item?.name ?? "",
    selectedItem: selected,
    stateReducer: (_state, actionAndChanges) => {
      const { stateChangeTypes } = useCombobox;
      const { changes, type } = actionAndChanges;

      switch (type) {
        case stateChangeTypes.InputChange:
          return { ...changes, highlightedIndex: 0 };
        case stateChangeTypes.InputKeyDownEscape:
        case stateChangeTypes.InputBlur:
          return { ...changes, inputValue: selected?.name ?? "" };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem, inputValue = "" }) => {
      const { stateChangeTypes } = useCombobox;

      if (type === stateChangeTypes.InputChange) {
        const trimmedInputValue = inputValue.trim();
        const term = trimmedInputValue.toLowerCase();
        setFiltered([
          ...predefined.filter(
            c =>
              getBaseFilterCondition(c, selected) &&
              (c.name.toLowerCase().includes(term) || c.code.includes(term))
          ),
          ...(trimmedInputValue !== "" &&
          !predefined.some(c => c.code === trimmedInputValue)
            ? [customChannel]
            : [])
        ]);
      }

      if (
        (type === stateChangeTypes.ItemClick ||
          type === stateChangeTypes.InputKeyDownEnter) &&
        selectedItem
      ) {
        if (selectedItem === customChannel) {
          setValue("");
          channelCodeInputRef.current?.focus();
        } else {
          setValue(selectedItem.code);
        }

        setFiltered(
          predefined.filter(c => getBaseFilterCondition(c, selectedItem))
        );
      }

      if (
        type === stateChangeTypes.InputKeyDownEscape ||
        type === stateChangeTypes.InputBlur
      ) {
        setFiltered(
          predefined.filter(c => getBaseFilterCondition(c, selected))
        );
      }
    }
  });

  const filteredPopular = filtered.filter(c => c.isPopular);
  const filteredNonPopular = filtered.filter(c => !c.isPopular);

  return (
    <ChannelSelectorWrapper>
      <div>
        <InputWrapper labelText="Sales Channel" disabled={isDisabled}>
          <InputFieldText
            {...getInputProps({
              variant: "SMALL",
              disabled: isDisabled,
              placeholder: "Type a channel name or code"
            })}
          />
        </InputWrapper>
        <SelectButton {...getToggleButtonProps()}>
          <IconChevronDown
            size="16px"
            color={isDisabled ? theme.palette.grey5 : theme.palette.grey4}
          />
        </SelectButton>
        <MenuWrapper isOpen={isOpen && !isDisabled}>
          <Menu
            {...getMenuProps({
              scrollable:
                value === "" && filteredPopular.length > 0
                  ? filtered.length > 3
                  : filtered.length > 4
            })}
          >
            {isOpen && !isDisabled ? (
              <>
                {filteredPopular.length > 0 ? (
                  <div>
                    <MenuSectionHeading>Popular Channels</MenuSectionHeading>
                    <ul>
                      {filteredPopular.map((item, index) => {
                        return (
                          <MenuItem
                            key={item.code}
                            isHighlighted={highlightedIndex === index}
                            {...getItemProps({ item, index })}
                          >
                            <div>{item.name}</div>
                            <div>{item.code}</div>
                          </MenuItem>
                        );
                      })}
                    </ul>
                    {filteredPopular.length > 0 ? (
                      <MenuSectionSeperator />
                    ) : null}
                  </div>
                ) : null}
                <div>
                  <ul>
                    {filteredNonPopular.map((item, index) => {
                      const adjustedIndex = index + filteredPopular.length;
                      return (
                        <MenuItem
                          key={item.code}
                          isHighlighted={highlightedIndex === adjustedIndex}
                          {...getItemProps({ item, index: adjustedIndex })}
                        >
                          <div>{item.name}</div>
                          <div>{item.code}</div>
                        </MenuItem>
                      );
                    })}
                  </ul>
                </div>
                {filtered.length === 0 ? (
                  <MenuFooter>
                    You cannot add this code as it is already used in another
                    filter.
                  </MenuFooter>
                ) : null}
              </>
            ) : null}
          </Menu>
        </MenuWrapper>
      </div>
      <div>
        <InputWrapper
          labelText="Channel Code"
          disabled={isDisabled}
          tooltip={{
            maxWidth: `${theme.gridBase * 33.75}px`,
            render: () => (
              <FilterTransactionsTooltipContent>
                <p>
                  This is a unique code assigned to the app that creates the
                  Shopify Order. For instance, the code for online Shopify
                  checkouts is "web".
                </p>
                <StyledLinkExternal
                  href={docHref}
                  text="How do I find a channels code?"
                />
              </FilterTransactionsTooltipContent>
            )
          }}
        >
          <InputFieldText
            ref={channelCodeInputRef}
            variant="SMALL"
            disabled={isDisabled}
            value={value}
            onChange={event => setValue(event.target.value)}
            spellCheck={false}
          />
        </InputWrapper>
      </div>
    </ChannelSelectorWrapper>
  );
};

const ChannelSelectorWrapper = styled.div`
  display: grid;
  grid-template-columns: auto ${props => props.theme.gridBase * 17.5}px;
  gap: ${props => props.theme.gridBase}px;

  > div:first-child {
    position: relative;
  }
`;

const SelectButton = styled.button`
  position: absolute;
  bottom: ${props => props.theme.gridBase * 1.5}px;
  right: ${props => props.theme.gridBase * 1.5}px;
`;

type MenuWrapperProps = {
  isOpen: boolean;
};

const MenuWrapper = styled.div<MenuWrapperProps>`
  position: absolute;
  top: calc(100% + ${props => props.theme.gridBase}px);
  left: 0;
  right: 0;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.palette.grey6};
  background-color: ${props => props.theme.palette.white};
  box-shadow: ${props => props.theme.other.boxShadowDropdown};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  z-index: 2;
`;

type MenuProps = {
  scrollable: boolean;
};

const Menu = styled.div<MenuProps>`
  ${scrollbarMixin};
  ${normalBodyStyles};
  margin: ${props => props.theme.gridBase * 0.5}px;
  overflow-y: auto;
  padding-right: ${props =>
    props.scrollable ? props.theme.gridBase * 0.5 : 0}px;
  max-height: ${props => props.theme.gridBase * 44}px;
`;

const MenuSectionHeading = styled.div`
  ${subheadingStyles};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;
`;

const MenuSectionSeperator = styled.div`
  height: 1px;
  width: calc(100% - ${props => props.theme.gridBase * 3}px);
  background-color: ${props => props.theme.palette.grey6};
  margin-top: ${props => props.theme.gridBase}px;
  margin-bottom: ${props => props.theme.gridBase}px;
  margin-left: ${props => props.theme.gridBase * 1.5}px;
  margin-right: ${props => props.theme.gridBase * 1.5}px;
`;

type MenuItemWrapperProps = {
  isHighlighted: boolean;
};

const MenuItem = styled.li<MenuItemWrapperProps>`
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  background-color: ${props =>
    props.isHighlighted
      ? props.theme.palette.grey8
      : props.theme.palette.white};
  transition: background-color ${props => props.theme.other.transition};
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey2};
  }

  > div:last-child {
    ${smallTextStyles};
    color: ${props => props.theme.palette.grey4};
  }
`;

const MenuFooter = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey4};
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase}px;
  padding-right: ${props => props.theme.gridBase}px;
`;
