import { produce } from "immer";
import { uniq } from "lodash-es";
import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  IconChevronDown,
  IconChevronUp,
  IconCircledPlus,
  IconCog,
  IconCross
} from "elevar-design-system/src/icons";
import { InputFieldSelect } from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { PageCard } from "../../components/PageCard";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { ConsentMode } from "./ConsentMode";
import { destinationSmt as destination } from "./data";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-setup-smartly-pixel-tracking-via-elevar-server-side-destination";

const { SetupFlow, useConfigRequired, useSetupFlowDetails } =
  createSetupFlow().destination(destination);

type DestinationSmtProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
};

export const DestinationSmt: React.FC<DestinationSmtProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      eventsConnectorConfig={eventsConnectorConfig}
      steps={[
        { details: { type: "SETTINGS" } },
        { details: { type: "CONSENT_MODE" } },
        { details: { type: "FILTER_TRANSACTIONS" } },
        { details: { type: "SUBSCRIPTIONS" } }
      ]}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
  }
};

/* ========================================================================== */

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              The Smartly Bearer Token is <span>configured</span>
            </>
          )
        },
        {
          step: 2,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 3,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        },
        {
          step: 4,
          type: "SUBSCRIPTIONS",
          filters: config.orderFilters,
          tagName: config.subscriptionTagName
        }
      ]}
      description={
        <Step0Explainer>
          Guarantee near 100% accuracy for Smartly transactions by configuring
          this server-side integration.
        </Step0Explainer>
      }
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [accessToken, setAccessToken] = useState(config.accessToken);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [enabledChannels, setEnabledChannels] = useState(
    config.dataConfig.enabledChannels
  );
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const areSmartlyFieldsValid = accessToken !== "";

  const areEnabledChannelsValid = enabledChannels.every(
    channel => channel.utm_source !== "" && channel.utm_medium !== ""
  );
  const areEnabledChannelsUnique =
    uniq(enabledChannels.map(c => `${c.utm_source}${c.utm_medium}`)).length ===
    enabledChannels.length;

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="Smartly Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your Smartly account.
            </Step1Explainer>
          }
        >
          <div ref={inputWrapperRef}>
            <InputWrapper
              labelText="Smartly Bearer Token"
              disabled={setupFlow.isLoading}
              tooltip={{
                maxWidth: `${theme.gridBase * 41}px`,
                render: () => (
                  <Step1InputWrapperTooltipContent>
                    This token can be found in your Smartly account. Please see{" "}
                    <LinkExternal href="https://docs.getelevar.com/docs/how-to-find-smartly-access-token">
                      here
                    </LinkExternal>{" "}
                    for how to find it.
                  </Step1InputWrapperTooltipContent>
                )
              }}
            >
              <InputFieldTextArea
                variant="SMALL"
                disabled={setupFlow.isLoading}
                value={accessToken}
                onChange={event => setAccessToken(event.target.value)}
                placeholder="Token"
                spellCheck={false}
                autoCapitalize="off"
              />
            </InputWrapper>
          </div>
        </StepSection>
        <Step1AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step1AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step1AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
            <div>
              <InputWrapper
                labelText="Conversion Value"
                disabled={setupFlow.isLoading}
              >
                <InputFieldSelect
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={selectedConversionValueOption}
                  setValue={option => setConversionValue(option.value)}
                  options={conversionValueOptions}
                  placeholder="Select a Conversion Value"
                />
              </InputWrapper>
            </div>
            <div>
              <div>
                Here you can further customize how to send data to Smartly.
              </div>
              <div>
                <Step1AdvancedOptionWrapper>
                  {enabledChannels.length === 0 ? (
                    <Step1IconTextButton
                      disabled={setupFlow.isLoading}
                      onClick={() => {
                        setEnabledChannels([
                          { utm_source: "", utm_medium: "" }
                        ]);
                      }}
                    >
                      <div>
                        <IconCog size="24px" />
                      </div>
                      <div>Customize by Source & Medium</div>
                    </Step1IconTextButton>
                  ) : (
                    <Step1MapItemsExistWrapper>
                      <div>
                        <div>Customize by Source & Medium</div>
                        <div>
                          By default, we send all transactions to Smartly.
                          Customize the settings here to send transactions with
                          only specific source/mediums (applies to subscription
                          recurring orders as well).
                        </div>
                      </div>
                      <div>
                        <div>
                          {enabledChannels.map((item, index) => (
                            <Step1MapItemWrapper key={index}>
                              <div>
                                <InputWrapper
                                  labelText="Source Name"
                                  tooltip={{
                                    maxWidth: `${theme.gridBase * 48}px`,
                                    render: () => (
                                      <Step1MapItemTooltipContent>
                                        This is the utm_source parameter in your
                                        ad campaign URL (for example,
                                        utm_source=facebook).
                                      </Step1MapItemTooltipContent>
                                    )
                                  }}
                                  disabled={setupFlow.isLoading}
                                >
                                  <InputFieldText
                                    variant="SMALL"
                                    disabled={setupFlow.isLoading}
                                    value={item.utm_source}
                                    onChange={event => {
                                      const value = event.target.value;
                                      setEnabledChannels(
                                        produce(enabledChannels, draft => {
                                          draft[index]!.utm_source = value;
                                        })
                                      );
                                    }}
                                    placeholder="Source"
                                    spellCheck={false}
                                    autoCapitalize="off"
                                  />
                                </InputWrapper>
                                <InputWrapper
                                  labelText="Medium Name"
                                  tooltip={{
                                    maxWidth: `${theme.gridBase * 49}px`,
                                    render: () => (
                                      <Step1MapItemTooltipContent>
                                        This is the utm_medium parameter in your
                                        ad campaign URL (for example,
                                        utm_medium=paidsocial).
                                      </Step1MapItemTooltipContent>
                                    )
                                  }}
                                  disabled={setupFlow.isLoading}
                                >
                                  <InputFieldText
                                    variant="SMALL"
                                    disabled={setupFlow.isLoading}
                                    value={item.utm_medium}
                                    onChange={event => {
                                      const value = event.target.value;
                                      setEnabledChannels(
                                        produce(enabledChannels, draft => {
                                          draft[index]!.utm_medium = value;
                                        })
                                      );
                                    }}
                                    placeholder="Source"
                                    spellCheck={false}
                                    autoCapitalize="off"
                                  />
                                </InputWrapper>
                              </div>
                              <div>
                                <Step1MapItemRemoveButton
                                  aria-label={`Remove: ${item.utm_source}`}
                                  disabled={setupFlow.isLoading}
                                  onClick={() => {
                                    setEnabledChannels(
                                      produce(enabledChannels, draft => {
                                        draft.splice(index, 1);
                                      })
                                    );
                                  }}
                                >
                                  <IconCross size="16px" />
                                </Step1MapItemRemoveButton>
                              </div>
                            </Step1MapItemWrapper>
                          ))}
                        </div>
                        <Step1IconTextButton
                          disabled={setupFlow.isLoading}
                          onClick={() => {
                            setEnabledChannels([
                              ...enabledChannels,
                              { utm_source: "", utm_medium: "" }
                            ]);
                          }}
                        >
                          <div>
                            <IconCircledPlus size="24px" />
                          </div>
                          <div>Add Another</div>
                        </Step1IconTextButton>
                      </div>
                    </Step1MapItemsExistWrapper>
                  )}
                </Step1AdvancedOptionWrapper>
              </div>
            </div>
          </Step1AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={setupFlow.isLoading}
          isStepCompleted={setupFlow.isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !areSmartlyFieldsValid
              ? "Please fill out all fields"
              : !areEnabledChannelsValid
                ? "Fill in all fields for enabled channels"
                : !areEnabledChannelsUnique
                  ? "Please provide unique values for enabled channels"
                  : null
          }
          onSave={async data => {
            await setupFlow.configMutation({
              ...data,
              accessToken,
              dataConfig: { conversionValue, enabledChannels }
            });
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step1AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step1AdvancedOptionsWrapper = styled.div<Step1AdvancedOptionsWrapperProps>`
  padding-top: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    width: ${props => props.inputWrapperWidth}px;
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }

  > div:last-child {
    > div:first-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
      margin-bottom: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

const Step1AdvancedOptionWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const Step1IconTextButton = styled.button`
  ${iconTextButtonStyles};
`;

const Step1MapItemsExistWrapper = styled.div`
  > div:first-child {
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
    }
  }
`;

const Step1MapItemWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    flex: 1;
    max-width: ${props => props.theme.gridBase * 90}px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props => props.theme.gridBase}px;

    @media screen and (max-width: 1450px) {
      width: 100%;
      grid-template-columns: 1fr;
      gap: ${props => props.theme.gridBase * 1.5}px;
      justify-items: stretch;
    }
  }

  > div:last-child {
    margin-left: ${props => props.theme.gridBase}px;
  }

  &:not(:last-child) {
    @media screen and (max-width: 1450px) {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
      border-bottom: 1px solid ${props => props.theme.palette.grey6};
      padding-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const Step1MapItemTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const Step1MapItemRemoveButton = styled.button`
  ${iconButtonStyles};
  color: ${props => props.theme.palette.grey3};
  margin-top: ${props => props.theme.gridBase * 4.5}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={(data, options) => setupFlow.configMutation(data, options)}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <Subscriptions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};
