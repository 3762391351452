import { uniq } from "lodash-es";
import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";
import { unsafeTypedEntries } from "elevar-common-ts/src/utils";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconChevronDown, IconChevronUp } from "elevar-design-system/src/icons";
import { InputFieldSelect } from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import { useGoogleAdsCreateConversionActionsMutation } from "../../api/handlers/website";
import { PageCard } from "../../components/PageCard";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { toast } from "../../utils/toast";
import { ConsentMode } from "./ConsentMode";
import { destinationGads as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { containsNumbersOnly, conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";
import { TransactionIdentifier } from "./TransactionIdentifier";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-google-ads-in-elevar-server-side-destination";

type SetupFlowContext = { shopifyOAuthScopes: ShopifyOAuthScopes };

const {
  SetupFlow,
  useConfigRequired,
  useSetupFlowDetails,
  useSetupFlowContext
} = createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationGadsProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationGads: React.FC<DestinationGadsProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      eventsConnectorConfig={eventsConnectorConfig}
      steps={[
        { details: { type: "CUSTOM", name: "Give Elevar Access" } },
        { details: { type: "SETTINGS" } },
        { details: { type: "EVENTS" } },
        { details: { type: "CONSENT_MODE" } },
        { details: { type: "TRANSACTION_IDENTIFIER" } },
        { details: { type: "PRODUCT_IDENTIFIER" } },
        { details: { type: "FILTER_TRANSACTIONS" } },
        { details: { type: "SUBSCRIPTIONS" } }
      ]}
      context={{ shopifyOAuthScopes }}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
    case 7:
      return <Step7 />;
    case 8:
      return <Step8 />;
  }
};

/* ========================================================================== */

type Events = ReturnType<typeof useConfigRequired>["enabledEvents"];

const recommendedEvents: Events = {
  purchase: true,
  addToCart: false,
  beginCheckout: false,
  viewItem: false,
  newCustomerPurchase: true,
  returningCustomerPurchase: true
};

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 2,
          type: "CUSTOM",
          render: () => (
            <>
              The Customer ID used is <span>{config.googleAdAccountId}</span>
            </>
          )
        },
        {
          step: 3,
          type: "EVENTS",
          selectedEvents: {
            enabledEvents: config.enabledEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          },
          recommendedEvents: getRecommendedEventState({
            destination,
            enabledEvents: recommendedEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          })
        },
        {
          step: 4,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 5,
          type: "TRANSACTION_IDENTIFIER",
          value: config.dataConfig.orderAttributeId
        },
        {
          step: 6,
          type: "PRODUCT_IDENTIFIER",
          value: config.dataConfig.productAttributeMapping
        },
        {
          step: 7,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        },
        {
          step: 8,
          type: "SUBSCRIPTIONS",
          filters: config.orderFilters,
          tagName: config.subscriptionTagName
        }
      ]}
      description={
        <Step0Explainer>
          <p>
            This server-side integration performs better than client-side
            tracking if you use post-purchase upsells.
          </p>
          <p>
            If you don't use post-purchase upsells, set up this integration
            along with client-side tracking and the GA4/Google Ads Import
            integration to determine what performs best for your business.
          </p>
          <StyledLinkExternal
            href="https://docs.getelevar.com/docs/how-to-implement-google-ads-remarketing-and-tracking-via-google-tag-manager"
            text="How to set up Google Ads via Google Tag Manager"
          />
          <StyledLinkExternal
            href="https://support.google.com/analytics/answer/10632359?hl=en"
            text="How to set up GA4/Google Ads Import Integration"
          />
        </Step0Explainer>
      }
      integrationNotes={{
        type: "ACTION_REQUIRED",
        content: (
          <div>
            For optimal performance, enable the{" "}
            <span>Enhanced Conversions for Leads</span> setting in your Google
            Ads account prior to setting up this destination.{" "}
            <LinkExternal href="https://support.google.com/google-ads/answer/11347292">
              How do I do this?
            </LinkExternal>
          </div>
        )
      }}
      whatsNextOverrides={{
        title: "Make Previous Conversion Action Secondary",
        explainer: (
          <>
            To avoid duplicate tracking, mark your previous Conversion Actions
            in Google Ads as Secondary Conversions and split test against
            Elevar.
          </>
        ),
        media: { type: "NONE", spanContent: true },
        link: {
          href: "https://docs.getelevar.com/docs/how-to-set-up-google-ads-in-elevar-server-side-destination",
          text: "How to Mark Conversion Actions as Secondary"
        }
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > p {
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > a:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <PageCard>
      <StepSection
        title="Give Elevar Access"
        setupGuideHref={setupGuideHref}
        description={
          <Step1Explainer>
            <p>
              To get started, please email us at{" "}
              <LinkExternal href="mailto:help@getelevar.com">
                help@getelevar.com
              </LinkExternal>{" "}
              with your Google Ads Account ID and a note to request access.
              Expect a response from our team within 24 business hours.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-google-ads-account-id"
              text="How do I find my Google Ads Account ID?"
            />
            <p>
              Why? Google requires the party who's sending the server data
              (Elevar) to have access to the account the data's going to (your
              Google Ads Account).
            </p>
          </Step1Explainer>
        }
        descriptionSpacing={!setupFlow.isStepCompleted}
      >
        {!setupFlow.isStepCompleted ? (
          <ButtonPrimary
            variant="SMALL"
            state={setupFlow.isLoading ? "LOADING" : "IDLE"}
            onClick={() => setupFlow.configMutation({})}
          >
            Mark as Complete
          </ButtonPrimary>
        ) : null}
      </StepSection>
    </PageCard>
  );
};

const Step1Explainer = styled.div`
  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};

    &:first-child {
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    &:last-child {
      margin-top: ${props => props.theme.gridBase * 2}px;
    }

    > a {
      ${linkStyles}
    }
  }
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [customerId, setCustomerId] = useState(config.googleAdAccountId);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [merchantId, setMerchantId] = useState(config.merchantCenterId);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const areGoogleAdsFieldsFilledIn = customerId !== "";
  const areGoogleAdsFieldsUnique = uniq([customerId, merchantId]).length === 2;
  const isCustomerIdValid = containsNumbersOnly(customerId);
  const isMerchantIdValid =
    merchantId === "" || containsNumbersOnly(merchantId);

  return (
    <Step2Wrapper>
      <PageCard>
        <StepSection
          title="Google Ads Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step2Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your Google Ads account.
            </Step2Explainer>
          }
        >
          <div ref={inputWrapperRef}>
            <InputWrapper
              labelText="Customer ID"
              disabled={setupFlow.isLoading}
              tooltip={{
                maxWidth: `${theme.gridBase * 33}px`,
                render: () => (
                  <Step2InputWrapperTooltipContent>
                    <p>
                      This is a unique number used to identify your Google Ads
                      account.
                    </p>
                    <StyledLinkExternal
                      href="https://docs.getelevar.com/docs/how-to-find-google-ads-customer-id"
                      text="How do I find this?"
                    />
                  </Step2InputWrapperTooltipContent>
                )
              }}
            >
              <InputFieldText
                variant="SMALL"
                disabled={setupFlow.isLoading}
                value={customerId}
                onChange={event => setCustomerId(event.target.value)}
                placeholder="123456789"
                spellCheck={false}
                autoCapitalize="off"
              />
            </InputWrapper>
          </div>
        </StepSection>
        <Step2AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step2AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step2AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
            <div>
              <InputWrapper
                labelText="Merchant ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 40}px`,
                  render: () => (
                    <Step2InputWrapperTooltipContent>
                      <p>This is your Google Merchant Center ID.</p>
                      <StyledLinkExternal
                        href="https://docs.getelevar.com/docs/how-to-find-google-merchant-center-id"
                        text="How do I find this?"
                      />
                    </Step2InputWrapperTooltipContent>
                  )
                }}
                optional={true}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={merchantId}
                  onChange={event => setMerchantId(event.target.value)}
                  placeholder="123456789"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Conversion Value"
                disabled={setupFlow.isLoading}
              >
                <InputFieldSelect
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={selectedConversionValueOption}
                  setValue={option => setConversionValue(option.value)}
                  options={conversionValueOptions}
                  placeholder="Select a Conversion Value"
                />
              </InputWrapper>
            </div>
          </Step2AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={setupFlow.isLoading}
          isStepCompleted={setupFlow.isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !areGoogleAdsFieldsFilledIn
              ? "Please fill out all fields"
              : !isCustomerIdValid
                ? "The Customer ID field must only contain numbers"
                : !isMerchantIdValid
                  ? "The Merchant ID field must only contain numbers"
                  : !areGoogleAdsFieldsUnique
                    ? "Please ensure all values are unique"
                    : null
          }
          onSave={async data => {
            await setupFlow.configMutation({
              ...data,
              googleAdAccountId: customerId,
              merchantCenterId: merchantId,
              dataConfig: { conversionValue }
            });
          }}
        />
      </PageCard>
    </Step2Wrapper>
  );
};

const Step2Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step2InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const Step2AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step2AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step2AdvancedOptionsWrapper = styled.div<Step2AdvancedOptionsWrapperProps>`
  width: ${props => props.inputWrapperWidth}px;
  padding-top: ${props => props.theme.gridBase * 2}px;

  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { shopifyOAuthScopes } = useSetupFlowContext();

  const { mutateAsync: googleAdsCreateConversionActionsMutation } =
    useGoogleAdsCreateConversionActionsMutation();

  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: {},
    webhookOverrides: config.webhookOverrides
  });
  const [conversionNameArrays, setConversionNameArrays] = useState(
    config.conversionNameArrays
  );

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step3Explainer>
            Select what events you want to send to Google Ads.
          </Step3Explainer>
        }
      />
      <EventDestinationTable
        isLoading={setupFlow.isLoading}
        isStepCompleted={setupFlow.isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        details={{
          eventState,
          setEventState,
          destinationName: destination.name,
          conversionNameArrays,
          setConversionNameArrays
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: {}
        }}
        onSave={async () => {
          setupFlow.setIsLoading(true);

          const shouldCreateActions = unsafeTypedEntries(
            eventState.enabledEvents
          ).some(([k, v]) => v && conversionNameArrays[k].length === 0);

          if (shouldCreateActions) {
            try {
              await setupFlow.configMutation(
                { ...eventState, conversionNameArrays },
                { disableAutoLoading: true, disableAutoStepComplete: true }
              );

              await googleAdsCreateConversionActionsMutation();
              toast.success("Conversion actions created in Google Ads");

              if (!setupFlow.isStepCompleted) {
                await setupFlow.configMutation({});
              }
            } catch (error) {
              toast.errorUnexpected(error);
            }
          } else {
            await setupFlow.configMutation({
              ...eventState,
              conversionNameArrays
            });
          }

          setupFlow.setIsLoading(false);
        }}
      />
    </PageCard>
  );
};

const Step3Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  return (
    <TransactionIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["id", "order_number", "name"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { orderAttributeId }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  return (
    <ProductIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["product_id", "sku", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { productAttributeMapping }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step7: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={(data, options) => setupFlow.configMutation(data, options)}
    />
  );
};

/* ========================================================================== */

const Step8: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <Subscriptions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};
