import { useState } from "react";
import styled from "styled-components";

import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWhite
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconCheckMark,
  IconChevronDown,
  IconChevronUp
} from "elevar-design-system/src/icons";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { Modal } from "../../../../components/Modal";
import { PageCard } from "../../../../components/PageCard";
import { useOnboardingDetails } from "../../../../context/OnboardingDetails";
import { track } from "../../../../utils/track";
import { type ManagePlanStep, type Product, products } from "./shared";

/* ========================================================================== */

type StepSelectProductProps = {
  setStep: (step: ManagePlanStep) => void;
  selectedProduct: Product | null;
  setSelectedProduct: (selectedProduct: Product | null) => void;
  isProductRequired: boolean;
  isNewUser: boolean;
};

export const StepSelectProduct: React.FC<StepSelectProductProps> = ({
  setStep,
  selectedProduct,
  setSelectedProduct,
  isProductRequired,
  isNewUser
}) => {
  const { onboardingState } = useOnboardingDetails();

  const [isAlreadyPurchasedModalVisible, setIsAlreadyPurchasedModalVisible] =
    useState(false);

  const recommendedProduct = isProductRequired
    ? null
    : onboardingState.name === "PLAN_SELECTION"
      ? (onboardingState.info.recommendedProduct ?? null)
      : null;

  const goToNextStep = () => {
    setStep("CHECKOUT");
    track.managePlanInitialCheckoutView(isNewUser);
  };

  return (
    <>
      {isProductRequired ? (
        <Explainer>
          An Expert Install is required because you added our Tracking
          Management service.
        </Explainer>
      ) : (
        <Explainer>
          Prefer for us to manage the installation, configuration, testing &
          deployment of your tracking? Purchase a One-Time Audit or Setup &
          we'll do the work for you.
        </Explainer>
      )}
      <ProductListWrapper>
        {products.map(product => (
          <ProductCard
            key={product.id}
            product={product}
            recommendedProduct={recommendedProduct}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            isProductRequired={isProductRequired}
          />
        ))}
        {isProductRequired ? (
          <AlreadyPurchasedCard>
            <div>Already Purchased?</div>
            <ButtonSecondary
              variant="SMALL"
              state={selectedProduct ? "DISABLED" : "IDLE"}
              onClick={() => setIsAlreadyPurchasedModalVisible(true)}
            >
              Already Purchased
            </ButtonSecondary>
          </AlreadyPurchasedCard>
        ) : null}
      </ProductListWrapper>
      {!recommendedProduct || selectedProduct ? (
        <StepSelectProductButtonPrimary
          variant="LARGE"
          state={isProductRequired && !selectedProduct ? "DISABLED" : "IDLE"}
          onClick={goToNextStep}
        >
          Continue to Checkout
        </StepSelectProductButtonPrimary>
      ) : (
        <StepSelectProductButtonWhite variant="LARGE" onClick={goToNextStep}>
          Continue to Checkout
        </StepSelectProductButtonWhite>
      )}
      {isProductRequired ? (
        <Modal
          isVisible={isAlreadyPurchasedModalVisible}
          onClose={() => setIsAlreadyPurchasedModalVisible(false)}
        >
          <ModalContents>
            <ModalTitle>Already Purchased</ModalTitle>
            <ModalBody>
              Did you pay an invoice for one of our Expert Install services
              outside of the Elevar app?
            </ModalBody>
            <ModalButtons>
              <ButtonSecondary variant="SMALL" onClick={goToNextStep}>
                Yes, I paid
              </ButtonSecondary>
              <ButtonPrimary
                variant="SMALL"
                onClick={() => setIsAlreadyPurchasedModalVisible(false)}
              >
                No, I didn't pay
              </ButtonPrimary>
            </ModalButtons>
          </ModalContents>
        </Modal>
      ) : null}
    </>
  );
};

const Explainer = styled.div`
  ${normalBodyStyles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ProductListWrapper = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > :not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

const AlreadyPurchasedCard = styled(PageCard)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    ${heading3Styles};
  }
`;

const StepSelectProductButtonPrimary = styled(ButtonPrimary)`
  align-self: flex-end;
`;

const StepSelectProductButtonWhite = styled(ButtonWhite)`
  align-self: flex-end;
`;

const ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 40}px;
  position: relative;
`;

const ModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

type ProductCardProps = {
  product: Product;
  recommendedProduct: Product | null;
  selectedProduct: Product | null;
  setSelectedProduct: (selectedProduct: Product | null) => void;
  isProductRequired: boolean;
};

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  recommendedProduct,
  selectedProduct,
  setSelectedProduct,
  isProductRequired
}) => {
  const isRecommended = product === recommendedProduct;
  const isSelected = product === selectedProduct;

  const [isInfoShown, setIsInfoShown] = useState(isRecommended);

  const onProductSelect = (selectedProduct: Product | null) => {
    setSelectedProduct(selectedProduct);
    track.managePlanProductButtonClick();
  };

  return (
    <ProductCardPageCard isSelected={isSelected} isRecommended={isRecommended}>
      <div>
        <div>
          <div>{product.name}</div>
          {isRecommended ? (
            <div>
              <ProductCardRecommendedLabel>
                Recommended
              </ProductCardRecommendedLabel>
            </div>
          ) : null}
        </div>
        <div>
          {isSelected ? (
            <ProductCardButtonSecondary
              variant="SMALL"
              onClick={() => onProductSelect(null)}
            >
              Added for ${product.dollarPrice}
            </ProductCardButtonSecondary>
          ) : (recommendedProduct ?? isProductRequired) ? (
            <ProductCardButtonPrimary
              variant="SMALL"
              state={
                selectedProduct !== null && selectedProduct !== product
                  ? "DISABLED"
                  : "IDLE"
              }
              onClick={() => onProductSelect(product)}
            >
              Get for ${product.dollarPrice}
            </ProductCardButtonPrimary>
          ) : (
            <ProductCardButtonSecondary
              variant="SMALL"
              state={
                selectedProduct !== null && selectedProduct !== product
                  ? "DISABLED"
                  : "IDLE"
              }
              onClick={() => onProductSelect(product)}
            >
              Get for ${product.dollarPrice}
            </ProductCardButtonSecondary>
          )}
        </div>
      </div>
      <ProductCardDescriptionToggleButton
        onClick={() => {
          setIsInfoShown(!isInfoShown);
          track.managePlanProductDescriptionToggle(product.name);
        }}
      >
        <div>What's Done For You</div>
        <div>
          {isInfoShown ? (
            <IconChevronUp size="16px" />
          ) : (
            <IconChevronDown size="16px" />
          )}
        </div>
      </ProductCardDescriptionToggleButton>
      {isInfoShown ? (
        <ProductCardInfo>
          <ProductCardDescription>{product.description}</ProductCardDescription>
          {product.whatsIncluded.length > 0 ? (
            <ProductCardWhatsIncludedWrapper>
              {product.whatsIncluded.map((item, index) => (
                <ProductCardWhatsIncludedItem key={index}>
                  <div>
                    <IconCheckMark size="16px" />
                  </div>
                  <div>{item}</div>
                </ProductCardWhatsIncludedItem>
              ))}
            </ProductCardWhatsIncludedWrapper>
          ) : null}
        </ProductCardInfo>
      ) : null}
    </ProductCardPageCard>
  );
};

type ProductCardPageCardProps = {
  isSelected: boolean;
  isRecommended: boolean;
};

const ProductCardPageCard = styled(PageCard)<ProductCardPageCardProps>`
  position: relative;
  box-shadow: ${props =>
    props.isRecommended && !props.isSelected
      ? `0 0 0 1px ${props.theme.palette.blue4}`
      : "none"};
  transition: box-shadow ${props => props.theme.other.transition};

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    inset: 0;
    border-width: 1px;
    border-style: solid;
    border-color: ${props =>
      props.isSelected
        ? props.theme.palette.purple2
        : props.isRecommended
          ? props.theme.palette.blue4
          : "transparent"};
    transition: border-color ${props => props.theme.other.transition};
    border-radius: 4px;
  }

  > div:first-child {
    display: flex;
    justify-content: space-between;

    > div:first-child {
      display: flex;
      gap: ${props => props.theme.gridBase * 2}px;

      > div:first-child {
        ${heading3Styles};
      }
    }

    > div:last-child {
      width: ${props => props.theme.gridBase * 20}px;
    }
  }
`;

const ProductCardDescriptionToggleButton = styled.button`
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey2};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

const ProductCardInfo = styled.div`
  padding-top: ${props => props.theme.gridBase}px;
`;

const ProductCardDescription = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  max-width: 90%;
`;

const ProductCardWhatsIncludedWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 1.5}px;
`;

const ProductCardWhatsIncludedItem = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    display: flex;
    color: ${props => props.theme.palette.purple2};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const ProductCardButtonSecondary = styled(ButtonSecondary)`
  width: 100%;
`;

const ProductCardButtonPrimary = styled(ButtonPrimary)`
  width: 100%;
`;

const ProductCardRecommendedLabel = styled.div`
  ${smallTextStyles};
  border-radius: 4px;
  padding-top: ${props => props.theme.gridBase * 0.5}px;
  padding-bottom: ${props => props.theme.gridBase * 0.5}px;
  padding-right: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase}px;
  color: ${props => props.theme.palette.white};
  background: ${props => props.theme.other.gradientRight};
`;
