import { useEffect, useState } from "react";
import styled from "styled-components";

import {
  type CompanyDetails,
  type EventsConnectorConfig,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { ElevarLogo } from "elevar-design-system/src/logos";
import { Spinner } from "elevar-design-system/src/Spinner";

import {
  type AccountCompanyList,
  useAccountCompanyListQuery
} from "../../../../api/handlers/account";
import {
  type CompanyPaymentMethods,
  useCompanyDetailsQuery,
  useCompanyPaymentMethodsQuery
} from "../../../../api/handlers/company";
import {
  useEventsConnectorConfigQuery,
  useSpecialPlanDetailsQuery
} from "../../../../api/handlers/website";
import { useGlobalError } from "../../../../context/GlobalError";
import { useLastLocation } from "../../../../context/LastLocation";
import { useOnboardingDetails } from "../../../../context/OnboardingDetails";
import { usePageScrollContainer } from "../../../../context/PageScrollContainer";
import { useCompanyId, useWebsiteId } from "../../../../utils/idHooks";
import { WebsiteExitButton } from "../../WebsiteExitButton";
import { BreakdownShell } from "./BreakdownShell";
import { ProgressStepper } from "./ProgressStepper";
import {
  type CurrentPlan,
  type ManagePlanStep,
  type Plan,
  plans,
  type Product,
  trackingManagementTier1AddOn
} from "./shared";
import { StepCheckout } from "./StepCheckout";
import { StepSelectPlan } from "./StepSelectPlan";
import { StepSelectProduct } from "./StepSelectProduct";

/* ========================================================================== */

type ManagePlanProps = {
  websiteDetails: WebsiteDetails;
};

export const ManagePlan: React.FC<ManagePlanProps> = ({ websiteDetails }) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const lastLocation = useLastLocation();
  const { onboardingState } = useOnboardingDetails();

  const [isPlanChangeInProgress, setIsPlanChangeInProgress] = useState(false);

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;

  return (
    <ManagePlanWrapper>
      <BackToAppOverlay>
        <LogoWrapper>
          <ElevarLogo />
        </LogoWrapper>
        <PageCloseButtonWrapper>
          <WebsiteExitButton
            buttonColor="WHITE"
            buttonDisabled={isPlanChangeInProgress}
            canDelete={onboardingState.name === "PLAN_SELECTION"}
            exitTo={
              onboardingState.name === "PLAN_SELECTION"
                ? "/"
                : (lastLocation ?? websiteUrl)
            }
          />
        </PageCloseButtonWrapper>
      </BackToAppOverlay>
      <ManagePlanInner1
        websiteDetails={websiteDetails}
        setIsPlanChangeInProgress={setIsPlanChangeInProgress}
      />
    </ManagePlanWrapper>
  );
};

const ManagePlanWrapper = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.palette.grey8};
`;

const BackToAppOverlay = styled.div`
  position: fixed;
  top: ${props => props.theme.gridBase * 4}px;
  left: ${props => props.theme.gridBase * 4}px;
  right: ${props => props.theme.gridBase * 4}px;
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: 0;
  height: ${props => props.theme.gridBase * 4}px;
  width: ${props => props.theme.gridBase * 4}px;
`;

const PageCloseButtonWrapper = styled.div`
  position: absolute;
  right: 0;
`;

/* ========================================================================== */

type ManagePlanInner1Props = {
  websiteDetails: WebsiteDetails;
  setIsPlanChangeInProgress: (isPlanChangeInProgress: boolean) => void;
};

const ManagePlanInner1: React.FC<ManagePlanInner1Props> = ({
  websiteDetails,
  setIsPlanChangeInProgress
}) => {
  const pageScrollContainer = usePageScrollContainer();

  const [refetchPaymentMethodsOnFocus, setRefetchPaymentMethodsOnFocus] =
    useState(false);

  const accountCompanyList = useAccountCompanyListQuery();
  const companyDetails = useCompanyDetailsQuery();
  const companyPaymentMethods = useCompanyPaymentMethodsQuery({
    refetchOnFocus: refetchPaymentMethodsOnFocus
  });
  const eventsConnectorConfig = useEventsConnectorConfigQuery();
  const specialPlanDetails = useSpecialPlanDetailsQuery();

  const [step, setStep] = useState<ManagePlanStep>("SELECT_PLAN");

  if (
    accountCompanyList.error !== null ||
    companyDetails.error !== null ||
    companyPaymentMethods.error !== null ||
    eventsConnectorConfig.error !== null ||
    specialPlanDetails.error !== null
  ) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    accountCompanyList.data === undefined ||
    companyDetails.data === undefined ||
    companyPaymentMethods.data === undefined ||
    eventsConnectorConfig.data === undefined ||
    specialPlanDetails.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  const augmentedSetStep = (step: ManagePlanStep) => {
    setStep(step);
    pageScrollContainer.scrollTo({ top: 0, left: 0 });
  };

  const specialPlan: Plan | null =
    specialPlanDetails.data !== null
      ? {
          id: specialPlanDetails.data.plan.id,
          name: specialPlanDetails.data.plan.name,
          dollarPrice: specialPlanDetails.data.plan.amount / 100,
          interval: specialPlanDetails.data.plan.interval,
          supportTier: "Standard",
          usage: {
            "Orders Included/Month": { type: "NUMBER", value: 0 },
            "Extra Order Fee": { type: "NUMBER", value: 0 }
          },
          features: {
            "Session Enrichment": { type: "BOOLEAN", value: true },
            "Built-in Consent Mode": { type: "BOOLEAN", value: true },
            "Conversion Accuracy Monitoring": { type: "BOOLEAN", value: true },
            "Facebook Conversion API": { type: "BOOLEAN", value: true },
            "Multiple Pixels per Destination": {
              type: "BOOLEAN",
              value: specialPlanDetails.data.plan.features.some(
                feature => feature.name === "MULTIPLE_DESTINATION_CONFIGS"
              )
            },
            "Google Ads": { type: "BOOLEAN", value: true },
            "Real-Time Reporting": {
              type: "BOOLEAN",
              value: specialPlanDetails.data.plan.features.some(
                feature => feature.name === "SERVER_SIDE_REAL_TIME"
              )
            },
            "Email Alerts": {
              type: "BOOLEAN",
              value: specialPlanDetails.data.plan.features.some(
                feature => feature.name === "SERVER_SIDE_ALERTS"
              )
            },
            "Shopify Markets": {
              type: "BOOLEAN",
              value: specialPlanDetails.data.plan.features.some(
                feature => feature.name === "MARKETS"
              )
            }
          },
          support: {
            "Support Level": { type: "BOOLEAN", value: true },
            "Onboarding Phone Calls": { type: "BOOLEAN", value: true }
          },
          services: {
            "One-Time Audits & Setups": { type: "BOOLEAN", value: true },
            "Tracking Management Tier 1": { type: "BOOLEAN", value: true },
            "Tracking Management Tier 2": { type: "BOOLEAN", value: true }
          },
          description: specialPlanDetails.data.plan.public_description,
          code: specialPlanDetails.data.planCode
        }
      : null;

  return (
    <ManagePlanInner2
      websiteDetails={websiteDetails}
      setIsPlanChangeInProgress={setIsPlanChangeInProgress}
      accountCompanyList={accountCompanyList.data}
      companyDetails={companyDetails.data}
      companyPaymentMethods={companyPaymentMethods.data}
      isRefetchingPaymentMethods={companyPaymentMethods.isRefetching}
      setRefetchPaymentMethodsOnFocus={setRefetchPaymentMethodsOnFocus}
      eventsConnectorConfig={eventsConnectorConfig.data}
      specialPlan={specialPlan}
      step={step}
      setStep={augmentedSetStep}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type ManagePlanInner2Props = {
  websiteDetails: WebsiteDetails;
  setIsPlanChangeInProgress: (isPlanChangeInProgress: boolean) => void;
  accountCompanyList: AccountCompanyList;
  companyDetails: CompanyDetails;
  companyPaymentMethods: CompanyPaymentMethods;
  isRefetchingPaymentMethods: boolean;
  setRefetchPaymentMethodsOnFocus: (value: boolean) => void;
  eventsConnectorConfig: EventsConnectorConfig;
  specialPlan: Plan | null;
  step: ManagePlanStep;
  setStep: (step: ManagePlanStep) => void;
};

const ManagePlanInner2: React.FC<ManagePlanInner2Props> = ({
  websiteDetails,
  setIsPlanChangeInProgress,
  accountCompanyList,
  companyDetails,
  companyPaymentMethods,
  isRefetchingPaymentMethods,
  setRefetchPaymentMethodsOnFocus,
  eventsConnectorConfig,
  specialPlan,
  step,
  setStep
}) => {
  const { onboardingState } = useOnboardingDetails();
  const { globalErrorDispatch } = useGlobalError();

  const initialIsAddOnSelected = websiteDetails.add_ons.some(
    addOn => addOn.id === trackingManagementTier1AddOn.id
  );
  const currentPlanRemote = websiteDetails.plan;
  const currentPlanLocal =
    plans.find(p => p.id === currentPlanRemote.id) ?? null;
  const currentPlan = { local: currentPlanLocal, remote: currentPlanRemote };

  const [isAddOnSelected, setIsAddOnSelected] = useState(
    initialIsAddOnSelected
  );
  const [selectedPlan, setSelectedPlan] = useState(currentPlan.local);

  useEffect(() => {
    globalErrorDispatch({ type: "RESHOW_PROACTIVE" });
  }, [globalErrorDispatch]);

  return (
    <ManagePlanInner2Wrapper>
      <div>
        <div>
          <ProgressStepper
            activeStep={step}
            setActiveStep={setStep}
            productStepState={
              onboardingState.name === "PLAN_SELECTION"
                ? "VISIBLE"
                : initialIsAddOnSelected
                  ? "HIDDEN"
                  : isAddOnSelected
                    ? "VISIBLE"
                    : "HIDDEN"
            }
          />
        </div>
        <div>
          <ManagePlanRouter
            websiteDetails={websiteDetails}
            setIsPlanChangeInProgress={setIsPlanChangeInProgress}
            accountCompanyList={accountCompanyList}
            companyDetails={companyDetails}
            companyPaymentMethods={companyPaymentMethods}
            isRefetchingPaymentMethods={isRefetchingPaymentMethods}
            setRefetchPaymentMethodsOnFocus={setRefetchPaymentMethodsOnFocus}
            eventsConnectorConfig={eventsConnectorConfig}
            specialPlan={specialPlan}
            step={step}
            setStep={setStep}
            isAddOnSelected={isAddOnSelected}
            setIsAddOnSelected={setIsAddOnSelected}
            initialIsAddOnSelected={initialIsAddOnSelected}
            currentPlan={currentPlan}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
          />
        </div>
      </div>
    </ManagePlanInner2Wrapper>
  );
};

const ManagePlanInner2Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${props => props.theme.gridBase * 8}px;

  > div {
    width: ${props => props.theme.gridBase * 125}px;

    > div:first-child {
      display: flex;
      align-items: center;
      height: ${props => props.theme.gridBase * 4}px;
      margin-top: ${props => props.theme.gridBase * 4}px;
      margin-bottom: ${props => props.theme.gridBase * 6}px;
    }
  }
`;

/* ========================================================================== */

type ManagePlanRouterProps = {
  websiteDetails: WebsiteDetails;
  setIsPlanChangeInProgress: (isPlanChangeInProgress: boolean) => void;
  accountCompanyList: AccountCompanyList;
  companyDetails: CompanyDetails;
  companyPaymentMethods: CompanyPaymentMethods;
  isRefetchingPaymentMethods: boolean;
  setRefetchPaymentMethodsOnFocus: (value: boolean) => void;
  eventsConnectorConfig: EventsConnectorConfig;
  specialPlan: Plan | null;
  step: ManagePlanStep;
  setStep: (step: ManagePlanStep) => void;
  isAddOnSelected: boolean;
  setIsAddOnSelected: (isAddOnSelected: boolean) => void;
  initialIsAddOnSelected: boolean;
  currentPlan: CurrentPlan;
  selectedPlan: Plan | null;
  setSelectedPlan: (plan: Plan | null) => void;
};

const ManagePlanRouter: React.FC<ManagePlanRouterProps> = ({
  websiteDetails,
  setIsPlanChangeInProgress,
  accountCompanyList,
  companyDetails,
  companyPaymentMethods,
  isRefetchingPaymentMethods,
  setRefetchPaymentMethodsOnFocus,
  eventsConnectorConfig,
  specialPlan,
  step,
  setStep,
  isAddOnSelected,
  setIsAddOnSelected,
  initialIsAddOnSelected,
  currentPlan,
  selectedPlan,
  setSelectedPlan
}) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [globalHowDidYouHearAboutUs, setGlobalHowDidYouHearAboutUs] =
    useState("");

  const isNewUser = accountCompanyList.flatMap(c => c.websites).length <= 1;
  const isProductRequired = !initialIsAddOnSelected && isAddOnSelected;
  const { marketsEnabled } = eventsConnectorConfig.globalConfig;

  switch (step) {
    case "SELECT_PLAN": {
      return (
        <StepSelectPlan
          setStep={setStep}
          specialPlan={specialPlan}
          currentPlan={currentPlan}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          isAddOnSelected={isAddOnSelected}
          setIsAddOnSelected={setIsAddOnSelected}
          initialIsAddOnSelected={initialIsAddOnSelected}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          isNewUser={isNewUser}
          marketsEnabled={marketsEnabled}
        />
      );
    }
    case "SELECT_PRODUCT": {
      return (
        <BreakdownShell
          step={step}
          setStep={setStep}
          selectedPlan={selectedPlan!}
          isAddOnSelected={isAddOnSelected}
          selectedProduct={selectedProduct}
          subscriptionType={websiteDetails.subscription_type}
          title="Select One-Time Audit or Setup"
          isRequired={isProductRequired}
        >
          <StepSelectProduct
            setStep={setStep}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            isProductRequired={isProductRequired}
            isNewUser={isNewUser}
          />
        </BreakdownShell>
      );
    }
    case "CHECKOUT": {
      return (
        <BreakdownShell
          step={step}
          setStep={setStep}
          selectedPlan={selectedPlan!}
          isAddOnSelected={isAddOnSelected}
          selectedProduct={selectedProduct}
          subscriptionType={websiteDetails.subscription_type}
          title="Checkout"
        >
          <StepCheckout
            currentPlan={currentPlan}
            selectedPlan={selectedPlan!}
            isAddOnSelected={isAddOnSelected}
            selectedProduct={selectedProduct}
            setIsPlanChangeInProgress={setIsPlanChangeInProgress}
            hasReferralInfo={companyDetails.how_did_you_hear_about_us !== null}
            globalHowDidYouHearAboutUs={globalHowDidYouHearAboutUs}
            setGlobalHowDidYouHearAboutUs={setGlobalHowDidYouHearAboutUs}
            subscriptionType={websiteDetails.subscription_type}
            websiteName={websiteDetails.name}
            details={
              websiteDetails.subscription_type === "Stripe"
                ? {
                    type: "STRIPE",
                    activePaymentMethod:
                      companyPaymentMethods.find(p => p.is_default) ?? null,
                    isRefetchingActivePaymentMethod: isRefetchingPaymentMethods,
                    refetchActivePaymentMethodOnFocus: () => {
                      setRefetchPaymentMethodsOnFocus(true);
                    }
                  }
                : { type: "SHOPIFY" }
            }
          />
        </BreakdownShell>
      );
    }
  }
};
