import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconCross } from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { ElevarLogo } from "elevar-design-system/src/logos";
import { heading1Styles } from "elevar-design-system/src/typography/typography";

import { useCompanyCreateMutation } from "../api/handlers/company";
import { QuestionFlowShell } from "../components/QuestionFlowShell";
import { UserButtonDropdown } from "../components/UserButtonDropdown";
import { useLastLocation } from "../context/LastLocation";
import { TitleProvider } from "../context/Title";
import { useUserRequired } from "../context/User";
import { track } from "../utils/track";
import { isValidCompanyOrWebsiteName } from "../utils/validate";

/* ========================================================================== */

export const CreateCompany: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const lastLocation = useLastLocation();
  const { accountDetails } = useUserRequired();

  const { mutateAsync: companyCreateMutation } = useCompanyCreateMutation(
    accountDetails.id
  );

  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [websiteName, setWebsiteName] = useState("");

  const searchParams = new URLSearchParams(location.search);
  const initialCompanyCreation = searchParams.has("initial");

  return (
    <TitleProvider page="Create Company">
      <CreateCompanyWrapper>
        {initialCompanyCreation ? (
          <UserButtonDropdownWrapper>
            <UserButtonDropdown
              userImage={accountDetails.profile.picture}
              userName={`${accountDetails.first_name} ${accountDetails.last_name}`}
              showAccountSettingsLink={true}
            />
          </UserButtonDropdownWrapper>
        ) : (
          <PageCloseButtonWrapper>
            <PageCloseButton
              disabled={isLoading}
              onClick={() => history.push(lastLocation ?? "/")}
            >
              <IconCross size="16px" />
            </PageCloseButton>
          </PageCloseButtonWrapper>
        )}
        <LogoWrapper>
          <ElevarLogo />
        </LogoWrapper>
        <CreateCompanyQuestionFlowShell>
          <PageHeading>Tell us About Your Store</PageHeading>
          <FirstInputWrapper labelText="Company Name" disabled={isLoading}>
            <InputFieldText
              variant="LARGE"
              disabled={isLoading}
              value={companyName}
              onChange={event => setCompanyName(event.target.value)}
              placeholder="Enter Company Name"
              spellCheck={false}
              autoCapitalize="off"
            />
          </FirstInputWrapper>
          <SecondInputWrapper labelText="Website URL" disabled={isLoading}>
            <InputFieldText
              variant="LARGE"
              disabled={isLoading}
              value={websiteName}
              onChange={event => setWebsiteName(event.target.value)}
              placeholder="eg. www.getelevar.com"
              spellCheck={false}
              autoCapitalize="off"
            />
          </SecondInputWrapper>
          <ButtonPrimary
            variant="LARGE"
            onClick={async () => {
              setIsLoading(true);
              const trimmedCompanyName = companyName.trim();
              const trimmedWebsiteName = websiteName.trim();

              const { companyId, websiteId } = await companyCreateMutation({
                companyName: trimmedCompanyName,
                websiteName: trimmedWebsiteName
              });

              track.companyCreate({ name: trimmedCompanyName });
              track.websiteCreate({ name: trimmedWebsiteName });
              history.replace(`/company/${companyId}/website/${websiteId}`);
            }}
            state={
              isLoading
                ? "LOADING"
                : !isValidCompanyOrWebsiteName(companyName) ||
                    !isValidCompanyOrWebsiteName(websiteName)
                  ? "DISABLED"
                  : "IDLE"
            }
          >
            Create Company & Website
          </ButtonPrimary>
        </CreateCompanyQuestionFlowShell>
      </CreateCompanyWrapper>
    </TitleProvider>
  );
};

/* ========================================================================== */

const CreateCompanyWrapper = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.palette.white};
`;

const UserButtonDropdownWrapper = styled.div`
  position: fixed;
  bottom: ${props => props.theme.gridBase * 2.45}px;
  left: ${props => props.theme.gridBase * 3}px;
`;

const PageCloseButtonWrapper = styled.div`
  position: fixed;
  top: ${props => props.theme.gridBase * 4}px;
  right: ${props => props.theme.gridBase * 4}px;
`;

const PageCloseButton = styled.button`
  display: flex;
  border-radius: 2px;
  padding: ${props => props.theme.gridBase}px;
  background-color: ${props => props.theme.palette.grey8};
  color: ${props => props.theme.palette.grey3};
  transition: background-color ${props => props.theme.other.transition};

  &:hover {
    background-color: ${props => props.theme.palette.grey6};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.palette.grey8};
  }
`;

const LogoWrapper = styled.div`
  position: fixed;
  top: ${props => props.theme.gridBase * 4}px;
  left: ${props => props.theme.gridBase * 4}px;
`;

const CreateCompanyQuestionFlowShell = styled(QuestionFlowShell)`
  padding-top: ${props => props.theme.gridBase * 9}px;
`;

const PageHeading = styled.h1`
  ${heading1Styles};
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const FirstInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const SecondInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;
