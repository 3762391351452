import Downshift from "downshift";
import { useRef, useState } from "react";
import styled from "styled-components";

import { IconChevronDown } from "elevar-design-system/src/icons";
import { type Option } from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { inputFieldFocusStyles } from "elevar-design-system/src/inputs/sharedInputStyles";
import { scrollbarMixin } from "elevar-design-system/src/scrollbar";
import { useDownshiftEnvironment } from "elevar-design-system/src/shadowRootHandlers";
import {
  normalBodyStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

/* ========================================================================== */

type Item =
  | { type: "SELECT"; option: Option }
  | { type: "OTHER"; value: string };

/* ========================================================================== */

type InputFieldChannelCodeProps = {
  value: string;
  setValue: (value: string) => void;
  options: Array<Option>;
  placeholder?: string;
  disabled?: boolean;
};

export const InputFieldChannelCode: React.FC<InputFieldChannelCodeProps> = ({
  value,
  setValue,
  options,
  placeholder,
  disabled = false
}) => {
  const downshiftEnvironment = useDownshiftEnvironment();
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const filteredOptions = options.filter(option => {
    const normalisedOptionName = option.name.toLowerCase();
    const normalisedOptionValue = option.value.toLowerCase();
    const normalisedValue = value.toLowerCase();

    return (
      normalisedOptionName.includes(normalisedValue) ||
      normalisedOptionValue.includes(normalisedValue)
    );
  });

  const selectItems = filteredOptions.map<Item>(option => ({
    type: "SELECT",
    option
  }));

  const items: Array<Item> =
    value === "" ||
    filteredOptions.map(o => o.name).includes(value) ||
    filteredOptions.map(o => o.value).includes(value)
      ? selectItems
      : [...selectItems, { type: "OTHER", value }];

  return (
    <Downshift
      environment={downshiftEnvironment}
      itemToString={(item: Item | null) => {
        return item?.type === "SELECT"
          ? item.option.value
          : (item?.value ?? "");
      }}
      defaultHighlightedIndex={0}
      onSelect={selection => {
        if (selection)
          setValue(
            selection.type === "SELECT"
              ? selection.option.value
              : selection.value
          );
      }}
    >
      {({
        isOpen,
        getInputProps,
        getMenuProps,
        getItemProps,
        getToggleButtonProps,
        highlightedIndex,
        openMenu
      }) => {
        const showInputFocused = inputFocused || isOpen;

        /**
         * NOTE:
         * The below `event.preventDefault()` call is needed so that when
         * this component is wrapped inside an `InputWrapper` component, the
         * menu closes correctly when a menu item is selected. This was not
         * happening in the explained case because the `InputWrapper` wraps
         * its children in a `label` element, meaning that any clicks inside
         * of it focuses the wrapped input. In the explained case, this meant
         * that when an menu item was selected, it triggered the label logic
         * explained above, causing the menu to stay open.
         */

        return (
          <div>
            <Wrapper onClick={event => event.preventDefault()}>
              <div>
                <Input
                  {...getInputProps({
                    onFocus: () => {
                      setInputFocused(true);
                      openMenu();
                    },
                    onBlur: () => setInputFocused(false)
                  })}
                  ref={inputRef}
                  variant="SMALL"
                  placeholder={placeholder}
                  disabled={disabled}
                  spellCheck={false}
                  autoCapitalize="off"
                  inputFocused={showInputFocused}
                  value={value}
                  onChange={event => {
                    setValue(event.target.value);
                    openMenu();
                  }}
                />
                <ToggleMenuButton
                  {...getToggleButtonProps({
                    onFocus: () => setInputFocused(true),
                    onBlur: () => setInputFocused(false)
                  })}
                  tabIndex={-1}
                  disabled={disabled}
                >
                  <IconChevronDown size="16px" />
                </ToggleMenuButton>
              </div>
              <MenuWrapper isOpen={isOpen}>
                <Menu {...getMenuProps({ scrollable: items.length > 4 })}>
                  {isOpen ? (
                    <>
                      <MenuExplainer>
                        Select a channel or enter another channel code:
                      </MenuExplainer>
                      <ul>
                        {items.map((item, index) => (
                          <MenuItem
                            key={`${
                              item.type === "SELECT"
                                ? item.option.value
                                : item.value
                            }-${index}`}
                            highlighted={index === highlightedIndex}
                            {...getItemProps({ item, index })}
                          >
                            {item.type === "OTHER" ? (
                              <div>
                                <ItemCreateText>Use</ItemCreateText>
                                <ItemOtherValueText>
                                  "{value}"
                                </ItemOtherValueText>
                              </div>
                            ) : (
                              <div>
                                <ItemSelectValueText>
                                  <div>{item.option.name}</div>
                                  <div>{item.option.value}</div>
                                </ItemSelectValueText>
                              </div>
                            )}
                          </MenuItem>
                        ))}
                      </ul>
                    </>
                  ) : null}
                </Menu>
              </MenuWrapper>
            </Wrapper>
          </div>
        );
      }}
    </Downshift>
  );
};

/* ========================================================================== */

const Wrapper = styled.div`
  position: relative;
`;

type InputProps = {
  inputFocused: boolean;
};

const Input = styled(InputFieldText)<InputProps>`
  padding-right: ${props => props.theme.gridBase * 4.5}px;

  &&:not(:disabled) {
    ${props => props.inputFocused && inputFieldFocusStyles};
  }
`;

const ToggleMenuButton = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  top: ${props => props.theme.gridBase}px;
  bottom: ${props => props.theme.gridBase}px;
  right: ${props => props.theme.gridBase}px;
  padding: ${props => props.theme.gridBase * 0.5}px;
  color: ${props => props.theme.palette.grey4};
`;

type MenuWrapperProps = {
  isOpen: boolean;
};

const MenuWrapper = styled.div<MenuWrapperProps>`
  position: absolute;
  top: calc(100% + ${props => props.theme.gridBase}px);
  left: 0;
  right: 0;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.palette.grey6};
  background-color: ${props => props.theme.palette.white};
  box-shadow: ${props => props.theme.other.boxShadowDropdown};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  z-index: 2;
`;

type MenuProps = {
  scrollable: boolean;
};

const Menu = styled.div<MenuProps>`
  ${scrollbarMixin};
  margin: ${props => props.theme.gridBase * 0.5}px;
  overflow-y: auto;
  padding-right: ${props =>
    props.scrollable ? props.theme.gridBase * 0.5 : 0}px;
  max-height: ${props => props.theme.gridBase * 27.5}px;
`;

const MenuExplainer = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey3};
  padding: ${props => props.theme.gridBase * 1.5}px;
`;

type MenuItemProps = {
  highlighted: boolean;
};

const MenuItem = styled.li<MenuItemProps>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase}px;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  background-color: ${props =>
    props.highlighted ? props.theme.palette.grey8 : props.theme.palette.white};
  transition: background-color ${props => props.theme.other.transition};

  > div {
    width: 100%;
    display: flex;
  }
`;

const ItemCreateText = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey4};
  margin-right: ${props => props.theme.gridBase}px;
`;

const ItemOtherValueText = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const ItemSelectValueText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:first-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  > div:last-child {
    ${smallTextStyles};
    color: ${props => props.theme.palette.grey3};
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-align: right;
    max-width: 35%;
  }
`;
