import { transparentize } from "polished";
import { useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import styled from "styled-components";

import { type CompanyDetails } from "elevar-common-ts/src/apiTypes";

import {
  ButtonPrimary,
  ButtonWarning
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { Spinner } from "elevar-design-system/src/Spinner";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

import {
  type CompanyWebsites,
  useCompanyDeleteMutation,
  useCompanyDetailsMutation,
  useCompanyDetailsQuery,
  useCompanyWebsitesQuery
} from "../../../api/handlers/company";
import { uploadFile } from "../../../api/handlers/fileUpload";
import { ActionWarningModal } from "../../../components/ActionWarningModal";
import { AvatarPicker } from "../../../components/AvatarPicker";
import { PageCard } from "../../../components/PageCard";
import { toast } from "../../../utils/toast";
import { isValidCompanyOrWebsiteName } from "../../../utils/validate";

/* ========================================================================== */

export const CompanyInfo: React.FC = () => {
  const companyDetails = useCompanyDetailsQuery();
  const companyWebsites = useCompanyWebsitesQuery();

  if (companyDetails.error !== null || companyWebsites.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (companyDetails.data === undefined || companyWebsites.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <CompanyInfoContents
      companyDetails={companyDetails.data}
      companyWebsites={companyWebsites.data}
    />
  );
};

const CenteredWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type CompanyInfoContents = {
  companyDetails: CompanyDetails;
  companyWebsites: CompanyWebsites;
};

const CompanyInfoContents: React.FC<CompanyInfoContents> = ({
  companyDetails,
  companyWebsites
}) => {
  const history = useHistory();

  const { mutateAsync: companyDetailsMutation } = useCompanyDetailsMutation();
  const { mutateAsync: companyDeleteMutation } = useCompanyDeleteMutation();

  const [companyImage, setCompanyImage] = useState<File | null>(null);
  const [companyName, setCompanyName] = useState(companyDetails.name);
  const [isCompanyFormLoading, setIsCompanyFormLoading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const isCompanyFormDirty =
    companyImage !== null || companyName !== companyDetails.name;

  const areAllOwnedWebsitesCancellable = companyWebsites.every(website => {
    return website.permissions.includes("CANCELLABLE");
  });
  const areAllOwnedWebsitesOnFreePlan = companyWebsites.every(website => {
    return website.plan.id === "DEFAULT_FREE_PLAN";
  });

  return (
    <>
      <CompanyInfoPageCard>
        <CompanyInfoInputWrapper
          labelText="Avatar"
          disabled={isCompanyFormLoading}
          asDiv={true}
        >
          <AvatarPicker
            type="COMPANY"
            imageSrc={
              companyImage
                ? URL.createObjectURL(companyImage)
                : companyDetails.picture?.file
            }
            imageSelectHandler={image => {
              if (image.size <= 1024 * 1024) {
                setCompanyImage(image);
              } else {
                toast.errorExpected("Image must be 1MB or less");
              }
            }}
            disabled={isCompanyFormLoading}
          />
        </CompanyInfoInputWrapper>
        <CompanyInfoInputWrapper
          labelText="Company Name"
          disabled={isCompanyFormLoading}
        >
          <InputFieldText
            variant="SMALL"
            disabled={isCompanyFormLoading}
            value={companyName}
            onChange={event => setCompanyName(event.target.value)}
            spellCheck={false}
          />
        </CompanyInfoInputWrapper>
        <ButtonPrimary
          variant="SMALL"
          state={
            isCompanyFormLoading
              ? "LOADING"
              : !isCompanyFormDirty || !isValidCompanyOrWebsiteName(companyName)
                ? "DISABLED"
                : "IDLE"
          }
          onClick={async () => {
            setIsCompanyFormLoading(true);
            const trimmedCompanyName = companyName.trim();

            if (companyImage !== null) {
              const upload = await uploadFile(companyImage);

              await companyDetailsMutation({
                name: trimmedCompanyName,
                picture: {
                  id: upload.id,
                  file: upload.file
                }
              });
            } else {
              await companyDetailsMutation({ name: trimmedCompanyName });
            }

            setCompanyName(trimmedCompanyName);
            toast.success("Company updated");
            setIsCompanyFormLoading(false);
          }}
        >
          Save Changes
        </ButtonPrimary>
      </CompanyInfoPageCard>

      {!areAllOwnedWebsitesCancellable ? (
        <CompanyInfoWarningCard>
          Company cannot be deleted automatically due to the fact that it owns a
          Website that cannot be deleted automatically. Please reach out to our
          support team if you are sure you want to delete this Company.
        </CompanyInfoWarningCard>
      ) : !areAllOwnedWebsitesOnFreePlan ? (
        <CompanyInfoWarningCard>
          Company cannot be deleted when it owns a Website that is on a paid
          plan. You must cancel the plans of all owned Websites before you can
          delete this Company.
        </CompanyInfoWarningCard>
      ) : null}
      <DeleteCompanyButton
        variant="SMALL"
        state={
          areAllOwnedWebsitesCancellable && areAllOwnedWebsitesOnFreePlan
            ? "IDLE"
            : "DISABLED"
        }
        onClick={() => setIsModalShown(true)}
      >
        Delete Company
      </DeleteCompanyButton>
      {areAllOwnedWebsitesCancellable && areAllOwnedWebsitesOnFreePlan ? (
        <ActionWarningModal
          isVisible={isModalShown}
          onClose={() => setIsModalShown(false)}
          isLoading={isModalLoading}
          subheading={companyDetails.name}
          heading="Are you sure you want to delete this Company?"
          text="By deleting this Company you're accepting the following:"
          checkBoxItems={[
            "All Websites that belong to this Company will be deleted",
            "All other data tied to this Company will be deleted",
            "All Team Members will lose access to this Company"
          ]}
          confirmActionText="Delete Company"
          onConfirmAction={async () => {
            setIsModalLoading(true);
            await companyDeleteMutation();
            history.push("/");
            toast.success("Company deleted");
          }}
          cancelActionText="Don't Delete"
        />
      ) : null}
      <Prompt
        when={isCompanyFormDirty}
        message={
          "You made some changes that are unsaved. " +
          "Are you sure you want to navigate away?"
        }
      />
    </>
  );
};

const CompanyInfoPageCard = styled(PageCard)`
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const CompanyInfoInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const CompanyInfoWarningCard = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.red1};
  background-color: ${props => transparentize(0.9, props.theme.palette.red1)};
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  border-radius: 4px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const DeleteCompanyButton = styled(ButtonWarning)`
  width: max-content;
`;
