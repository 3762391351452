import { transparentize } from "polished";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";

import {
  type EventsConnectorConfig,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonPrimaryAsLink,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconBolt,
  IconCheckMark,
  IconCircledInfo,
  IconCircledPause,
  IconCircledPlus,
  IconCoffee,
  IconGTM,
  IconRefresh,
  IconSearch
} from "elevar-design-system/src/icons";
import {
  InputFieldSelect,
  type Option
} from "elevar-design-system/src/inputs/InputFieldSelect";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { ElevarLogo } from "elevar-design-system/src/logos";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { type AppEducationSlideshowNode } from "../../api/handlers/appEducationSlideshows";
import {
  useEventsConnectorConfigQuery,
  useWebsiteOnboardingQuestionsMutation
} from "../../api/handlers/website";
import { Banner } from "../../components/Banner";
import { CongratsModal } from "../../components/CongratsModal";
import { FeatureTipsButton } from "../../components/FeatureTipsButton";
import { InputFieldSearch } from "../../components/InputFieldSearch";
import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { useOnboardingDetails } from "../../context/OnboardingDetails";
import { useServerSideDetails } from "../../context/ServerSideDetails";
import { useTrialDetails } from "../../context/TrialDetails";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { track } from "../../utils/track";
import {
  AddDestinationButton,
  type AddDestinationButtonItem
} from "./AddDestinationButton";
import { sourceCustomPages, sourceEnrich, sourceShopify } from "./data";
import {
  type EnchancedDestination,
  getEnchancedDestinations
} from "./getEnchancedDestinations";
import { SummaryCard } from "./SummaryCard";

/* ========================================================================== */

type SummaryType = "ONBOARDING" | "PAGE";

type SummaryProps = {
  type: SummaryType;
  website: WebsiteDetails;
  appEducationSlideshows?: Array<AppEducationSlideshowNode>;
};

export const Summary: React.FC<SummaryProps> = ({
  type,
  website,
  appEducationSlideshows
}) => {
  const { isOnboardingModalShown, setIsOnboardingModalShown } =
    useOnboardingDetails();

  const eventsConnectorConfig = useEventsConnectorConfigQuery();

  if (eventsConnectorConfig.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (eventsConnectorConfig.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <>
      <SummaryInner
        type={type}
        website={website}
        eventsConnectorConfig={eventsConnectorConfig.data}
        appEducationSlideshows={appEducationSlideshows}
      />
      <CongratsModal
        rainConfetti={true}
        isVisible={isOnboardingModalShown}
        onClose={() => setIsOnboardingModalShown(false)}
        title="Your configuration is live!"
        content={
          <>
            <p>
              You've unlocked more Elevar features, so you now can monitor
              conversion accuracy, analyze first vs last touch attribution and
              more!
            </p>
            <p>
              You're also sending more remarketing data to destinations. Be sure
              to analyze increased revenue in{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/how-to-analyze-klaviyo-server-side-performance-setup-abandoned-flows">
                Klaviyo
              </LinkExternal>
              , lower CPMs in Facebook, & other destinations.
            </p>
          </>
        }
      />
    </>
  );
};

const CenteredWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type SummaryFilter = "ALL" | "LIVE" | "OFFLINE" | "TEST_MODE";

const filterOptions: Array<Option<SummaryFilter>> = [
  { name: "All", value: "ALL" },
  { name: "Live", value: "LIVE" },
  { name: "Offline", value: "OFFLINE" },
  { name: "Test Mode", value: "TEST_MODE" }
];

const getFilteredDestinations = (
  destinations: Array<EnchancedDestination>,
  filter: { type: SummaryFilter } | { type: "CUSTOM"; value: string }
): Array<EnchancedDestination> => {
  switch (filter.type) {
    case "ALL":
      return destinations;
    case "LIVE":
      return destinations.map(d => ({
        ...d,
        instances: d.instances.filter(i => i.isLive)
      }));
    case "OFFLINE":
      return destinations.map(d => ({
        ...d,
        instances: d.instances.filter(i => !i.isLive && !i.isTestModeEnabled)
      }));
    case "TEST_MODE":
      return destinations.map(d => ({
        ...d,
        instances: d.instances.filter(i => i.isTestModeEnabled)
      }));
    case "CUSTOM": {
      const normalizedQuery = filter.value.toLowerCase().trim();
      return destinations.map(d => ({
        ...d,
        instances: d.instances.filter(i =>
          [i.title, ...d.base.searchKeywords, ...i.summaryItems].some(item =>
            item.toLowerCase().includes(normalizedQuery)
          )
        )
      }));
    }
  }
};

/* -------------------------------------------------------------------------- */

type SummaryInnerProps = {
  type: SummaryType;
  website: WebsiteDetails;
  eventsConnectorConfig: EventsConnectorConfig;
  appEducationSlideshows?: Array<AppEducationSlideshowNode>;
};

const SummaryInner: React.FC<SummaryInnerProps> = ({
  type,
  website,
  eventsConnectorConfig,
  appEducationSlideshows
}) => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { trialState, formattedTrialEndDate } = useTrialDetails();
  const { isFullyManaged } = useServerSideDetails();
  const { onboardingState } = useOnboardingDetails();
  const { sourceInfo, isDeadended } = useMyTrackingDetails();

  const { mutateAsync: websiteOnboardingQuestionsMutation } =
    useWebsiteOnboardingQuestionsMutation();

  const [query, setQuery] = useState<string | null>(null);
  const [filter, setFilter] = useState<SummaryFilter>("ALL");

  const centerRef = useRef<HTMLDivElement | null>(null);

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;

  const getUrl = useCallback(
    (type: "destination" | "source", shorthand: string) => {
      return `${myTrackingUrl}/${type}-${shorthand}`;
    },
    [myTrackingUrl]
  );

  const getDestinationUrl = useCallback(
    (shorthand: string) => getUrl("destination", shorthand),
    [getUrl]
  );

  const shopifySourceState = sourceInfo.shopify?.state ?? "SETUP";
  const isCustomPagesSourceAddable =
    sourceInfo.customPages?.state === "NOT_SETUP" &&
    sourceInfo.customPages.prompt === "ADD";
  const isCustomPagesSourceCardHidden =
    sourceInfo.customPages === null || isCustomPagesSourceAddable;

  const destinations = useMemo(
    () =>
      getEnchancedDestinations(
        getDestinationUrl,
        eventsConnectorConfig,
        onboardingState
      ),
    [getDestinationUrl, eventsConnectorConfig, onboardingState]
  );

  const areDestinationsAvailable =
    onboardingState.name === "DONE" ||
    (onboardingState.name === "ONBOARDING" &&
      onboardingState.info.step === "INSTALL_DESTINATIONS");

  const areAnyDestinationsVisible =
    areDestinationsAvailable &&
    destinations.some(destination => destination.instances.length > 0);

  const areAnyDestinationsSetup = destinations.some(destination =>
    destination.instances.some(instance => instance.isSetup)
  );

  const multi = website.permissions.includes("MULTIPLE_DESTINATION_CONFIGS");

  const drawerDestinations = useMemo(
    () =>
      destinations
        .filter(destination => destination.base.showInSummaryDrawer)
        .map<AddDestinationButtonItem>(destination => ({
          name: destination.base.name,
          shorthand: destination.base.shorthand,
          icon: <destination.base.icon size="24px" />,
          isBeta: destination.base.state === "BETA",
          state:
            destination.instances.length >= destination.base.limit
              ? "LOCKED_LIMIT_REACHED"
              : !multi && destination.instances.length > 0
                ? "LOCKED_PERMISSION_NEEDED"
                : "AVAILABLE",
          category: destination.base.searchCategory,
          keywords: destination.base.searchKeywords,
          isPopular: destination.base.isPopular
        })),
    [destinations, multi]
  );

  const filteredDestinations = useMemo(
    () =>
      getFilteredDestinations(
        destinations,
        query !== null ? { type: "CUSTOM", value: query } : { type: filter }
      ),
    [destinations, query, filter]
  );

  const upgradeCopySubject =
    sourceInfo.shopify?.state === "UPGRADE_REQUIRED" &&
    sourceInfo.customPages?.state === "UPGRADE_REQUIRED"
      ? "sources"
      : "source";

  const unfilteredInstanceCount = useMemo(
    () => destinations.flatMap(d => d.instances).length,
    [destinations]
  );
  const filteredInstanceCount = useMemo(
    () => filteredDestinations.flatMap(d => d.instances).length,
    [filteredDestinations]
  );

  const activeFilterOption = filterOptions.find(o => o.value === filter)!;
  const transparentOrange = transparentize(0.84, theme.palette.orange);

  const resetFilters = () => {
    setQuery(null);
    setFilter("ALL");
  };

  useEffect(() => {
    const onKeyPressHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") setQuery(null);
    };

    document.addEventListener("keydown", onKeyPressHandler);

    return () => {
      document.removeEventListener("keydown", onKeyPressHandler);
    };
  }, []);

  return (
    <>
      <SummaryPageWrapper isPage={type === "PAGE"}>
        {type === "PAGE" ? (
          <>
            {sourceInfo.shopify?.state === "UPGRADE_REQUIRED" ||
            sourceInfo.customPages?.state === "UPGRADE_REQUIRED" ? (
              <SummaryPageUpgradeBannerWrapper>
                <Link
                  to={
                    sourceInfo.shopify?.state === "UPGRADE_REQUIRED"
                      ? getUrl("source", sourceShopify.shorthand)
                      : getUrl("source", sourceCustomPages.shorthand)
                  }
                >
                  <Banner
                    textColor={theme.palette.white}
                    backgroundColor={theme.palette.blue1}
                    primaryText="Source Upgrade Available&nbsp;&nbsp;🎉"
                    secondaryText={`Upgrade your ${upgradeCopySubject} and you'll get automatic updates - so you're always on the latest & greatest.`}
                    actionText="Go to Upgrade"
                  />
                </Link>
              </SummaryPageUpgradeBannerWrapper>
            ) : sourceInfo.shopify?.state === "UPDATE_REQUIRED" ? (
              <SummaryPageUpgradeBannerWrapper>
                <Link to={getUrl("source", sourceShopify.shorthand)}>
                  <Banner
                    textColor={theme.palette.white}
                    backgroundColor={theme.palette.blue1}
                    primaryText="Source Update Available&nbsp;&nbsp;🎉"
                    secondaryText="Update your Shopify source to fully track checkout events with Shopify's Checkout + Thank You Extensibility."
                    actionText="Go to Update"
                  />
                </Link>
              </SummaryPageUpgradeBannerWrapper>
            ) : null}
            <SummaryPageHeader>
              <SummaryPageHeading>My Tracking</SummaryPageHeading>
              <div>
                {appEducationSlideshows ? (
                  <FeatureTipsButton
                    data={appEducationSlideshows}
                    route="MY_TRACKING"
                  />
                ) : null}
                {areAnyDestinationsVisible ? (
                  <AddDestinationButton
                    type="PRIMARY"
                    getDestinationUrl={getDestinationUrl}
                    destinations={drawerDestinations}
                  />
                ) : null}
              </div>
            </SummaryPageHeader>
            {trialState === "IN_PROGRESS" ? (
              <SummaryPageTrialBannerWrapper>
                <Banner
                  textColor={theme.palette.blue1}
                  backgroundColor={theme.palette.white}
                  borderColor={theme.palette.blue1}
                  primaryText="Free Trial"
                  secondaryText={`Your trial ends on ${formattedTrialEndDate}.`}
                />
              </SummaryPageTrialBannerWrapper>
            ) : null}
          </>
        ) : null}
        <SummaryWrapper>
          <div>
            <SummaryVisualWrapper
              visualType={isFullyManaged ? "FULLY_MANAGED" : "GTM"}
            >
              <div ref={centerRef}>
                {isFullyManaged ? (
                  <ElevarLogo color={theme.palette.white} />
                ) : (
                  <IconGTM size="24px" />
                )}
              </div>
            </SummaryVisualWrapper>
          </div>
          <div>
            <SummarySubHeading>
              <div>
                <div>Sources</div>
                {type === "ONBOARDING" ? (
                  <div>(where your data comes from)</div>
                ) : null}
              </div>
            </SummarySubHeading>
            <SummaryCardsWrapper>
              <SummaryCard
                centerRef={centerRef}
                details={{
                  type: "SOURCE",
                  data: sourceShopify,
                  explainer:
                    sourceInfo.shopify?.state === "UPDATE_REQUIRED"
                      ? "Update to fully track checkout events with Shopify's Checkout + Thank You Extensibility & optimize ROAS with enhanced new vs returning customer data."
                      : "Capture actions shoppers take on your online store front (like Add to Carts, Purchases) + any offline orders imported to Shopify (like POS orders).",
                  banner:
                    sourceInfo.shopify?.state === "UPGRADE_REQUIRED"
                      ? sourceInfo.shopify.isInProgress
                        ? {
                            icon: <IconRefresh size="16px" />,
                            text: "Upgrade Mode",
                            textColor: theme.palette.white,
                            backgroundColor: theme.palette.orange
                          }
                        : {
                            icon: <IconBolt size="16px" />,
                            text: "Upgrade Available",
                            textColor: theme.palette.orange,
                            backgroundColor: transparentOrange
                          }
                      : sourceInfo.shopify?.state === "UPDATE_REQUIRED"
                        ? sourceInfo.shopify.isInProgress
                          ? {
                              icon: <IconRefresh size="16px" />,
                              text: "Update Mode",
                              textColor: theme.palette.white,
                              backgroundColor: theme.palette.orange
                            }
                          : {
                              icon: <IconBolt size="16px" />,
                              text: "Update Available",
                              textColor: theme.palette.orange,
                              backgroundColor: transparentOrange
                            }
                        : null,
                  notice:
                    sourceInfo.shopify?.state === "SETUP" ||
                    sourceInfo.shopify?.state === "UPDATE_REQUIRED"
                      ? sourceInfo.shopify.isDetectedOnLiveTheme
                        ? {
                            icon: <IconCheckMark size="16px" />,
                            text: "Detected on Live Theme",
                            textColor: theme.palette.white,
                            backgroundColor: theme.palette.green
                          }
                        : {
                            icon: <IconCircledInfo size="16px" />,
                            text: "Not Detected on Live Theme",
                            textColor: theme.palette.white,
                            backgroundColor: theme.palette.orange
                          }
                      : null,
                  state:
                    shopifySourceState === "UPGRADE_REQUIRED"
                      ? { value: shopifySourceState, isAllowed: true }
                      : { value: shopifySourceState }
                }}
                lineState={
                  (sourceInfo.shopify?.state === "SETUP" &&
                    sourceInfo.shopify.isDetectedOnLiveTheme) ||
                  sourceInfo.shopify?.state === "UPGRADE_REQUIRED" ||
                  sourceInfo.shopify?.state === "UPDATE_REQUIRED"
                    ? "GREEN"
                    : (sourceInfo.shopify?.state === "SETUP" &&
                          !sourceInfo.shopify.isDetectedOnLiveTheme) ||
                        (sourceInfo.shopify?.state === "NOT_SETUP" &&
                          sourceInfo.shopify.wasAlreadyInstalled)
                      ? "ORANGE"
                      : "GREY"
                }
              />
              {!isCustomPagesSourceCardHidden ? (
                sourceInfo.customPages?.state === "NOT_SETUP" &&
                sourceInfo.customPages.prompt === "YES/NO" ? (
                  <SummaryOnboardingCard type="CUSTOM_PAGES_YES/NO" />
                ) : sourceInfo.customPages?.state === "NOT_SETUP" &&
                  sourceInfo.customPages.prompt === "DEFER" ? (
                  <SummaryOnboardingCard type="CUSTOM_PAGES_DEFER" />
                ) : (
                  <SummaryCard
                    centerRef={centerRef}
                    details={{
                      type: "SOURCE",
                      data: sourceCustomPages,
                      explainer:
                        "Set up this source to know shoppers' entire journeys by capturing attribution data from non-Shopify subdomains. E.g. a WordPress blog, third-party landing pages, or a headless website.",
                      notice:
                        sourceInfo.customPages?.state === "SETUP"
                          ? {
                              icon: <IconCheckMark size="16px" />,
                              text: "Completed",
                              textColor: theme.palette.white,
                              backgroundColor: theme.palette.green
                            }
                          : sourceInfo.customPages?.state === "UPGRADE_REQUIRED"
                            ? sourceInfo.customPages.isInProgress
                              ? {
                                  icon: <IconRefresh size="16px" />,
                                  text: "Upgrade Mode",
                                  textColor: theme.palette.white,
                                  backgroundColor: theme.palette.orange
                                }
                              : {
                                  icon: <IconBolt size="16px" />,
                                  text: "Upgrade Available",
                                  textColor: theme.palette.orange,
                                  backgroundColor: transparentOrange
                                }
                            : null,
                      state:
                        sourceInfo.customPages!.state === "UPGRADE_REQUIRED"
                          ? {
                              value: sourceInfo.customPages!.state,
                              isAllowed: sourceInfo.customPages!.isInProgress
                            }
                          : { value: sourceInfo.customPages!.state }
                    }}
                    lineState={
                      sourceInfo.customPages?.state === "SETUP" ||
                      (sourceInfo.customPages?.state === "UPGRADE_REQUIRED" &&
                        !sourceInfo.customPages.isInProgress)
                        ? "GREEN"
                        : sourceInfo.customPages?.state ===
                              "UPGRADE_REQUIRED" &&
                            sourceInfo.customPages.isInProgress
                          ? "ORANGE"
                          : "GREY"
                    }
                  />
                )
              ) : null}
              {sourceInfo.enrich ? (
                sourceInfo.enrich.isConsentQuestionShown ? (
                  <SummaryOnboardingCard type="ENRICH_CONSENT" />
                ) : (
                  <SummaryCard
                    centerRef={centerRef}
                    details={{
                      type: "SOURCE",
                      data: sourceEnrich,
                      explainer: "",
                      notice: sourceInfo.enrich.isOn
                        ? {
                            icon: <IconCheckMark size="16px" />,
                            text: "On",
                            textColor: theme.palette.white,
                            backgroundColor: theme.palette.green
                          }
                        : {
                            icon: <IconCircledPause size="16px" />,
                            text: "Off",
                            textColor: theme.palette.white,
                            backgroundColor: theme.palette.grey5
                          },
                      state: { value: "SETUP" as const }
                    }}
                    lineState={sourceInfo.enrich.isOn ? "GREEN" : "GREY"}
                  />
                )
              ) : null}
              {isCustomPagesSourceAddable ? (
                <AddCustomPagesSourceLink
                  to={getUrl("source", sourceCustomPages.shorthand)}
                >
                  <div>
                    <IconCircledPlus size="24px" />
                  </div>
                  <div>Add {sourceCustomPages.name}</div>
                </AddCustomPagesSourceLink>
              ) : null}
            </SummaryCardsWrapper>
          </div>
          <div>
            <SummarySubHeading>
              <div>
                <div>Destinations</div>
                {type === "ONBOARDING" ? (
                  <div>(where your data is sent to)</div>
                ) : null}
              </div>
              {type === "PAGE" && unfilteredInstanceCount > 1 ? (
                query === null ? (
                  <SummarySubHeadingActionsSearchHidden>
                    <button onClick={() => setQuery("")}>
                      <IconSearch size="24px" />
                    </button>
                    <div>
                      <InputFieldSelect
                        variant="SMALL"
                        renderedOn="PAGE"
                        value={activeFilterOption}
                        setValue={option => setFilter(option.value)}
                        options={filterOptions}
                        placeholder=""
                      />
                    </div>
                  </SummarySubHeadingActionsSearchHidden>
                ) : (
                  <SummarySubHeadingActionsSearchExposed
                    onBlur={() => {
                      if (query === "") resetFilters();
                    }}
                  >
                    <InputFieldSearch
                      variant="SMALL"
                      renderedOn="PAGE"
                      value={query}
                      onChange={event => setQuery(event.target.value)}
                      placeholder="Search by name or info"
                      canClear={true}
                      onClear={resetFilters}
                      autoFocus={true}
                    />
                  </SummarySubHeadingActionsSearchExposed>
                )
              ) : null}
            </SummarySubHeading>
            <SummaryCardsWrapper>
              {!areAnyDestinationsVisible ? (
                areDestinationsAvailable && !isDeadended ? (
                  <SummaryAddDestinationPageCard>
                    <div>
                      <div>You're ready to add destinations</div>
                      <div>
                        To continue, click the "Add Destination" button below
                      </div>
                    </div>
                    <div>
                      <AddDestinationButton
                        type="PRIMARY"
                        getDestinationUrl={getDestinationUrl}
                        destinations={drawerDestinations}
                      />
                    </div>
                  </SummaryAddDestinationPageCard>
                ) : isDeadended ? (
                  <SummaryAddDestinationPageCard>
                    <div>
                      <div>No destinations configured</div>
                      <div>
                        You cannot add new destinations on your current plan
                      </div>
                    </div>
                  </SummaryAddDestinationPageCard>
                ) : (
                  <SummaryAddDestinationPageCard>
                    <div>
                      <div>No destinations available yet</div>
                      <div>Please set up your data sources first</div>
                    </div>
                  </SummaryAddDestinationPageCard>
                )
              ) : type === "ONBOARDING" && areAnyDestinationsSetup ? (
                <SummaryAddDestinationPageCard>
                  <div>
                    <div>Ready for what's next?</div>
                    <div>
                      Add another destination or go to the next setup step
                    </div>
                  </div>
                  <div>
                    <AddDestinationButton
                      type="SECONDARY"
                      getDestinationUrl={getDestinationUrl}
                      destinations={drawerDestinations}
                    />
                    <ButtonPrimary
                      variant="SMALL"
                      onClick={async () => {
                        await websiteOnboardingQuestionsMutation({
                          destinations_confirmed: true
                        });
                        track.myTrackingDestinationNextStepButtonClick();
                      }}
                    >
                      Next Step
                    </ButtonPrimary>
                  </div>
                </SummaryAddDestinationPageCard>
              ) : null}
              {areAnyDestinationsVisible ? (
                filteredInstanceCount > 0 ? (
                  filteredDestinations.map(d =>
                    d.instances.map(i => (
                      <SummaryCard
                        // Forces lines to redraw when search changes
                        key={`${i.title}-${i.id}-${filter}-${query}`}
                        centerRef={centerRef}
                        details={{
                          type: "DESTINATION",
                          data: d.base,
                          instance: i,
                          notice: i.isTestModeEnabled
                            ? {
                                icon: <IconCoffee size="16px" />,
                                text: "Test Mode",
                                textColor: theme.palette.white,
                                backgroundColor: theme.palette.orange
                              }
                            : i.isLive
                              ? {
                                  icon: <IconCheckMark size="16px" />,
                                  text: "Live",
                                  textColor: theme.palette.white,
                                  backgroundColor: theme.palette.green
                                }
                              : isFullyManaged && i.isSetup
                                ? {
                                    icon: <IconCircledPause size="16px" />,
                                    text: "Offline",
                                    textColor: theme.palette.white,
                                    backgroundColor: theme.palette.grey5
                                  }
                                : !isFullyManaged && i.isSetup
                                  ? {
                                      icon: <IconCheckMark size="16px" />,
                                      text: "Completed",
                                      textColor: theme.palette.white,
                                      backgroundColor: theme.palette.green
                                    }
                                  : null,
                          state: i.isSetup
                            ? { value: "SETUP" }
                            : i.isLive
                              ? { value: "UPDATE_REQUIRED" }
                              : { value: "NOT_SETUP" }
                        }}
                        lineState={
                          i.isLive || (!isFullyManaged && i.isSetup)
                            ? "GREEN"
                            : "GREY"
                        }
                      />
                    ))
                  )
                ) : (
                  <SummaryAddDestinationPageCard>
                    <div>
                      <div>Oops, no destinations match your search</div>
                    </div>
                  </SummaryAddDestinationPageCard>
                )
              ) : null}
              {areAnyDestinationsVisible &&
              type === "ONBOARDING" &&
              !areAnyDestinationsSetup ? (
                <SummaryAddDestinationPageCard>
                  <div>
                    <div>Need a different destination?</div>
                  </div>
                  <div>
                    <AddDestinationButton
                      type="PRIMARY"
                      getDestinationUrl={getDestinationUrl}
                      destinations={drawerDestinations}
                    />
                  </div>
                </SummaryAddDestinationPageCard>
              ) : null}
            </SummaryCardsWrapper>
          </div>
        </SummaryWrapper>
      </SummaryPageWrapper>
      {onboardingState.name === "ONBOARDING" ? <SummaryEnrichModal /> : null}
    </>
  );
};

type SummaryPageWrapperProps = {
  isPage: boolean;
};

const SummaryPageWrapper = styled.div<SummaryPageWrapperProps>`
  position: relative;

  ${props =>
    props.isPage
      ? css`
          flex: 1;
          display: flex;
          flex-direction: column;
          padding-top: ${props => props.theme.gridBase * 3.25}px;
          padding-bottom: ${props => props.theme.gridBase * 4}px;
          padding-left: ${props => props.theme.gridBase * 4}px;
          padding-right: ${props => props.theme.gridBase * 4}px;
        `
      : null}
`;

const SummaryPageUpgradeBannerWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const SummaryPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
  min-height: ${props => props.theme.gridBase * 5}px;

  > div:last-child {
    display: flex;
    gap: ${props => props.theme.gridBase * 2}px;
  }
`;

const SummaryPageTrialBannerWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const SummaryPageHeading = styled.div`
  ${heading2Styles};
`;

const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns:
    minmax(
      ${props => props.theme.gridBase * 44}px,
      ${props => props.theme.gridBase * 70}px
    )
    minmax(${props => props.theme.gridBase * 17.5}px, auto)
    minmax(
      ${props => props.theme.gridBase * 44}px,
      ${props => props.theme.gridBase * 70}px
    );

  > div {
    grid-row: 1;
  }

  > div:nth-child(1) {
    grid-column: 2;
  }

  > div:nth-child(2) {
    grid-column: 1;
  }

  > div:nth-child(3) {
    grid-column: 3;
  }
`;

const SummarySubHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.theme.gridBase * 5}px;
  margin-bottom: ${props => props.theme.gridBase * 1.75}px;

  > div:first-child {
    display: flex;
    gap: ${props => props.theme.gridBase * 0.75}px;

    > div:first-child {
      ${subheadingStyles};
      color: ${props => props.theme.palette.grey1};
    }

    > div:not(:first-child) {
      ${smallTextStyles};
      color: ${props => props.theme.palette.grey3};
    }
  }
`;

const SummarySubHeadingActionsSearchHidden = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase * 1.5}px;

  > button {
    ${iconButtonStyles};
    color: ${props => props.theme.palette.grey3};
  }

  > div {
    width: ${props => props.theme.gridBase * 15}px;
  }
`;

const SummarySubHeadingActionsSearchExposed = styled.div`
  max-width: ${props => props.theme.gridBase * 30}px;
`;

const SummaryCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gridBase * 2}px;
`;

const AddCustomPagesSourceLink = styled(Link)`
  ${iconTextButtonStyles};
  width: max-content;
`;

type SummaryVisualWrapperProps = {
  visualType: "GTM" | "FULLY_MANAGED";
};

const SummaryVisualWrapper = styled.div<SummaryVisualWrapperProps>`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.gridBase * 5.5}px;
  padding: 0 ${props => props.theme.gridBase * 8}px;

  > div {
    display: flex;
    justify-content: center;
    background-color: ${props =>
      props.visualType === "GTM"
        ? props.theme.palette.grey8
        : props.theme.palette.purple2};
    border-radius: 50%;
    border-width: ${props => props.theme.gridBase * 1.5}px;
    border-style: solid;
    border-color: ${props =>
      props.visualType === "GTM" ? props.theme.palette.grey6 : " #dfdefa"};
    padding: ${props => props.theme.gridBase * 2.5}px;

    > svg {
      width: ${props => props.theme.gridBase * 4}px;
      height: ${props => props.theme.gridBase * 4}px;
    }
  }

  @media screen and (max-width: 1400px) {
    margin-top: ${props => props.theme.gridBase * 7}px;

    > div {
      border-width: ${props => props.theme.gridBase}px;
      padding: ${props => props.theme.gridBase * 2}px;

      > svg {
        width: ${props => props.theme.gridBase * 3}px;
        height: ${props => props.theme.gridBase * 3}px;
      }
    }
  }
`;

const SummaryAddDestinationPageCard = styled(PageCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${props => props.theme.gridBase * 15}px;

  > div:first-child {
    > div:first-child {
      ${heading3Styles};
      text-align: center;
    }

    > div:not(:first-child) {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
      text-align: center;
      margin-top: ${props => props.theme.gridBase}px;
    }
  }

  > div:not(:first-child) {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 2}px;
    margin-top: ${props => props.theme.gridBase * 2}px;
  }
`;

/* ========================================================================== */

type SummaryOnboardingCardProps = {
  type: "CUSTOM_PAGES_YES/NO" | "CUSTOM_PAGES_DEFER" | "ENRICH_CONSENT";
};

const SummaryOnboardingCard: React.FC<SummaryOnboardingCardProps> = ({
  type
}) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const { mutateAsync: questionsMutation } =
    useWebsiteOnboardingQuestionsMutation();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;
  const getUrl = (sh: string) => `${myTrackingUrl}/source-${sh}`;

  switch (type) {
    case "CUSTOM_PAGES_YES/NO":
      return (
        <PageCard>
          <SummaryCardHeader>
            <div>Do you have non-Shopify subdomain pages?</div>
          </SummaryCardHeader>
          <SummaryCardExplainer>
            If you have non-Shopify subdomain pages (such as a WordPress blog,
            third-party landing pages, or a headless website), then you'll need
            to set up this source if you want us to capture attribution data for
            your shoppers' entire journey.
          </SummaryCardExplainer>
          <SummaryCardActions>
            <ButtonPrimaryAsLink
              variant="SMALL"
              to={getUrl(sourceCustomPages.shorthand)}
              onClick={() => questionsMutation({ has_custom_pages: true })}
            >
              Yes
            </ButtonPrimaryAsLink>
            <ButtonSecondary
              variant="SMALL"
              onClick={() => questionsMutation({ has_custom_pages: false })}
            >
              No
            </ButtonSecondary>
          </SummaryCardActions>
        </PageCard>
      );
    case "CUSTOM_PAGES_DEFER":
      return (
        <PageCard>
          <SummaryCardHeader>
            <div>
              <sourceCustomPages.icon size="24px" />
            </div>
            <div>{sourceCustomPages.name}</div>
          </SummaryCardHeader>
          <SummaryCardExplainer>
            If you have non-Shopify subdomain pages (such as a WordPress blog,
            third-party landing pages, or a headless website), then you'll need
            to set up this source if you want us to capture attribution data for
            your shoppers' entire journey.
          </SummaryCardExplainer>
          <SummaryCardActions>
            <ButtonPrimaryAsLink
              variant="SMALL"
              to={getUrl(sourceCustomPages.shorthand)}
            >
              Set Up
            </ButtonPrimaryAsLink>
            <ButtonSecondary
              variant="SMALL"
              onClick={async () => {
                await questionsMutation({ custom_pages_setup_later: true });
              }}
            >
              Set Up Later
            </ButtonSecondary>
          </SummaryCardActions>
        </PageCard>
      );
    case "ENRICH_CONSENT":
      return (
        <PageCard>
          <SummaryCardHeader>
            <div>
              Do you need to enable consent mode for Session Enrichment?
            </div>
          </SummaryCardHeader>
          <SummaryCardExplainer>
            By enabling consent mode, Session Enrichment will be made "consent
            aware" – allowing you to adjust when data gets sent to Session
            Enrichment based on the consent choices of your users.
          </SummaryCardExplainer>
          <SummaryCardActions>
            <ButtonPrimaryAsLink
              variant="SMALL"
              to={{
                pathname: `${getUrl(sourceEnrich.shorthand)}/consent-mode`,
                state: { isFromOnboardingQuestion: true }
              }}
            >
              Yes
            </ButtonPrimaryAsLink>
            <ButtonSecondary
              variant="SMALL"
              onClick={async () => {
                await questionsMutation({
                  enrich_consent_prompt_dismissed: true
                });
              }}
            >
              No
            </ButtonSecondary>
          </SummaryCardActions>
        </PageCard>
      );
  }
};

const SummaryCardHeader = styled.div`
  display: flex;
  gap: ${props => props.theme.gridBase * 2}px;

  > div:first-child:not(:last-child) {
    display: flex;
    color: ${props => props.theme.palette.purple2};
  }

  > div:last-child {
    ${heading3Styles};
  }
`;

const SummaryCardExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-top: ${props => props.theme.gridBase * 2}px;
`;

const SummaryCardActions = styled.div`
  display: flex;
  gap: ${props => props.theme.gridBase * 2}px;
  margin-top: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

const SummaryEnrichModal: React.FC = () => {
  const { sourceInfo } = useMyTrackingDetails();

  const { mutateAsync: websiteOnboardingQuestionsMutation } =
    useWebsiteOnboardingQuestionsMutation();

  return (
    <CongratsModal
      rainConfetti={false}
      isVisible={sourceInfo.enrich?.isModalVisible ?? false}
      onClose={async () => {
        await websiteOnboardingQuestionsMutation({
          enrich_modal_dismissed: true
        });
      }}
      title="You unlocked the Session Enrichment feature!"
      content={
        <p>
          Identify users without relying on cookies with Session Enrichment.
          Empower data-driven campaigns by recognizing returning users &
          enhancing data sent to Facebook CAPI, Klaviyo, Google Ads, and more!
        </p>
      }
    />
  );
};
