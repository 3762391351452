import { useState } from "react";
import styled, { css } from "styled-components";

import {
  ButtonDropdownBase,
  type DropdownPlacement
} from "elevar-design-system/src/buttons/ButtonDropdown";
import {
  baseButtonStyles,
  smallButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  CheckBox,
  type CheckBoxVariant
} from "elevar-design-system/src/CheckBox";
import { IconCircle, IconCircledPlus } from "elevar-design-system/src/icons";
import { scrollbarMixin } from "elevar-design-system/src/scrollbar";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { useCubeApiDetails } from "../../context/CubeApiDetails";

/* ========================================================================== */

type DimensionCubeProps = {
  initialActiveDimensions: Array<string>;
  dimensions: Array<string>;
  setQueryDimensions: (dimensions: Array<string>) => void;
  dropdownPlacement: DropdownPlacement;
  dimensionTitleMapper?: (title: string) => string;
  maxItemsInView?: number;
};

export const DimensionCube: React.FC<DimensionCubeProps> = ({
  initialActiveDimensions,
  dimensions,
  setQueryDimensions,
  dropdownPlacement,
  dimensionTitleMapper = t => t,
  maxItemsInView = 6
}) => {
  const cubeApiDetails = useCubeApiDetails();

  const [activeDimensions, setActiveDimensions] = useState(
    initialActiveDimensions
  );

  const toggleDimension = (dimension: string) => {
    const newActiveDimensions = activeDimensions.includes(dimension)
      ? activeDimensions.filter(active => active !== dimension)
      : [...activeDimensions, dimension];

    setActiveDimensions(newActiveDimensions);
    setQueryDimensions(newActiveDimensions);
  };

  return (
    <div>
      <DropdownActive
        dropdownPlacement={dropdownPlacement}
        activeCount={
          dimensions.filter(i => activeDimensions.includes(i)).length
        }
        showActiveCount={true}
        dropdownContent={() => (
          <DropdownWrapper
            optionCount={dimensions.length}
            maxItemsInView={maxItemsInView}
          >
            {dimensions.map(dimension => {
              const isLocked =
                activeDimensions.length === 1 &&
                activeDimensions.includes(dimension);

              const member = cubeApiDetails.meta.resolveMember(
                dimension,
                "dimensions"
              );

              return "error" in member ? null : (
                <Tooltip
                  key={dimension}
                  text="Must have at least 1 dimension"
                  placement="left"
                  disabled={!isLocked}
                >
                  <div>
                    <DropdownOptionCheckbox
                      isChecked={activeDimensions.includes(dimension)}
                      isLocked={isLocked}
                      toggle={() => toggleDimension(dimension)}
                      value={dimensionTitleMapper(member.shortTitle)}
                    />
                  </div>
                </Tooltip>
              );
            })}
          </DropdownWrapper>
        )}
      >
        Dimensions
      </DropdownActive>
    </div>
  );
};

type DropdownWrapperProps = {
  optionCount: number;
  maxItemsInView: number;
};

const DropdownWrapper = styled.div<DropdownWrapperProps>`
  ${scrollbarMixin};
  margin: ${props => props.theme.gridBase * 0.5}px;
  overflow-y: auto;
  padding-right: ${props =>
    props.optionCount > props.maxItemsInView
      ? props.theme.gridBase * 0.5
      : 0}px;
  max-height: ${props =>
    props.theme.gridBase * props.maxItemsInView * 5 +
    props.theme.gridBase * 2.75}px;
`;

/* ========================================================================== */

type DropdownActiveProps = {
  activeCount: number;
  showActiveCount: boolean;
  dropdownPlacement: DropdownPlacement;
  dropdownContent: () => React.ReactNode;
  children: React.ReactNode;
};

const DropdownActive: React.FC<DropdownActiveProps> = ({
  activeCount,
  showActiveCount,
  dropdownPlacement,
  dropdownContent,
  children
}) => {
  return (
    <DropdownActiveInternal
      activeCount={activeCount}
      showActiveCount={showActiveCount}
      dropdownPlacement={dropdownPlacement}
      dropdownContent={dropdownContent}
    >
      {showActiveCount ? (
        activeCount > 0 ? (
          <ActiveAmount>
            <IconCircle size="24px" />
            <span>{activeCount}</span>
          </ActiveAmount>
        ) : (
          <IconCircledPlus size="24px" />
        )
      ) : null}
      <DropdownActiveChildrenWrapper>{children}</DropdownActiveChildrenWrapper>
    </DropdownActiveInternal>
  );
};

const DropdownActiveInternal = styled(ButtonDropdownBase)<DropdownActiveProps>`
  ${baseButtonStyles};
  ${smallButtonStyles};
  background-color: ${props => props.theme.palette.white};
  padding-top: ${props => props.theme.gridBase * 1.25 - 1}px;
  padding-bottom: ${props => props.theme.gridBase * 1.25 - 1}px;
  padding-left: ${props => props.theme.gridBase * 2 - 1}px;
  padding-right: ${props => props.theme.gridBase * 1.5 - 1}px;
  display: flex;
  align-items: center;
  text-align: left;
  white-space: nowrap;

  ${props =>
    props.activeCount > 0
      ? css`
          background-color: ${props.theme.palette.blue1};
          border: 1px solid ${props.theme.palette.blue1};
          color: ${props.theme.palette.white};
        `
      : css`
          border: 1px solid ${props.theme.palette.grey6};
        `}

  ${props =>
    props.showActiveCount
      ? css`
          padding-top: ${props => props.theme.gridBase - 1}px;
          padding-right: ${props => props.theme.gridBase * 2.5 - 1}px;
          padding-bottom: ${props => props.theme.gridBase - 1}px;
          padding-left: ${props => props.theme.gridBase * 2 - 1}px;

          > :not(:first-child) {
            margin-left: ${props => props.theme.gridBase}px;
          }
        `
      : null}
`;

const ActiveAmount = styled.div`
  position: relative;
  height: ${props => props.theme.gridBase * 3}px;
  width: ${props => props.theme.gridBase * 3}px;

  > span {
    ${smallTextStyles};
    color: currentColor;
    position: absolute;
    text-align: center;
    top: 0;
    height: ${props => props.theme.gridBase * 3}px;
    width: ${props => props.theme.gridBase * 3}px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const DropdownActiveChildrenWrapper = styled.div`
  display: flex;
  align-items: center;
`;

/* ========================================================================== */

type DropdownOptionCheckboxProps = {
  value: string;
  toggle: () => void;
  isChecked: boolean;
  isLocked: boolean;
  variant?: CheckBoxVariant;
};

const DropdownOptionCheckbox: React.FC<DropdownOptionCheckboxProps> = ({
  value,
  toggle,
  isChecked,
  isLocked,
  variant
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <DropdownOption
      onClick={toggle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      disabled={isLocked}
    >
      <CheckBox
        variant={isLocked ? "DISABLED" : (variant ?? "NORMAL")}
        isChecked={isChecked}
        isHovered={isHovered}
        isFocused={isFocused}
      />
      <span>{value}</span>
    </DropdownOption>
  );
};

const DropdownOption = styled.button`
  ${normalTextStyles};
  width: 100%;
  min-width: ${props => props.theme.gridBase * 20}px;
  background-color: ${props => props.theme.palette.white};
  line-height: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 2.25}px;
  white-space: nowrap;
  border-radius: 2px;

  &:hover {
    background-color: ${props => props.theme.palette.grey8};
  }

  > :not(:first-child) {
    margin-left: ${props => props.theme.gridBase * 1.5}px;
  }
`;
