import { difference } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  type CompanyDetails,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import {
  type AppEducationSlideshowNode,
  useAppEducationSlideshowsQuery
} from "../../api/handlers/appEducationSlideshows";
import { useCompanyDetailsQuery } from "../../api/handlers/company";
import {
  requiredScopes,
  useShopifyOAuthScopesQuery
} from "../../api/handlers/shopify";
import { useWebsiteDetailsQuery } from "../../api/handlers/website";
import { useExtendedQueryClient } from "../../api/utils";
import { Modal } from "../../components/Modal";
import { PageNotFound } from "../../components/PageNotFound";
import { useGlobalError } from "../../context/GlobalError";
import { RedirectWithoutLastLocation } from "../../context/LastLocation";
import {
  OnboardingDetailsProvider,
  useOnboardingDetails
} from "../../context/OnboardingDetails";
import {
  ServerSideDetailsProvider,
  useServerSideDetails
} from "../../context/ServerSideDetails";
import { TimezoneProvider } from "../../context/Timezone";
import { TitleProvider, TitleWebsiteProvider } from "../../context/Title";
import { TrialDetailsProvider } from "../../context/TrialDetails";
import { useUserRequired } from "../../context/User";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { track } from "../../utils/track";
import { CroIdeas } from "../croIdeas/CroIdeas";
import { Dashboard } from "../dashboard/Dashboard";
import { EventBuilder } from "../EventBuilder";
import { AttributionFeed } from "../monitoring/attributionFeed/AttributionFeed";
import { ChannelAccuracy } from "../monitoring/channelAccuracy/ChannelAccuracy";
import { MyTracking } from "../myTracking/MyTracking";
import { useShopifyRedirects } from "../myTracking/SourceShopify";
import { PreBuiltTags } from "../preBuiltTags/PreBuiltTags";
import { RealTimeActivity } from "../RealTimeActivity";
import { Support } from "../Support";
import { CancelPlan } from "./settings/CancelPlan";
import { Connections } from "./settings/connections/Connections";
import { History } from "./settings/History";
import { ManagePlan } from "./settings/managePlan/ManagePlan";
import { PlanAndUsage } from "./settings/PlanAndUsage";
import { Products } from "./settings/Products";
import { PurchasedProducts } from "./settings/PurchasedProducts";
import { WebsiteInfo } from "./settings/WebsiteInfo";
import { InitialQuestions } from "./InitialQuestions";
import { ProductFinalize } from "./ProductFinalize";
import { type NavigationData, WebsiteLayout } from "./WebsiteLayout";

/* ========================================================================== */

export const Website: React.FC = () => {
  const { websiteId } = useParams<{ websiteId?: string }>();

  if (Number.isSafeInteger(Number(websiteId))) {
    return <WebsiteInner1 />;
  } else {
    return <RedirectWithoutLastLocation to="/" />;
  }
};

/* ========================================================================== */

const WebsiteInner1: React.FC = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  const [isQueryClientUpdated, setIsQueryClientUpdated] = useState(false);

  useEffect(() => {
    const defaultOptions = queryClient.base.getDefaultOptions();

    queryClient.base.setDefaultOptions({
      queries: { ...defaultOptions.queries, meta: { websiteId } },
      mutations: { ...defaultOptions.mutations, meta: { websiteId } }
    });

    setIsQueryClientUpdated(true);

    return () => {
      queryClient.base.setDefaultOptions({
        queries: { ...defaultOptions.queries, meta: undefined },
        mutations: { ...defaultOptions.mutations, meta: undefined }
      });
    };
  }, [queryClient.base, websiteId]);

  if (isQueryClientUpdated) {
    return <WebsiteInner2 />;
  } else {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

const WebsiteInner2: React.FC = () => {
  const appEducationSlideshows = useAppEducationSlideshowsQuery();
  const companyDetails = useCompanyDetailsQuery();
  const websiteDetails = useWebsiteDetailsQuery({ retry: false });

  if (websiteDetails.error !== null) {
    return <PageNotFound type="WEBSITE" />;
  }

  if (appEducationSlideshows.error !== null || companyDetails.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    appEducationSlideshows.data === undefined ||
    companyDetails.data === undefined ||
    websiteDetails.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <TimezoneProvider websiteDetails={websiteDetails.data}>
      <TitleWebsiteProvider websiteDetails={websiteDetails.data}>
        <TrialDetailsProvider websiteDetails={websiteDetails.data}>
          <ServerSideDetailsProvider websiteDetails={websiteDetails.data}>
            <OnboardingDetailsProvider websiteDetails={websiteDetails.data}>
              <WebsiteInner3
                companyDetails={companyDetails.data}
                appEducationSlideshows={appEducationSlideshows.data}
                websiteDetails={websiteDetails.data}
              />
              <GlobalErrorModal websiteDetails={websiteDetails.data} />
            </OnboardingDetailsProvider>
          </ServerSideDetailsProvider>
        </TrialDetailsProvider>
      </TitleWebsiteProvider>
    </TimezoneProvider>
  );
};

/* ========================================================================== */

const websitePath = "/company/:companyId/website/:websiteId";

type WebsiteInner3Props = {
  companyDetails: CompanyDetails;
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
  websiteDetails: WebsiteDetails;
};

const WebsiteInner3: React.FC<WebsiteInner3Props> = ({
  companyDetails,
  appEducationSlideshows,
  websiteDetails
}) => {
  const { accountDetails } = useUserRequired();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { isFullyManaged } = useServerSideDetails();
  const { onboardingState } = useOnboardingDetails();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;

  useEffect(() => {
    track.websiteView({
      userEmail: accountDetails.email,
      website: {
        id: websiteDetails.id,
        name: websiteDetails.name,
        url: websiteDetails.url,
        plan: websiteDetails.plan,
        has_hubspot_id: websiteDetails.has_hubspot_id,
        subscription_type: websiteDetails.subscription_type
      }
    });
  }, [
    accountDetails.email,
    websiteDetails.id,
    websiteDetails.name,
    websiteDetails.url,
    websiteDetails.plan,
    websiteDetails.has_hubspot_id,
    websiteDetails.subscription_type
  ]);

  const isDelinquent = websiteDetails.subscription_status.is_delinquent;
  const isCompanyAdmin =
    companyDetails.role === "Owner" || companyDetails.role === "Admin";

  if (isDelinquent) {
    return (
      <Switch>
        {isCompanyAdmin ? (
          <Route
            exact={true}
            path={`${websitePath}/settings/plan/manage`}
            render={() => (
              <TitleProvider page="Manage Plan">
                <ManagePlan websiteDetails={websiteDetails} />
              </TitleProvider>
            )}
          />
        ) : null}
        <RedirectWithoutLastLocation to="/" />
      </Switch>
    );
  }

  if (onboardingState.name === "INITIAL_QUESTIONS") {
    return (
      <Switch>
        <Route
          exact={true}
          path={websitePath}
          render={() => (
            <TitleProvider page="Initial Questions">
              <InitialQuestions />
            </TitleProvider>
          )}
        />
        <RedirectWithoutLastLocation to={websiteUrl} />
      </Switch>
    );
  }

  if (onboardingState.name === "PLAN_SELECTION") {
    return (
      <Switch>
        <Route
          exact={true}
          path={websitePath}
          render={() => (
            <TitleProvider page="Manage Plan">
              <ManagePlan websiteDetails={websiteDetails} />
            </TitleProvider>
          )}
        />
        <RedirectWithoutLastLocation to={websiteUrl} />
      </Switch>
    );
  }

  const isV1Installed = websiteDetails.data_layer_version === "1.0.0";
  const isOnDefaultFreePlan = websiteDetails.plan.id === "DEFAULT_FREE_PLAN";
  const isOnboardingCompleted = onboardingState.name === "DONE";
  const areProductRoutesAvailable = !isOnDefaultFreePlan && isFullyManaged;

  const isConnectionsRouteAvailable =
    websiteDetails.permissions.includes("GTM_AUTH") ||
    (websiteDetails.workspace.ss_client_id !== null && !isFullyManaged);

  const navigationData: NavigationData = {
    isV1Installed,
    isOnDefaultFreePlan,
    isCompanyAdmin,
    onboardingState,
    areProductRoutesAvailable,
    isConnectionsRouteAvailable,
    userName: `${accountDetails.first_name} ${accountDetails.last_name}`,
    userImage: accountDetails.profile.picture,
    companyName: companyDetails.name,
    companyImage: companyDetails.picture,
    websiteName: websiteDetails.name,
    serverSideType: websiteDetails.server_side_type
  };

  return (
    <Switch>
      <Route
        exact={true}
        path={`${websitePath}/product_finalize`}
        render={() => <ProductFinalize />}
      />
      {isOnboardingCompleted ? (
        <Route
          exact={true}
          path={websitePath}
          render={() => (
            <TitleProvider page="Dashboard">
              <WebsiteLayout
                navigationData={navigationData}
                showMigrationBanner={false}
              >
                <Dashboard
                  appEducationSlideshows={appEducationSlideshows}
                  website={websiteDetails}
                  isCompanyAdmin={isCompanyAdmin}
                />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      {isFullyManaged || (!isV1Installed && !isOnDefaultFreePlan) ? (
        <Route
          exact={false}
          path={`${websitePath}/my-tracking`}
          render={() => (
            <TitleProvider page="My Tracking">
              <WebsiteLayout navigationData={navigationData}>
                <MyTracking
                  appEducationSlideshows={appEducationSlideshows}
                  website={websiteDetails}
                  isCompanyAdmin={isCompanyAdmin}
                  onboardingState={onboardingState}
                />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      {isOnboardingCompleted && isFullyManaged ? (
        <Route
          exact={true}
          path={`${websitePath}/real-time-activity`}
          render={() => (
            <TitleProvider page="Real-Time Activity">
              <WebsiteLayout navigationData={navigationData}>
                <RealTimeActivity
                  appEducationSlideshows={appEducationSlideshows}
                  website={websiteDetails}
                  isCompanyAdmin={isCompanyAdmin}
                />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      {isOnboardingCompleted ? (
        <Route
          exact={true}
          path={`${websitePath}/event-builder`}
          render={() => (
            <TitleProvider page="Event Builder">
              <WebsiteLayout navigationData={navigationData}>
                <EventBuilder appEducationSlideshows={appEducationSlideshows} />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      <Route
        exact={true}
        path={`${websitePath}/pre-built-tags/:slug?`}
        render={() => (
          <TitleProvider page="Pre-Built Tags">
            <WebsiteLayout navigationData={navigationData}>
              <PreBuiltTags
                appEducationSlideshows={appEducationSlideshows}
                website={websiteDetails}
              />
            </WebsiteLayout>
          </TitleProvider>
        )}
      />
      {isOnboardingCompleted && isFullyManaged ? (
        <Route
          exact={false}
          path={`${websitePath}/channel-accuracy`}
          render={() => (
            <TitleProvider page="Channel Accuracy">
              <WebsiteLayout navigationData={navigationData}>
                <ChannelAccuracy
                  appEducationSlideshows={appEducationSlideshows}
                />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      {isOnboardingCompleted && isFullyManaged ? (
        <Route
          exact={true}
          path={`${websitePath}/attribution-feed`}
          render={() => (
            <TitleProvider page="Attribution Feed">
              <WebsiteLayout navigationData={navigationData}>
                <AttributionFeed
                  appEducationSlideshows={appEducationSlideshows}
                />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      {isOnboardingCompleted ? (
        <Route
          exact={true}
          path={`${websitePath}/cro-ideas/:slug?`}
          render={() => (
            <TitleProvider page="CRO Ideas">
              <WebsiteLayout navigationData={navigationData}>
                <CroIdeas appEducationSlideshows={appEducationSlideshows} />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      {isCompanyAdmin ? (
        <Route
          exact={true}
          path={`${websitePath}/settings/info`}
          render={() => (
            <TitleProvider page="Website Info">
              <WebsiteLayout navigationData={navigationData}>
                <WebsiteInfo />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      {isCompanyAdmin ? (
        <Route
          exact={true}
          path={`${websitePath}/settings/plan`}
          render={() => (
            <TitleProvider page="Plan & Usage">
              <WebsiteLayout navigationData={navigationData}>
                <PlanAndUsage />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      {isCompanyAdmin ? (
        <Route
          exact={true}
          path={`${websitePath}/settings/plan/manage`}
          render={() => (
            <TitleProvider page="Manage Plan">
              <ManagePlan websiteDetails={websiteDetails} />
            </TitleProvider>
          )}
        />
      ) : null}
      {isCompanyAdmin && !isOnDefaultFreePlan ? (
        <Route
          exact={true}
          path={`${websitePath}/settings/plan/cancel`}
          render={() => (
            <TitleProvider page="Cancel Plan">
              <WebsiteLayout navigationData={navigationData}>
                <CancelPlan websiteDetails={websiteDetails} />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      {isCompanyAdmin && areProductRoutesAvailable ? (
        <Route
          exact={true}
          path={`${websitePath}/settings/products`}
          render={() => (
            <TitleProvider page="Audits & Setups">
              <WebsiteLayout navigationData={navigationData}>
                <PurchasedProducts />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      {isCompanyAdmin && areProductRoutesAvailable ? (
        <Route
          exact={true}
          path={`${websitePath}/settings/products/explore`}
          render={() => (
            <TitleProvider page="Audits & Setups">
              <Products />
            </TitleProvider>
          )}
        />
      ) : null}
      {isCompanyAdmin && isConnectionsRouteAvailable ? (
        <Route
          exact={true}
          path={`${websitePath}/settings/connections`}
          render={() => (
            <TitleProvider page="Connections">
              <WebsiteLayout navigationData={navigationData}>
                <Connections />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      {isCompanyAdmin ? (
        <Route
          exact={true}
          path={`${websitePath}/settings/history`}
          render={() => (
            <TitleProvider page="History">
              <WebsiteLayout navigationData={navigationData}>
                <History />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      {isFullyManaged ? (
        <Route
          exact={true}
          path={`${websitePath}/support`}
          render={() => (
            <TitleProvider page="Support">
              <WebsiteLayout navigationData={navigationData}>
                <Support />
              </WebsiteLayout>
            </TitleProvider>
          )}
        />
      ) : null}
      <RedirectWithoutLastLocation
        to={!isOnboardingCompleted ? `${websiteUrl}/my-tracking` : websiteUrl}
      />
    </Switch>
  );
};

/* ========================================================================== */

type GlobalErrorModalProps = {
  websiteDetails: WebsiteDetails;
};

const GlobalErrorModal: React.FC<GlobalErrorModalProps> = ({
  websiteDetails
}) => {
  const websiteId = useWebsiteId();
  const { isFullyManaged } = useServerSideDetails();
  const { onboardingState } = useOnboardingDetails();
  const { globalError, globalErrorDispatch } = useGlobalError();
  const { goToAppStorePage, goToShopifyLogin } = useShopifyRedirects();

  const shopifyOAuthScopes = useShopifyOAuthScopesQuery();

  const hasProactiveLogicRun = useRef(false);

  const scopesData = shopifyOAuthScopes.data;
  const shopDetails = websiteDetails.shopify_shop;
  const hasAccessToken = shopDetails?.has_access_token;
  const usesCustomPixel = shopDetails?.uses_custom_pixel;

  useEffect(() => {
    if (
      !hasProactiveLogicRun.current &&
      onboardingState.name === "DONE" &&
      scopesData !== undefined
    ) {
      if (hasAccessToken === false) {
        globalErrorDispatch({
          type: "SET_PROACTIVE",
          payload: { websiteId, code: "SHOPIFY_LACKS_ACCESS" }
        });
      } else if (scopesData !== null && "scopes" in scopesData) {
        if (difference(requiredScopes, scopesData.scopes).length > 0) {
          globalErrorDispatch({
            type: "SET_PROACTIVE",
            payload: {
              websiteId,
              code:
                scopesData.scopes.length === 0
                  ? "SHOPIFY_LACKS_ACCESS"
                  : "SHOPIFY_LACKS_SCOPE"
            }
          });
        } else if (
          usesCustomPixel &&
          !scopesData.scopes.includes("read_customers")
        ) {
          globalErrorDispatch({
            type: "SET_PROACTIVE",
            payload: { websiteId, code: "SHOPIFY_LACKS_SCOPE" }
          });
        }
      }

      hasProactiveLogicRun.current = true;
    }
  }, [
    onboardingState.name,
    scopesData,
    hasAccessToken,
    globalErrorDispatch,
    websiteId,
    usesCustomPixel
  ]);

  const onClose = () => globalErrorDispatch({ type: "DISMISS_PROACTIVE" });

  return (
    <Modal
      isVisible={
        isFullyManaged &&
        (globalError.reactive?.websiteId === websiteId ||
          (globalError.proactive?.websiteId === websiteId &&
            !globalError.proactive.isDismissed))
      }
      onClose={onClose}
      disallowClose={globalError.reactive !== null}
    >
      {(() => {
        const error = globalError.reactive ?? globalError.proactive;

        if (error) {
          switch (error.code) {
            case "SHOPIFY_LACKS_ACCESS":
              return (
                <GlobalErrorModalContents>
                  <GlobalErrorModalTitle>
                    Reinstall Elevar App
                  </GlobalErrorModalTitle>
                  <GlobalErrorModalBody>
                    Oops! It looks like we are missing some permissions from
                    Shopify. Please reinstall the Elevar App to continue.
                  </GlobalErrorModalBody>
                  <GlobalErrorModalActionsWrapper>
                    {"isDismissed" in error ? (
                      <ButtonSecondary variant="SMALL" onClick={onClose}>
                        Dismiss
                      </ButtonSecondary>
                    ) : null}
                    <ButtonPrimary variant="SMALL" onClick={goToAppStorePage}>
                      Go to Shopify
                    </ButtonPrimary>
                  </GlobalErrorModalActionsWrapper>
                </GlobalErrorModalContents>
              );
            case "SHOPIFY_LACKS_SCOPE":
              return (
                <GlobalErrorModalContents>
                  <GlobalErrorModalTitle>
                    Approve Permissions
                  </GlobalErrorModalTitle>
                  <GlobalErrorModalBody>
                    Oops! It looks like we are missing some permissions from
                    Shopify. Please approve the permissions we request to
                    continue.
                  </GlobalErrorModalBody>
                  <GlobalErrorModalActionsWrapper>
                    {"isDismissed" in error ? (
                      <ButtonSecondary variant="SMALL" onClick={onClose}>
                        Dismiss
                      </ButtonSecondary>
                    ) : null}
                    <ButtonPrimary
                      variant="SMALL"
                      onClick={() => {
                        goToShopifyLogin(websiteDetails.shopify_shop);
                      }}
                    >
                      Go to Shopify
                    </ButtonPrimary>
                  </GlobalErrorModalActionsWrapper>
                </GlobalErrorModalContents>
              );
          }
        } else {
          return null;
        }
      })()}
    </Modal>
  );
};

const GlobalErrorModalContents = styled.div`
  width: ${props => props.theme.gridBase * 38}px;
`;

const GlobalErrorModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const GlobalErrorModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const GlobalErrorModalActionsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  column-gap: ${props => props.theme.gridBase}px;
`;
