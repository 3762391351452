type IconAppLovinProps = {
  size: "24px";
  className?: string;
};

export const IconAppLovin: React.FC<IconAppLovinProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.993 2.09515C10.4773 2.09515 9.24855 3.32388 9.24855 4.83959C9.24855 5.44564 9.44495 6.00583 9.77765 6.45988L9.74725 6.51373L3.7677 17.2769C3.45148 17.1497 3.10613 17.0798 2.74444 17.0798C1.22873 17.0798 0 18.3085 0 19.8242C0 21.3399 1.22873 22.5686 2.74444 22.5686C4.26015 22.5686 5.48885 21.3399 5.48885 19.8242C5.48885 19.1865 5.27135 18.5996 4.9065 18.1336C7.2548 17.5824 9.6631 17.3267 12.0754 17.3725C14.4349 17.3489 16.7886 17.6076 19.0862 18.143C18.7257 18.6074 18.5112 19.1907 18.5112 19.8242C18.5112 21.3399 19.7399 22.5686 21.2556 22.5686C22.7712 22.5686 24 21.3399 24 19.8242C24 18.3085 22.7712 17.0798 21.2556 17.0798C20.8796 17.0798 20.5213 17.1554 20.195 17.2922L14.207 6.50458L14.1932 6.48023C14.535 6.02258 14.7374 5.45473 14.7374 4.83959C14.7374 4.11172 14.4483 3.41366 13.9336 2.89898C13.4189 2.3843 12.7208 2.09515 11.993 2.09515ZM13.2977 7.25463C12.9096 7.46473 12.4653 7.58403 11.993 7.58403C11.5124 7.58403 11.0606 7.46048 10.6678 7.24348L10.6666 7.24558L5.32415 16.8511C7.5441 16.3894 9.8083 16.1747 12.0754 16.2107C14.2785 16.1938 16.4771 16.41 18.6346 16.8557L13.2977 7.25463ZM13.6122 4.83959C13.6122 5.4945 13.2177 6.08493 12.6126 6.33553C12.0076 6.58618 11.3111 6.44763 10.848 5.98453C10.3849 5.52146 10.2464 4.825 10.497 4.21994C10.7477 3.61488 11.3381 3.22037 11.993 3.22037C12.8862 3.22289 13.6096 3.94636 13.6122 4.83959ZM2.73837 21.4343C3.63144 21.4376 4.3586 20.7173 4.36366 19.8242L4.35451 19.8151C4.35452 18.9243 3.63513 18.2009 2.74444 18.1958C1.85136 18.1958 1.12692 18.9189 1.12524 19.812C1.12357 20.7051 1.8453 21.4309 2.73837 21.4343ZM21.8696 21.3206C22.4752 21.0702 22.8702 20.4795 22.8702 19.8242V19.8151C22.8677 18.9251 22.1455 18.205 21.2556 18.205C20.6003 18.2031 20.0084 18.5965 19.7563 19.2014C19.5043 19.8063 19.6417 20.5035 20.1044 20.9675C20.5672 21.4316 21.264 21.571 21.8696 21.3206Z"
            fill="black"
          />
        </svg>
      );
  }
};
