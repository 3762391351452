import { produce } from "immer";
import { uniq } from "lodash-es";
import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { css, useTheme } from "styled-components";

import {
  type EventsConnectorConfig,
  type PinterestActionSource
} from "elevar-common-ts/src/apiTypes";
import { userFacingPredefinedChannels } from "elevar-common-ts/src/channels";

import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconChevronDown,
  IconChevronUp,
  IconCircledPlus,
  IconCog,
  IconCross
} from "elevar-design-system/src/icons";
import {
  InputFieldSelect,
  type Option
} from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import {
  type ContainerInfo,
  useContainerInfoQuery,
  useMyTrackingVersionMutation
} from "../../api/handlers/website";
import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { ConsentMode } from "./ConsentMode";
import { destinationPin as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { InputFieldChannelCode } from "./InputFieldChannelCode";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { containsNumbersOnly, conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";
import { TransactionIdentifier } from "./TransactionIdentifier";
import { WebContainerSetup } from "./WebContainerSetup";

/* ========================================================================== */

type DestinationPinProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationPin: React.FC<DestinationPinProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes
}) => {
  const containerInfo = useContainerInfoQuery({ destination });

  if (containerInfo.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (containerInfo.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <DestinationPinInner
      isCompanyAdmin={isCompanyAdmin}
      eventsConnectorConfig={eventsConnectorConfig}
      shopifyOAuthScopes={shopifyOAuthScopes}
      containerInfo={containerInfo.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-pinterest-in-elevar-server-side-destination";

type SetupFlowContext = {
  shopifyOAuthScopes: ShopifyOAuthScopes;
  containerInfo: ContainerInfo;
};

const {
  SetupFlow,
  useConfigRequired,
  useSetupFlowDetails,
  useSetupFlowContext
} = createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationPinInnerProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
  containerInfo: ContainerInfo;
};

const DestinationPinInner: React.FC<DestinationPinInnerProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes,
  containerInfo
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      eventsConnectorConfig={eventsConnectorConfig}
      steps={[
        { details: { type: "SETTINGS" } },
        { details: { type: "EVENTS" } },
        { details: { type: "CONSENT_MODE" } },
        { details: { type: "TRANSACTION_IDENTIFIER" } },
        { details: { type: "PRODUCT_IDENTIFIER" } },
        { details: { type: "FILTER_TRANSACTIONS" } },
        { details: { type: "SUBSCRIPTIONS" } },
        { details: { type: "WEB_CONTAINER_SETUP" } }
      ]}
      context={{ shopifyOAuthScopes, containerInfo }}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
    case 7:
      return <Step7 />;
    case 8:
      return <Step8 />;
  }
};

/* ========================================================================== */

type Events = ReturnType<typeof useConfigRequired>["enabledEvents"];
type WebEvents = ReturnType<typeof useConfigRequired>["enabledWebEvents"];

const recommendedEvents: Events = {
  addToCart: true,
  pageView: true,
  purchase: true,
  signUp: true,
  subscribe: true,
  viewItem: true,
  viewItemList: true,
  viewSearchResults: true
};

const recommendedWebEvents: WebEvents = {
  addToCart: true,
  pageView: true,
  purchase: true,
  signUp: true,
  subscribe: true,
  viewItem: true,
  viewItemList: true,
  viewSearchResults: true
};

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              The Pinterest Tag ID used is <span>{config.tagId}</span>
            </>
          )
        },
        {
          step: 2,
          type: "EVENTS",
          selectedEvents: {
            enabledEvents: config.enabledEvents,
            enabledWebEvents: config.enabledWebEvents,
            webhookOverrides: config.webhookOverrides
          },
          recommendedEvents: getRecommendedEventState({
            destination,
            enabledEvents: recommendedEvents,
            enabledWebEvents: recommendedWebEvents,
            webhookOverrides: config.webhookOverrides
          })
        },
        {
          step: 3,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 4,
          type: "TRANSACTION_IDENTIFIER",
          value: config.dataConfig.orderAttributeId
        },
        {
          step: 5,
          type: "PRODUCT_IDENTIFIER",
          value: config.dataConfig.productAttributeMapping
        },
        {
          step: 6,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        },
        {
          step: 7,
          type: "SUBSCRIPTIONS",
          filters: config.orderFilters,
          tagName: config.subscriptionTagName
        }
      ]}
      description={
        <Step0Explainer>
          Send meaningful data to {destination.name} so that you can focus on
          making strategic business decisions rather than worrying about your
          data.
        </Step0Explainer>
      }
      integrationNotes={{
        type: "ACTION_REQUIRED",
        content: (
          <div>
            <p>
              Pinterest has a limit of 5000 requests per minute. If your store
              has more requests, we recommend disabling the pageview and other
              events (see the “Events” step).
            </p>
            <p>
              The Pinterest Sales Channel does not allow you to separate use of
              the Catalog vs the Pixel in the app. You can either use another
              software for your Pinterest Feed Management like{" "}
              <LinkExternal href="https://help.pinterest.com/en/business/article/upload-your-products-with-a-third-party">
                GoDataFeed
              </LinkExternal>{" "}
              or continue to use the native app for your Pinterest Pixel
              tracking.
            </p>
          </div>
        )
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

type ChannelActionSourceMapItemEditable = {
  channel: string;
  actionSource: PinterestActionSource | null;
};

type GatewayActionSourceMapItemEditable = {
  gateway: string;
  actionSource: PinterestActionSource | null;
};

type TagActionSourceMapItemEditable = {
  tag: string;
  actionSource: PinterestActionSource | null;
};

const pinterestActionSourceOptions: Array<Option<PinterestActionSource>> = [
  { name: "App (Android)", value: "app_android" },
  { name: "App (iOS)", value: "app_ios" },
  { name: "Web", value: "web" },
  { name: "Offline", value: "offline" }
];

const Step1: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const {
    channelMap: initialChannelActionSourceMap,
    gatewayMap: initialGatewayActionSourceMap,
    tagMap: initialTagActionSourceMap
  } = config.actionSourceMaps;

  const [tagId, setTagId] = useState(config.tagId);
  const [adAccountId, setAdAccountId] = useState(config.adAccountId);
  const [accessToken, setAccessToken] = useState(config.accessToken);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );
  const [channelActionSourceMap, setChannelActionSourceMap] = useState<
    Array<ChannelActionSourceMapItemEditable>
  >(initialChannelActionSourceMap);
  const [gatewayActionSourceMap, setGatewayActionSourceMap] = useState<
    Array<GatewayActionSourceMapItemEditable>
  >(initialGatewayActionSourceMap);
  const [tagActionSourceMap, setTagActionSourceMap] = useState<
    Array<TagActionSourceMapItemEditable>
  >(initialTagActionSourceMap);

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const arePinterestFieldsFilledIn =
    tagId !== "" && adAccountId !== "" && accessToken !== "";
  const arePinterestFieldsUnique =
    uniq([tagId, adAccountId, accessToken]).length === 3;

  const isTagIdValid = containsNumbersOnly(tagId);
  const isAdAccountIdValid = containsNumbersOnly(adAccountId);
  const isAccessTokenValid =
    accessToken.startsWith("pina_") && accessToken.length > 5;

  const areChannelActionSourceMapItemsFilledIn = channelActionSourceMap.every(
    i => i.channel !== "" && i.actionSource !== null
  );
  const areChannelActionSourceMapItemsUnique =
    uniq(channelActionSourceMap.map(i => i.channel)).length ===
    channelActionSourceMap.length;

  const areGatewayActionSourceMapItemsFilledIn = gatewayActionSourceMap.every(
    i => i.gateway !== "" && i.actionSource !== null
  );
  const areGatewayActionSourceMapItemsUnique =
    uniq(gatewayActionSourceMap.map(i => i.gateway)).length ===
    gatewayActionSourceMap.length;

  const areTagActionSourceMapItemsFilledIn = tagActionSourceMap.every(
    i => i.tag !== "" && i.actionSource !== null
  );
  const areTagActionSourceMapItemsUnique =
    uniq(tagActionSourceMap.map(i => i.tag)).length ===
    tagActionSourceMap.length;

  const pinterestVersionOptions: Array<Option> = [{ name: "5", value: "5" }];
  const selectedPinterestVersionOption = pinterestVersionOptions[0]!;

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="Pinterest Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your Pinterest account.
            </Step1Explainer>
          }
        >
          <Step1SectionInnerWrapper ref={inputWrapperRef}>
            <div>
              <InputWrapper
                labelText="Pinterest Tag ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 35}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      <p>
                        This is the same pixel ID used and deduplicated with
                        your primary pixel events setup via GTM.
                      </p>
                      <StyledLinkExternal
                        href="https://docs.getelevar.com/docs/how-to-find-pinterest-tag-id"
                        text="How do I find this?"
                      />
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={tagId}
                  onChange={event => setTagId(event.target.value)}
                  placeholder="1234567890"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Pinterest Ad Account ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 36}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      <p>
                        This is the Pinterest Ad Account that you want to send
                        server-side data to.
                      </p>
                      <StyledLinkExternal
                        href="https://docs.getelevar.com/docs/how-to-find-pinterest-ad-account-id"
                        text="How do I find this?"
                      />
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={adAccountId}
                  onChange={event => setAdAccountId(event.target.value)}
                  placeholder="1234567890"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Conversion Access Token"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 30}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      <p>
                        This is an authentication token generated in Ads
                        Manager.
                      </p>
                      <StyledLinkExternal
                        href="https://docs.getelevar.com/docs/how-to-generate-pinterest-access-token"
                        text="How do I generate this?"
                      />
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldTextArea
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={accessToken}
                  onChange={event => setAccessToken(event.target.value)}
                  placeholder="Token"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Pinterest API Version"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 25}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      <p>Elevar sets this to the latest version by default.</p>
                      <StyledLinkExternal
                        href="https://developers.pinterest.com/docs/new/changelog/"
                        text="Learn More"
                      />
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldSelect
                  variant="SMALL"
                  disabled={true}
                  value={selectedPinterestVersionOption}
                  setValue={() => null}
                  options={pinterestVersionOptions}
                  placeholder="Select a Version"
                />
              </InputWrapper>
            </div>
          </Step1SectionInnerWrapper>
        </StepSection>
        <Step1AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step1AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step1AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
            <div>
              <InputWrapper
                labelText="Conversion Value"
                disabled={setupFlow.isLoading}
              >
                <InputFieldSelect
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={selectedConversionValueOption}
                  setValue={option => setConversionValue(option.value)}
                  options={conversionValueOptions}
                  placeholder="Select a Conversion Value"
                />
              </InputWrapper>
            </div>
            <div>
              <div>
                Here you can further customize how to send data to Pinterest.
              </div>
              <div>
                <Step1AdvancedOptionWrapper
                  showBottomSeparator={
                    channelActionSourceMap.length > 0 ||
                    gatewayActionSourceMap.length > 0
                  }
                >
                  {channelActionSourceMap.length === 0 ? (
                    <Step1IconTextButton
                      disabled={setupFlow.isLoading}
                      onClick={() => {
                        setChannelActionSourceMap([
                          { channel: "", actionSource: null }
                        ]);
                      }}
                    >
                      <div>
                        <IconCog size="24px" />
                      </div>
                      <div>Customize by Channel</div>
                    </Step1IconTextButton>
                  ) : (
                    <Step1MapItemsExistWrapper>
                      <div>
                        <div>Customize by Channel</div>
                        <div>
                          Note, a channel code is required. Please follow the
                          Setup Guide for details on where to find this.
                        </div>
                      </div>
                      <div>
                        <div>
                          {channelActionSourceMap.map((item, index) => (
                            <Step1MapItemWrapper key={index}>
                              <div>
                                <InputWrapper
                                  labelText="Channel Code"
                                  tooltip={{
                                    text: 'Examples: "Point of Sale", "Wholesale", "Global-E"'
                                  }}
                                  disabled={setupFlow.isLoading}
                                >
                                  <InputFieldChannelCode
                                    value={item.channel}
                                    setValue={value => {
                                      setChannelActionSourceMap(
                                        produce(
                                          channelActionSourceMap,
                                          draft => {
                                            draft[index]!.channel = value;
                                          }
                                        )
                                      );
                                    }}
                                    options={userFacingPredefinedChannels.map(
                                      c => ({ name: c.name, value: c.code })
                                    )}
                                    placeholder="Code"
                                    disabled={setupFlow.isLoading}
                                  />
                                </InputWrapper>
                                <InputWrapper
                                  labelText="Action Source"
                                  tooltip={{
                                    maxWidth: `${theme.gridBase * 36}px`,
                                    render: () => (
                                      <Step1InputWrapperTooltipContent>
                                        Learn more about the "action_source"
                                        setting from Pinterest{" "}
                                        <LinkExternal href="https://developers.pinterest.com/docs/conversions/conversions/#Required,%20recommended,%20and%20optional%20fields">
                                          here
                                        </LinkExternal>
                                        .
                                      </Step1InputWrapperTooltipContent>
                                    )
                                  }}
                                  disabled={setupFlow.isLoading}
                                >
                                  <InputFieldSelect
                                    variant="SMALL"
                                    disabled={setupFlow.isLoading}
                                    value={
                                      pinterestActionSourceOptions.find(
                                        o => o.value === item.actionSource
                                      ) ?? null
                                    }
                                    setValue={({ value }) => {
                                      setChannelActionSourceMap(
                                        produce(
                                          channelActionSourceMap,
                                          draft => {
                                            draft[index]!.actionSource = value;
                                          }
                                        )
                                      );
                                    }}
                                    options={pinterestActionSourceOptions}
                                    maxItemsInView={3}
                                    placeholder="Source"
                                  />
                                </InputWrapper>
                              </div>
                              <div>
                                <Step1MapItemRemoveButton
                                  aria-label={`Remove: ${item.channel}`}
                                  disabled={setupFlow.isLoading}
                                  onClick={() => {
                                    setChannelActionSourceMap(
                                      produce(channelActionSourceMap, draft => {
                                        draft.splice(index, 1);
                                      })
                                    );
                                  }}
                                >
                                  <IconCross size="16px" />
                                </Step1MapItemRemoveButton>
                              </div>
                            </Step1MapItemWrapper>
                          ))}
                        </div>
                        <Step1IconTextButton
                          disabled={setupFlow.isLoading}
                          onClick={() => {
                            setChannelActionSourceMap([
                              ...channelActionSourceMap,
                              { channel: "", actionSource: null }
                            ]);
                          }}
                        >
                          <div>
                            <IconCircledPlus size="24px" />
                          </div>
                          <div>Add Another</div>
                        </Step1IconTextButton>
                      </div>
                    </Step1MapItemsExistWrapper>
                  )}
                </Step1AdvancedOptionWrapper>
                <Step1AdvancedOptionWrapper
                  showBottomSeparator={
                    gatewayActionSourceMap.length > 0 ||
                    tagActionSourceMap.length > 0
                  }
                >
                  {gatewayActionSourceMap.length === 0 ? (
                    <Step1IconTextButton
                      disabled={setupFlow.isLoading}
                      onClick={() => {
                        setGatewayActionSourceMap([
                          { gateway: "", actionSource: null }
                        ]);
                      }}
                    >
                      <div>
                        <IconCog size="24px" />
                      </div>
                      <div>Customize by Order Gateway</div>
                    </Step1IconTextButton>
                  ) : (
                    <Step1MapItemsExistWrapper>
                      <div>
                        <div>Customize by Order Gateway</div>
                        <div>
                          Please follow the Setup Guide for details on where to
                          find this.
                        </div>
                      </div>
                      <div>
                        <div>
                          {gatewayActionSourceMap.map((item, index) => (
                            <Step1MapItemWrapper key={index}>
                              <div>
                                <InputWrapper
                                  labelText="Order Gateway"
                                  tooltip={{
                                    text: 'Examples: "Money Order", "Paypal"'
                                  }}
                                  disabled={setupFlow.isLoading}
                                >
                                  <InputFieldText
                                    variant="SMALL"
                                    disabled={setupFlow.isLoading}
                                    value={item.gateway}
                                    onChange={event => {
                                      const value = event.target.value;
                                      setGatewayActionSourceMap(
                                        produce(
                                          gatewayActionSourceMap,
                                          draft => {
                                            draft[index]!.gateway = value;
                                          }
                                        )
                                      );
                                    }}
                                    placeholder="Gateway"
                                    spellCheck={false}
                                    autoCapitalize="off"
                                  />
                                </InputWrapper>
                                <InputWrapper
                                  labelText="Action Source"
                                  tooltip={{
                                    maxWidth: `${theme.gridBase * 36}px`,
                                    render: () => (
                                      <Step1InputWrapperTooltipContent>
                                        Learn more about the "action_source"
                                        setting from Pinterest{" "}
                                        <LinkExternal href="https://developers.pinterest.com/docs/conversions/conversions/#Required,%20recommended,%20and%20optional%20fields">
                                          here
                                        </LinkExternal>
                                        .
                                      </Step1InputWrapperTooltipContent>
                                    )
                                  }}
                                  disabled={setupFlow.isLoading}
                                >
                                  <InputFieldSelect
                                    variant="SMALL"
                                    disabled={setupFlow.isLoading}
                                    value={
                                      pinterestActionSourceOptions.find(
                                        o => o.value === item.actionSource
                                      ) ?? null
                                    }
                                    setValue={({ value }) => {
                                      setGatewayActionSourceMap(
                                        produce(
                                          gatewayActionSourceMap,
                                          draft => {
                                            draft[index]!.actionSource = value;
                                          }
                                        )
                                      );
                                    }}
                                    options={pinterestActionSourceOptions}
                                    maxItemsInView={3}
                                    placeholder="Source"
                                  />
                                </InputWrapper>
                              </div>
                              <div>
                                <Step1MapItemRemoveButton
                                  aria-label={`Remove: ${item.gateway}`}
                                  disabled={setupFlow.isLoading}
                                  onClick={() => {
                                    setGatewayActionSourceMap(
                                      produce(gatewayActionSourceMap, draft => {
                                        draft.splice(index, 1);
                                      })
                                    );
                                  }}
                                >
                                  <IconCross size="16px" />
                                </Step1MapItemRemoveButton>
                              </div>
                            </Step1MapItemWrapper>
                          ))}
                        </div>
                        <Step1IconTextButton
                          disabled={setupFlow.isLoading}
                          onClick={() => {
                            setGatewayActionSourceMap([
                              ...gatewayActionSourceMap,
                              { gateway: "", actionSource: null }
                            ]);
                          }}
                        >
                          <div>
                            <IconCircledPlus size="24px" />
                          </div>
                          <div>Add Another</div>
                        </Step1IconTextButton>
                      </div>
                    </Step1MapItemsExistWrapper>
                  )}
                </Step1AdvancedOptionWrapper>
                <Step1AdvancedOptionWrapper showBottomSeparator={false}>
                  {tagActionSourceMap.length === 0 ? (
                    <Step1IconTextButton
                      disabled={setupFlow.isLoading}
                      onClick={() => {
                        setTagActionSourceMap([
                          { tag: "", actionSource: null }
                        ]);
                      }}
                    >
                      <div>
                        <IconCog size="24px" />
                      </div>
                      <div>Customize by Tag</div>
                    </Step1IconTextButton>
                  ) : (
                    <Step1MapItemsExistWrapper>
                      <div>
                        <div>Customize by Tag</div>
                        <div>
                          Please follow the Setup Guide for details on where to
                          find this.
                        </div>
                      </div>
                      <div>
                        <div>
                          {tagActionSourceMap.map((item, index) => (
                            <Step1MapItemWrapper key={index}>
                              <div>
                                <InputWrapper
                                  labelText="Tag Name"
                                  tooltip={{
                                    text: 'Examples: "Subscription", "Customer Service"'
                                  }}
                                  disabled={setupFlow.isLoading}
                                >
                                  <InputFieldText
                                    variant="SMALL"
                                    disabled={setupFlow.isLoading}
                                    value={item.tag}
                                    onChange={event => {
                                      const value = event.target.value;
                                      setTagActionSourceMap(
                                        produce(tagActionSourceMap, draft => {
                                          draft[index]!.tag = value;
                                        })
                                      );
                                    }}
                                    placeholder="Tag"
                                    spellCheck={false}
                                    autoCapitalize="off"
                                  />
                                </InputWrapper>
                                <InputWrapper
                                  labelText="Action Source"
                                  tooltip={{
                                    maxWidth: `${theme.gridBase * 36}px`,
                                    render: () => (
                                      <Step1InputWrapperTooltipContent>
                                        Learn more about the "action_source"
                                        setting from Pinterest{" "}
                                        <LinkExternal href="https://developers.pinterest.com/docs/conversions/conversions/#Required,%20recommended,%20and%20optional%20fields">
                                          here
                                        </LinkExternal>
                                        .
                                      </Step1InputWrapperTooltipContent>
                                    )
                                  }}
                                  disabled={setupFlow.isLoading}
                                >
                                  <InputFieldSelect
                                    variant="SMALL"
                                    disabled={setupFlow.isLoading}
                                    value={
                                      pinterestActionSourceOptions.find(
                                        o => o.value === item.actionSource
                                      ) ?? null
                                    }
                                    setValue={({ value }) => {
                                      setTagActionSourceMap(
                                        produce(tagActionSourceMap, draft => {
                                          draft[index]!.actionSource = value;
                                        })
                                      );
                                    }}
                                    options={pinterestActionSourceOptions}
                                    maxItemsInView={3}
                                    placeholder="Source"
                                  />
                                </InputWrapper>
                              </div>
                              <div>
                                <Step1MapItemRemoveButton
                                  aria-label={`Remove: ${item.tag}`}
                                  disabled={setupFlow.isLoading}
                                  onClick={() => {
                                    setTagActionSourceMap(
                                      produce(tagActionSourceMap, draft => {
                                        draft.splice(index, 1);
                                      })
                                    );
                                  }}
                                >
                                  <IconCross size="16px" />
                                </Step1MapItemRemoveButton>
                              </div>
                            </Step1MapItemWrapper>
                          ))}
                        </div>
                        <Step1IconTextButton
                          disabled={setupFlow.isLoading}
                          onClick={() => {
                            setTagActionSourceMap([
                              ...tagActionSourceMap,
                              { tag: "", actionSource: null }
                            ]);
                          }}
                        >
                          <div>
                            <IconCircledPlus size="24px" />
                          </div>
                          <div>Add Another</div>
                        </Step1IconTextButton>
                      </div>
                    </Step1MapItemsExistWrapper>
                  )}
                </Step1AdvancedOptionWrapper>
              </div>
            </div>
          </Step1AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={setupFlow.isLoading}
          isStepCompleted={setupFlow.isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !arePinterestFieldsFilledIn
              ? "Please fill out all fields"
              : !isTagIdValid
                ? "The Pinterest Tag ID field must only contain numbers"
                : !isAdAccountIdValid
                  ? "The Pinterest Ad Account ID field must only contain numbers"
                  : !isAccessTokenValid
                    ? "The Conversion Access Token field must start with 'pina_'"
                    : !areChannelActionSourceMapItemsFilledIn
                      ? "Fill in all channel action source items"
                      : !areChannelActionSourceMapItemsUnique
                        ? "Provide unique channel codes for all channel action source items"
                        : !areGatewayActionSourceMapItemsFilledIn
                          ? "Fill in all order gateway action source items"
                          : !areGatewayActionSourceMapItemsUnique
                            ? "Provide unique gateways names for all gateway action source items"
                            : !areTagActionSourceMapItemsFilledIn
                              ? "Fill in all tag action source items"
                              : !areTagActionSourceMapItemsUnique
                                ? "Provide unique tag names for all tag action source items"
                                : !arePinterestFieldsUnique
                                  ? "Please ensure all values are unique"
                                  : null
          }
          onSave={async data => {
            type C = ReturnType<typeof useConfigRequired>;
            type ChannelMap = C["actionSourceMaps"]["channelMap"];
            type GatewayMap = C["actionSourceMaps"]["gatewayMap"];
            type TagMap = C["actionSourceMaps"]["tagMap"];

            await setupFlow.configMutation({
              ...data,
              tagId,
              adAccountId,
              accessToken,
              dataConfig: { conversionValue },
              actionSourceMaps: {
                channelMap: channelActionSourceMap as ChannelMap,
                gatewayMap: gatewayActionSourceMap as GatewayMap,
                tagMap: tagActionSourceMap as TagMap
              }
            });
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1SectionInnerWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }

  > a {
    ${linkStyles};
  }
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step1AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step1AdvancedOptionsWrapper = styled.div<Step1AdvancedOptionsWrapperProps>`
  padding-top: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    width: ${props => props.inputWrapperWidth}px;
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }

  > div:last-child {
    > div:first-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
      margin-bottom: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

type Step1AdvancedOptionWrapperProps = {
  showBottomSeparator: boolean;
};

const Step1AdvancedOptionWrapper = styled.div<Step1AdvancedOptionWrapperProps>`
  ${props =>
    props.showBottomSeparator
      ? css`
          margin-bottom: ${props => props.theme.gridBase * 3}px;
          border-bottom: 1px solid ${props => props.theme.palette.grey6};
          padding-bottom: ${props => props.theme.gridBase * 3}px;
        `
      : css`
          margin-bottom: ${props => props.theme.gridBase * 1.5}px;
        `}
`;

const Step1IconTextButton = styled.button`
  ${iconTextButtonStyles};
`;

const Step1MapItemsExistWrapper = styled.div`
  > div:first-child {
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
    }
  }
`;

const Step1MapItemWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    flex: 1;
    max-width: ${props => props.theme.gridBase * 90}px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props => props.theme.gridBase}px;

    @media screen and (max-width: 1450px) {
      width: 100%;
      grid-template-columns: 1fr;
      gap: ${props => props.theme.gridBase * 1.5}px;
      justify-items: stretch;
    }
  }

  > div:last-child {
    margin-left: ${props => props.theme.gridBase}px;
  }

  &:not(:last-child) {
    @media screen and (max-width: 1450px) {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
      border-bottom: 1px solid ${props => props.theme.palette.grey6};
      padding-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const Step1MapItemRemoveButton = styled.button`
  ${iconButtonStyles};
  color: ${props => props.theme.palette.grey3};
  margin-top: ${props => props.theme.gridBase * 4.5}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { shopifyOAuthScopes } = useSetupFlowContext();

  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: config.enabledWebEvents,
    webhookOverrides: config.webhookOverrides
  });

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to Pinterest.
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={setupFlow.isLoading}
        isStepCompleted={setupFlow.isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        details={{
          eventState,
          setEventState,
          destinationName: destination.name
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: recommendedWebEvents
        }}
        onSave={options => setupFlow.configMutation(eventState, options)}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  return (
    <TransactionIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["id", "name"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { orderAttributeId }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  return (
    <ProductIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      hasRecommendedOption={false}
      options={["product_id", "sku", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { productAttributeMapping }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={(data, options) => setupFlow.configMutation(data, options)}
    />
  );
};

/* ========================================================================== */

const Step7: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <Subscriptions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};

/* ========================================================================== */

const Step8: React.FC = () => {
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { containerInfo } = useSetupFlowContext();

  const { mutateAsync: myTrackingVersionMutation } =
    useMyTrackingVersionMutation();

  return (
    <WebContainerSetup
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      containerUrl={containerInfo.web_container_url ?? ""}
      eventsConnectorConfig={eventsConnectorConfig}
      details={{ destination, config }}
      onSave={async () => {
        setupFlow.setIsLoading(true);
        await myTrackingVersionMutation("destination-pin");
        await setupFlow.configMutation({});
        setupFlow.setIsLoading(false);
      }}
    />
  );
};
