import { type Filter, type Query, type ResultSet } from "@cubejs-client/core";
import { capitalize, groupBy, omit, partition, sortBy, uniq } from "lodash-es";
import { useEffect, useMemo, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";
import {
  type MonitoringChannel,
  type MonitoringEvent
} from "elevar-common-ts/src/monitoringTypes";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconHelp,
  IconServer,
  IconShopify
} from "elevar-design-system/src/icons";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { scrollbarHoriztonalMixin } from "elevar-design-system/src/scrollbar";
import { Spinner } from "elevar-design-system/src/Spinner";
import { TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  useConfigurableCubeQuery,
  useCubeQuery
} from "../../../api/handlers/cube";
import {
  type ShopifyMarkets,
  useShopifyMarketsQuery
} from "../../../api/handlers/shopify";
import { useEventsConnectorConfigQuery } from "../../../api/handlers/website";
import { NoneExplainer } from "../../../components/NoneExplainer";
import { PageCard } from "../../../components/PageCard";
import { type TableRow } from "../../../components/Table";
import {
  type CubeDateRange,
  getCubeDateRangeFromMonitoringTimePeriod,
  useMonitoringTimePeriod
} from "../../../context/MonitoringTimePeriod";
import { TimezoneNotice } from "../../../context/Timezone";
import { formatEventName, formatTitle } from "../../../utils/format";
import { useCompanyId, useWebsiteId } from "../../../utils/idHooks";
import { type Destination, destinations } from "../../myTracking/data";
import { CubeTable } from "../CubeTable";
import { DimensionCube } from "../DimensionCube";

/* ========================================================================== */

export const ServerEventsLog: React.FC = () => {
  const eventsConnectorConfig = useEventsConnectorConfigQuery();
  const shopifyMarkets = useShopifyMarketsQuery();

  if (eventsConnectorConfig.error !== null || shopifyMarkets.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    eventsConnectorConfig.data === undefined ||
    shopifyMarkets.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <ServerEventsLogInner1
      eventsConnectorConfig={eventsConnectorConfig.data}
      shopifyMarkets={shopifyMarkets.data}
    />
  );
};

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.theme.gridBase * 22}px;
`;

/* ========================================================================== */

type ValidInfoItem = {
  destination: Destination;
  configs: EventsConnectorConfig[Destination["configKey"]];
};

type SummaryQueryData = {
  "ServerSideEvents.channel": MonitoringChannel;
  "ServerSideEvents.config_id": number;
  "ServerSideEvents.event": MonitoringEvent;
  "ServerSideEvents.success_count": number;
};

type ServerEventsLogInner1Props = {
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyMarkets: ShopifyMarkets;
};

const ServerEventsLogInner1: React.FC<ServerEventsLogInner1Props> = ({
  eventsConnectorConfig,
  shopifyMarkets
}) => {
  const theme = useTheme();
  const { monitoringTimePeriod } = useMonitoringTimePeriod();

  const cubeDateRange =
    getCubeDateRangeFromMonitoringTimePeriod(monitoringTimePeriod);

  const validInfo = useMemo(() => {
    return destinations
      .map<ValidInfoItem | null>(destination => {
        const configs = eventsConnectorConfig[destination.configKey].filter(
          config => config.live
        ) as EventsConnectorConfig[Destination["configKey"]];

        return configs.length > 0 ? { destination, configs } : null;
      })
      .filter(v => v !== null);
  }, [eventsConnectorConfig]);

  const summaryQueryResult = useCubeQuery<SummaryQueryData>({
    query: {
      measures: ["ServerSideEvents.success_count"],
      dimensions: [
        "ServerSideEvents.channel",
        "ServerSideEvents.config_id",
        "ServerSideEvents.event"
      ],
      timeDimensions: [
        { dimension: "ServerSideEvents.time", dateRange: cubeDateRange }
      ],
      filters: [
        {
          or: [
            {
              member: "ServerSideEvents.channel",
              operator: "equals",
              values: ["Shopify"]
            },
            ...validInfo.map<Filter>(({ destination, configs }) => ({
              and: [
                {
                  member: "ServerSideEvents.channel",
                  operator: "equals",
                  values: [destination.name]
                },
                {
                  member: "ServerSideEvents.config_id",
                  operator: "equals",
                  values: configs.map(c => String(c.id))
                }
              ]
            }))
          ]
        }
      ]
    }
  });

  return (
    <>
      <RouteHeader>
        <RouteHeading>
          <div>Server Events Log</div>
          <div>
            <TooltipBig
              placement="right"
              maxWidth={`${theme.gridBase * 36.5}px`}
              render={() => (
                <RouteHeadingTooltipContent>
                  The "Server Events Log" only includes data from our
                  server-side integrations. Learn more about this{" "}
                  <LinkExternal href="https://docs.getelevar.com/docs/server-events-log">
                    here
                  </LinkExternal>
                  .
                </RouteHeadingTooltipContent>
              )}
            >
              <RouteHeadingTooltipTarget>
                <IconHelp size="16px" />
              </RouteHeadingTooltipTarget>
            </TooltipBig>
          </div>
        </RouteHeading>
        <TimezoneNotice />
      </RouteHeader>
      {summaryQueryResult.error ? (
        <LoadingOrErrorWrapper>
          <ErrorOccurred />
        </LoadingOrErrorWrapper>
      ) : summaryQueryResult.data === undefined ? (
        <LoadingOrErrorWrapper>
          <Spinner size="24px" />
        </LoadingOrErrorWrapper>
      ) : summaryQueryResult.data.resultSet.rawData().length === 0 ? (
        <NoneExplainer details={{ type: "EVENTS", monitoringTimePeriod }} />
      ) : (
        <ServerEventsLogInner2
          eventsConnectorConfig={eventsConnectorConfig}
          shopifyMarkets={shopifyMarkets}
          resultSet={summaryQueryResult.data.resultSet}
          validInfo={validInfo}
          cubeDateRange={cubeDateRange}
        />
      )}
    </>
  );
};

const RouteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => props.theme.gridBase * 4}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const RouteHeading = styled.h1`
  display: flex;

  > div:first-child {
    ${heading3Styles};
    margin-right: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const RouteHeadingTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const RouteHeadingTooltipTarget = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey3};
  padding: ${props => props.theme.gridBase * 0.5}px;
`;

const LoadingOrErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.theme.gridBase * 15}px;
`;

/* ========================================================================== */

const eventOrder: Array<MonitoringEvent> = [
  "PageView",
  "ViewItemList",
  "ClickContent",
  "ViewContent",
  "AddToCart",
  "ViewCart",
  "InitiateCheckout",
  "AddShippingInfo",
  "AddPaymentInfo",
  "Purchase",
  "Subscribe",
  "SubscriptionPurchase",
  "NewCustomerPurchase",
  "ReturningCustomerPurchase",
  "ChannelLiftStudy",
  "Search",
  "RemoveFromCart",
  "CompletePayment",
  "CompleteRegistration",
  "Login",
  "SignUp",
  "Refund"
];

const channelOrder: Array<MonitoringChannel> = [
  "Shopify",
  ...destinations.map(d => d.name),
  "Unknown"
];

type MappedPoint = { event: MonitoringEvent; successCount: number | null };

type TransformedData = {
  events: Array<MonitoringEvent>;
  shopify: { points: Array<MappedPoint> };
  channels: Array<{
    config: EventsConnectorConfig[Destination["configKey"]][number];
    destination: Destination;
    points: Array<MappedPoint>;
    title: string;
  }>;
};

const transformSummaryResult = (
  resultSet: ResultSet<SummaryQueryData>,
  validInfo: Array<ValidInfoItem>
): TransformedData => {
  const rawData = resultSet.rawData();

  const [shopifyEntries, channelsEntries] = partition(
    Object.entries(groupBy(rawData, i => i["ServerSideEvents.channel"])),
    ([channel]) => channel === "Shopify"
  );

  const entries = [...shopifyEntries, ...channelsEntries];

  const events = sortBy(
    uniq(entries.flatMap(([_, d]) => d.map(i => i["ServerSideEvents.event"]))),
    eventOrder.map<(event: MonitoringEvent) => boolean>(e1 => e2 => e2 === e1)
  ).reverse();

  const parsePoints = (points: Array<SummaryQueryData>) => {
    return events.map<MappedPoint>(event => {
      const point = points.find(p => p["ServerSideEvents.event"] === event);
      return {
        event,
        successCount: point?.["ServerSideEvents.success_count"] ?? null
      };
    });
  };

  const shopify = { points: parsePoints(shopifyEntries[0]?.[1] ?? []) };

  const channelData = channelsEntries.flatMap(([name, points]) => {
    const info = validInfo.find(i => i.destination.name === name)!;

    const configEntries = Object.entries(
      groupBy(points, point => point["ServerSideEvents.config_id"])
    );

    return configEntries.map(([configId, points]) => {
      const config = info.configs.find(c => c.id === Number(configId))!;
      return {
        config,
        destination: info.destination,
        points: parsePoints(points),
        title: formatTitle(info.destination.name, config.label)
      };
    });
  });

  const channels = validInfo.flatMap(info =>
    info.configs.map<(typeof channelData)[number]>(
      config =>
        channelData.find(channel => channel.config === config) ?? {
          config,
          destination: info.destination,
          points: parsePoints([]),
          title: formatTitle(info.destination.name, config.label)
        }
    )
  );

  return { events, shopify, channels };
};

/* ========================================================================== */

type MonitoringChannelIconProps = {
  channel: MonitoringChannel;
};

const MonitoringChannelIcon: React.FC<MonitoringChannelIconProps> = ({
  channel
}) => {
  const Icon =
    channel === "Shopify"
      ? IconShopify
      : channel === "Unknown"
        ? IconServer
        : (destinations.find(d => d.name === channel)?.icon ?? IconServer);

  return <Icon size="24px" />;
};

/* ========================================================================== */

type SummaryDataItem =
  | {
      channel: Extract<MonitoringChannel, "Shopify">;
      title: string;
      url?: undefined;
      eventData: Array<MappedPoint>;
    }
  | {
      channel: Exclude<MonitoringChannel, "Shopify">;
      title: string;
      url: string;
      eventData: Array<MappedPoint>;
    };

type ServerEventsLogInner2Props = {
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyMarkets: ShopifyMarkets;
  resultSet: ResultSet<SummaryQueryData>;
  validInfo: Array<ValidInfoItem>;
  cubeDateRange: CubeDateRange;
};

const ServerEventsLogInner2: React.FC<ServerEventsLogInner2Props> = ({
  eventsConnectorConfig,
  shopifyMarkets,
  resultSet,
  validInfo,
  cubeDateRange
}) => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;

  const { events, shopify, channels } = useMemo(
    () => transformSummaryResult(resultSet, validInfo),
    [resultSet, validInfo]
  );

  const getUrl = (shorthand: string, configId: number) => {
    return `${websiteUrl}/my-tracking/destination-${shorthand}/${configId}`;
  };

  const summaryData: Array<SummaryDataItem> = [
    { channel: "Shopify", title: "Shopify", eventData: shopify.points },
    ...channels.map(c => ({
      channel: c.destination.name,
      title: c.title,
      url: getUrl(c.destination.shorthand, c.config.id),
      eventData: c.points
    }))
  ];

  return (
    <>
      <ServerEventsLogSummaryPageCard>
        <div>
          <table>
            <thead>
              <tr>
                <th />
                {events.map(event => (
                  <th key={event}>{formatEventName(event)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {summaryData.map(({ channel, title, url, eventData }) => (
                <tr key={title}>
                  <td>
                    <div>
                      <div>
                        <MonitoringChannelIcon channel={channel} />
                      </div>
                      <div>
                        <div>{title}</div>
                        {channel === "Pinterest" ? (
                          <TooltipBig
                            placement="top"
                            maxWidth={`${theme.gridBase * 45}px`}
                            render={() => (
                              <ServerEventsLogSummaryTooltipContent>
                                <p>
                                  Pinterest has a limit of 5000 requests per
                                  minute. If your store has more requests, we
                                  recommend disabling the pageview and other
                                  events.
                                </p>
                                <Link to={url}>Go to destination</Link>
                              </ServerEventsLogSummaryTooltipContent>
                            )}
                          >
                            <ServerEventsLogSummaryTooltipTrigger>
                              <IconHelp size="16px" />
                            </ServerEventsLogSummaryTooltipTrigger>
                          </TooltipBig>
                        ) : channel === "Mixpanel" ? (
                          <TooltipBig
                            placement="top"
                            maxWidth={`${theme.gridBase * 45}px`}
                            render={() => (
                              <ServerEventsLogSummaryTooltipContent>
                                <p>
                                  Mixpanel has a limit of 30,000 requests per
                                  minute. If your store has more requests, we
                                  recommend disabling the page view and other
                                  events.
                                </p>
                                <Link to={url}>Go to destination</Link>
                              </ServerEventsLogSummaryTooltipContent>
                            )}
                          >
                            <ServerEventsLogSummaryTooltipTrigger>
                              <IconHelp size="16px" />
                            </ServerEventsLogSummaryTooltipTrigger>
                          </TooltipBig>
                        ) : channel === "Reddit" ? (
                          <TooltipBig
                            placement="top"
                            maxWidth={`${theme.gridBase * 43}px`}
                            render={() => (
                              <ServerEventsLogSummaryTooltipContent>
                                <p>
                                  Reddit has a limit of 10 requests per second.
                                  If your store has more requests, we recommend
                                  disabling the page view and other events.
                                </p>
                                <Link to={url}>Go to destination</Link>
                              </ServerEventsLogSummaryTooltipContent>
                            )}
                          >
                            <ServerEventsLogSummaryTooltipTrigger>
                              <IconHelp size="16px" />
                            </ServerEventsLogSummaryTooltipTrigger>
                          </TooltipBig>
                        ) : null}
                      </div>
                    </div>
                  </td>
                  {eventData.map(item => (
                    <td
                      key={item.event}
                      className={item.successCount === null ? "empty" : "full"}
                    >
                      {item.successCount === null
                        ? "n/a"
                        : item.successCount.toLocaleString("en")}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ServerEventsLogSummaryPageCard>
      <ServerEventsLogDetails
        eventsConnectorConfig={eventsConnectorConfig}
        shopifyMarkets={shopifyMarkets}
        validInfo={validInfo}
        cubeDateRange={cubeDateRange}
        channels={channels}
      />
    </>
  );
};

const ServerEventsLogSummaryPageCard = styled(PageCard)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div {
    ${scrollbarHoriztonalMixin};
    ${normalBodyStyles};
    overflow-x: auto;

    > table {
      width: 100%;
      border-spacing: 0;

      > thead {
        > tr {
          > th {
            padding: 0;
            height: ${props => props.theme.gridBase * 8}px;
            border-bottom: 1px solid ${props => props.theme.palette.grey7};
          }

          > th:first-child {
            position: sticky;
            left: 0;
            background-color: ${props => props.theme.palette.white};
            border-right: 1px solid ${props => props.theme.palette.grey7};
          }

          > th:not(:first-child) {
            ${smallTextStyles};
            color: ${props => props.theme.palette.grey3};
            text-align: right;
            min-width: ${props => props.theme.gridBase * 18}px;
            padding-right: ${props => props.theme.gridBase * 4}px;
          }
        }
      }

      > tbody {
        > tr {
          > td {
            padding: 0;
            height: ${props => props.theme.gridBase * 6}px;
            border-bottom: 1px solid ${props => props.theme.palette.grey7};
          }

          > td:first-child {
            position: sticky;
            left: 0;
            background-color: ${props => props.theme.palette.white};
            border-right: 1px solid ${props => props.theme.palette.grey7};

            > div {
              display: flex;
              align-items: center;

              > div:first-child {
                display: flex;
                margin: 0 ${props => props.theme.gridBase * 2}px;
              }

              > div:last-child {
                display: flex;
                align-items: center;
                gap: ${props => props.theme.gridBase * 1.5}px;
                white-space: nowrap;
                min-width: ${props => props.theme.gridBase * 25}px;
                padding-right: ${props => props.theme.gridBase * 2}px;
              }
            }
          }

          > td:not(:first-child) {
            white-space: nowrap;
            text-align: right;
            padding-right: ${props => props.theme.gridBase * 4}px;

            &.empty {
              color: ${props => props.theme.palette.grey4};
            }
            &.full {
              color: ${props => props.theme.palette.grey1};
            }
          }
        }
      }
    }
  }
`;

const ServerEventsLogSummaryTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }

  > a {
    ${linkStyles};
  }
`;

const ServerEventsLogSummaryTooltipTrigger = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
`;

/* ========================================================================== */

type ServerEventsLogDetailsProps = {
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyMarkets: ShopifyMarkets;
  validInfo: Array<ValidInfoItem>;
  cubeDateRange: CubeDateRange;
  channels: TransformedData["channels"];
};

const ServerEventsLogDetails: React.FC<ServerEventsLogDetailsProps> = ({
  eventsConnectorConfig,
  shopifyMarkets,
  cubeDateRange,
  validInfo,
  channels
}) => {
  const location = useLocation();

  const baseQuery = {
    measures: ["ServerSideEvents.count"],
    dimensions: [
      "ServerSideEvents.channel",
      "ServerSideEvents.config_id",
      "ServerSideEvents.event",
      "ServerSideEvents.state",
      "ServerSideEvents.error_code",
      "ServerSideEvents.error_message"
    ],
    timeDimensions: [
      { dimension: "ServerSideEvents.time", dateRange: cubeDateRange }
    ],
    filters: [
      {
        or: [
          {
            member: "ServerSideEvents.channel",
            operator: "equals",
            values: ["Shopify"]
          },
          ...validInfo.map<Filter>(({ destination, configs }) => ({
            and: [
              {
                member: "ServerSideEvents.channel",
                operator: "equals",
                values: [destination.name]
              },
              {
                member: "ServerSideEvents.config_id",
                operator: "equals",
                values: configs.map(c => String(c.id))
              }
            ]
          }))
        ]
      }
    ]
  } satisfies Query;

  const { queryResult, setQueryDimensions } = useConfigurableCubeQuery({
    baseQuery
  });

  const tablePageCardRef = useRef<HTMLElement | null>(null);

  const defaultFilters = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const channel = searchParams.get("channelDefault");
    const state = searchParams.get("stateDefault");
    return [
      ...(channel
        ? [{ key: "ServerSideEvents.config_id", value: channel }]
        : []),
      ...(state ? [{ key: "ServerSideEvents.state", value: state }] : [])
    ];
  }, [location.search]);

  useEffect(() => {
    if (queryResult.isFetched && defaultFilters.length > 0) {
      tablePageCardRef.current?.scrollIntoView();
    }
  }, [queryResult.isFetched, defaultFilters]);

  return (
    <ServerEventsLogDetailsPageCard ref={tablePageCardRef}>
      <CubeTable
        visibleRows={25}
        queryResult={queryResult}
        columnMapper={column => {
          switch (column.key) {
            case "ServerSideEvents.channel":
              return null;
            case "ServerSideEvents.config_id":
              return { ...column, title: "Channel", type: "text" };
            case "ServerSideEvents.selected_market":
              return { ...column, type: "text" };
            default:
              return column;
          }
        }}
        rowMapper={row => ({
          ...(omit(row, ["key", "ServerSideEvents.channel"]) as typeof row),
          "ServerSideEvents.event": formatEventName(
            row["ServerSideEvents.event"] as MonitoringEvent
          ),
          "ServerSideEvents.state": capitalize(
            row["ServerSideEvents.state"] as string | undefined
          ),
          "ServerSideEvents.config_id":
            (
              channels.find(
                c =>
                  c.destination.name === row["ServerSideEvents.channel"] &&
                  c.config.id === row["ServerSideEvents.config_id"]
              ) ??
              channels.find(
                c => c.destination.name === row["ServerSideEvents.channel"]
              )
            )?.title ?? row["ServerSideEvents.channel"]!,
          "ServerSideEvents.selected_market":
            shopifyMarkets.find(
              m => m.id === row["ServerSideEvents.selected_market"]
            )?.name ??
            (!row["ServerSideEvents.selected_market"]
              ? "No Market ID"
              : "Unconfigured Market")
        })}
        defaultFilters={defaultFilters}
        defaultRowSorters={[
          ...eventOrder.map<(row: TableRow) => boolean>(
            event => row => row["ServerSideEvents.event"] === event
          ),
          ...channelOrder.map<(row: TableRow) => boolean>(
            channel => row => row["ServerSideEvents.channel"] === channel
          )
        ]}
        renderActions={() => (
          <DimensionCube
            initialActiveDimensions={baseQuery.dimensions}
            dimensions={[
              ...baseQuery.dimensions.filter(
                d => d !== "ServerSideEvents.channel"
              ),
              ...(eventsConnectorConfig.globalConfig.marketsEnabled
                ? [
                    "ServerSideEvents.provided_market",
                    "ServerSideEvents.selected_market"
                  ]
                : []),
              "ServerSideEvents.page_url"
            ]}
            setQueryDimensions={setQueryDimensions}
            dropdownPlacement="bottom-end"
            dimensionTitleMapper={t => (t === "Config ID" ? "Channel" : t)}
          />
        )}
      />
    </ServerEventsLogDetailsPageCard>
  );
};

const ServerEventsLogDetailsPageCard = styled(PageCard)`
  padding: 0;
`;
