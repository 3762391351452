import styled, { useTheme } from "styled-components";

import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconAlertCircle,
  IconCheckMark,
  IconPaperPlane
} from "elevar-design-system/src/icons";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading1Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { BrandShowcase } from "../../../../components/BrandShowcase";
import { PageCard } from "../../../../components/PageCard";
import { useGlobalError } from "../../../../context/GlobalError";
import { useOnboardingDetails } from "../../../../context/OnboardingDetails";
import { useServerSideDetails } from "../../../../context/ServerSideDetails";
import { useUserRequired } from "../../../../context/User";
import { useWebsiteId } from "../../../../utils/idHooks";
import { track } from "../../../../utils/track";
import { PlanSelector } from "./PlanSelector";
import {
  type CurrentPlan,
  type ManagePlanStep,
  type Plan,
  type Product,
  trackingManagementTier1AddOn
} from "./shared";

/* ========================================================================== */

type StepSelectPlanProps = {
  setStep: (step: ManagePlanStep) => void;
  specialPlan: Plan | null;
  currentPlan: CurrentPlan;
  selectedPlan: Plan | null;
  setSelectedPlan: (plan: Plan | null) => void;
  isAddOnSelected: boolean;
  setIsAddOnSelected: (isAddOnSelected: boolean) => void;
  initialIsAddOnSelected: boolean;
  selectedProduct: Product | null;
  setSelectedProduct: (selectedProduct: Product | null) => void;
  isNewUser: boolean;
  marketsEnabled: boolean;
};

export const StepSelectPlan: React.FC<StepSelectPlanProps> = ({
  setStep,
  specialPlan,
  currentPlan,
  selectedPlan,
  setSelectedPlan,
  isAddOnSelected,
  setIsAddOnSelected,
  initialIsAddOnSelected,
  selectedProduct,
  setSelectedProduct,
  isNewUser,
  marketsEnabled
}) => {
  const user = useUserRequired();
  const { onboardingState } = useOnboardingDetails();

  const goToNextStep = (plan: Plan) => {
    track.managePlanBeginCheckout({ plan, user });

    if (
      onboardingState.name === "PLAN_SELECTION" ||
      (!initialIsAddOnSelected && isAddOnSelected)
    ) {
      setStep("SELECT_PRODUCT");
      track.managePlanInitialProductsView(isNewUser);
    } else {
      if (selectedProduct) setSelectedProduct(null);
      setStep("CHECKOUT");
    }
  };

  const isAddOnSectionVisible =
    selectedPlan !== null && selectedPlan.id !== specialPlan?.id;

  return (
    <>
      <PlanSelector
        specialPlan={specialPlan}
        currentPlan={currentPlan}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        setIsAddOnSelected={setIsAddOnSelected}
        goToNextStep={goToNextStep}
        marketsEnabled={marketsEnabled}
      />
      {isAddOnSectionVisible ? (
        <PlanPickerAddOn
          isAddOnSelected={isAddOnSelected}
          setIsAddOnSelected={setIsAddOnSelected}
        />
      ) : null}
      <PlanPickerFooter isAddOnSectionVisible={isAddOnSectionVisible} />
      {selectedPlan !== null && selectedPlan.id !== specialPlan?.id ? (
        <ContinueBar
          goToNextStep={() => goToNextStep(selectedPlan)}
          isAddOnSelected={isAddOnSelected}
          isContinueButtonDisabled={
            selectedPlan.id === currentPlan.remote.id &&
            isAddOnSelected === initialIsAddOnSelected
          }
        />
      ) : null}
    </>
  );
};

/* ========================================================================== */

type PlanPickerAddOnProps = {
  isAddOnSelected: boolean;
  setIsAddOnSelected: (isAddOnSelected: boolean) => void;
};

const PlanPickerAddOn: React.FC<PlanPickerAddOnProps> = ({
  isAddOnSelected,
  setIsAddOnSelected
}) => {
  const theme = useTheme();
  const { onboardingState } = useOnboardingDetails();

  const onAddOnSelect = (isSelected: boolean) => {
    setIsAddOnSelected(isSelected);
    track.managePlanAddOnButtonClick({
      onboardingState: onboardingState.name
    });
  };

  return (
    <PlanPickerAddOnWrapper>
      <PlanPickerAddOnHeading>Select Services</PlanPickerAddOnHeading>
      <PlanPickerAddOnPageCard isSelected={isAddOnSelected}>
        <div>
          <div>{trackingManagementTier1AddOn.name}</div>
          <PlanPickerAddOnDescription>
            {trackingManagementTier1AddOn.description}
          </PlanPickerAddOnDescription>
          {trackingManagementTier1AddOn.whatsIncluded.length > 0 ? (
            <PlanPickerAddOnWhatsIncludedWrapper>
              {trackingManagementTier1AddOn.whatsIncluded.map((item, index) => (
                <PlanPickerAddOnWhatsIncludedItem key={index}>
                  <div>
                    <IconCheckMark size="16px" color={theme.palette.purple2} />
                  </div>
                  <div>{item}</div>
                </PlanPickerAddOnWhatsIncludedItem>
              ))}
              {trackingManagementTier1AddOn.whatsRequired.map((item, index) => (
                <PlanPickerAddOnWhatsIncludedItem key={index}>
                  <div>
                    <IconAlertCircle size="16px" color={theme.palette.orange} />
                  </div>
                  <div>{item}</div>
                </PlanPickerAddOnWhatsIncludedItem>
              ))}
            </PlanPickerAddOnWhatsIncludedWrapper>
          ) : null}
        </div>
        <div>
          {isAddOnSelected ? (
            <PlanPickerAddOnButtonSecondary
              variant="SMALL"
              onClick={() => onAddOnSelect(false)}
            >
              Added for ${trackingManagementTier1AddOn.dollarPrice}
            </PlanPickerAddOnButtonSecondary>
          ) : (
            <PlanPickerAddOnButtonPrimary
              variant="SMALL"
              onClick={() => onAddOnSelect(true)}
            >
              Get for ${trackingManagementTier1AddOn.dollarPrice} /month
            </PlanPickerAddOnButtonPrimary>
          )}
        </div>
      </PlanPickerAddOnPageCard>
    </PlanPickerAddOnWrapper>
  );
};

const PlanPickerAddOnWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 5}px;
`;

const PlanPickerAddOnHeading = styled.div`
  ${heading1Styles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

type PlanPickerAddOnPageCardProps = {
  isSelected: boolean;
};

const PlanPickerAddOnPageCard = styled(PageCard)<PlanPickerAddOnPageCardProps>`
  display: flex;
  justify-content: space-between;
  position: relative;

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    inset: 0;
    border: 1px solid
      ${props =>
        props.isSelected ? props.theme.palette.purple2 : "transparent"};
    transition: border-color ${props => props.theme.other.transition};
    border-radius: 4px;
  }

  > div:first-child {
    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }

  > div:last-child {
    width: ${props => props.theme.gridBase * 25}px;
  }
`;

const PlanPickerAddOnDescription = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  max-width: 80%;
`;

const PlanPickerAddOnWhatsIncludedWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 1.5}px;
`;

const PlanPickerAddOnWhatsIncludedItem = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    display: flex;
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const PlanPickerAddOnButtonSecondary = styled(ButtonSecondary)`
  width: 100%;
`;

const PlanPickerAddOnButtonPrimary = styled(ButtonPrimary)`
  width: 100%;
`;

/* ========================================================================== */

type PlanPickerFooterProps = {
  isAddOnSectionVisible: boolean;
};

const PlanPickerFooter: React.FC<PlanPickerFooterProps> = ({
  isAddOnSectionVisible
}) => {
  const { onboardingState } = useOnboardingDetails();

  return (
    <PlanPickerFooterWrapper>
      <EnterprisePlansCard>
        <div>
          <IconPaperPlane size="24px" />
        </div>
        {isAddOnSectionVisible ? (
          <div>
            <h3>Need Help with Tracking, Data Analysis, or CRO?</h3>
            <StyledLinkExternal
              href="https://www.getelevar.com/book-call-with-sales/"
              text="Ask about our other services"
            />
          </div>
        ) : (
          <div>
            <h3>Have 75,000+ orders per month?</h3>
            <StyledLinkExternal
              href="https://www.getelevar.com/book-call-with-sales/"
              text="Ask about our enterprise plans"
              onClick={() => {
                track.managePlanEnterprisePlansLinkClick({
                  onboardingState: onboardingState.name
                });
              }}
            />
          </div>
        )}
      </EnterprisePlansCard>
      <BrandShowcase />
    </PlanPickerFooterWrapper>
  );
};

const PlanPickerFooterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.gridBase * 6}px;
  align-items: center;
  margin-top: ${props => props.theme.gridBase * 5}px;
  margin-bottom: ${props => props.theme.gridBase * 19}px;
`;

const EnterprisePlansCard = styled(PageCard)`
  display: flex;
  gap: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    display: flex;
    color: ${props => props.theme.palette.purple2};
  }

  > div:last-child {
    > h3 {
      ${normalTextStyles};
      font-weight: 500;
    }

    > p {
      ${normalTextStyles};
      color: ${props => props.theme.palette.grey2};
    }

    > :not(:first-child) {
      margin-top: ${props => props.theme.gridBase * 1.25}px;
    }
  }
`;

/* ========================================================================== */

type ContinueBarProps = {
  goToNextStep: () => void;
  isAddOnSelected: boolean;
  isContinueButtonDisabled: boolean;
};

const ContinueBar: React.FC<ContinueBarProps> = ({
  goToNextStep,
  isAddOnSelected,
  isContinueButtonDisabled
}) => {
  const websiteId = useWebsiteId();
  const { isFullyManaged } = useServerSideDetails();
  const { globalError, globalErrorDispatch } = useGlobalError();

  return (
    <ContinueBarWrapper>
      <div>
        <div>
          <ContinueBarContentWrapper>
            <div>
              <div>
                <IconCheckMark size="24px" />
              </div>
              <div>Monthly Plan</div>
            </div>
            {isAddOnSelected ? (
              <div>
                <div>
                  <IconCheckMark size="24px" />
                </div>
                <div>Monthly Services</div>
              </div>
            ) : null}
          </ContinueBarContentWrapper>
        </div>
        <div>
          <Tooltip
            text="You haven't made any changes"
            placement="top"
            disabled={!isContinueButtonDisabled}
          >
            <ContinueBarTooltipInner>
              <ContinueBarButtonPrimary
                variant="LARGE"
                state={isContinueButtonDisabled ? "DISABLED" : "IDLE"}
                onClick={() => {
                  if (
                    isFullyManaged &&
                    globalError.proactive?.websiteId === websiteId
                  ) {
                    globalErrorDispatch({ type: "RESHOW_PROACTIVE" });
                  } else {
                    goToNextStep();
                  }
                }}
              >
                Continue
              </ContinueBarButtonPrimary>
            </ContinueBarTooltipInner>
          </Tooltip>
        </div>
      </div>
    </ContinueBarWrapper>
  );
};

const ContinueBarWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.palette.white};

  > div {
    width: 100%;
    max-width: ${props => props.theme.gridBase * 125}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.theme.gridBase * 2.5}px 0;
  }
`;

const ContinueBarContentWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.gridBase * 4}px;

  > div {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase}px;

    > div:first-child {
      color: ${props => props.theme.palette.green};
    }

    > div:last-child {
      ${normalTextStyles};
      color: ${props => props.theme.palette.grey2};
    }
  }
`;

const ContinueBarTooltipInner = styled.span`
  display: inline-block;
`;

const ContinueBarButtonPrimary = styled(ButtonPrimary)`
  width: ${props => props.theme.gridBase * 30}px;
`;
