import { isEqual } from "lodash-es";
import { transparentize } from "polished";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";

import {
  type ImageUpload,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import { Avatar } from "elevar-design-system/src/Avatar";
import {
  baseButtonStyles,
  smallButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  IconActivity,
  IconChevronLeft,
  IconChevronRight,
  IconChurnRisk,
  IconCog,
  IconCompass,
  IconHelp,
  IconHouse,
  IconLabel,
  IconLightBulb,
  IconMonitor,
  IconPersonas,
  IconShuffle,
  IconSite,
  IconTarget
} from "elevar-design-system/src/icons";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { scrollbarMixin } from "elevar-design-system/src/scrollbar";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { Banner } from "../../components/Banner";
import { Status } from "../../components/Status";
import { UserButtonDropdown } from "../../components/UserButtonDropdown";
import { type NavigatableOnboardingState } from "../../context/OnboardingDetails";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { track } from "../../utils/track";

/* ========================================================================== */

export type NavigationData = {
  isV1Installed: boolean;
  isOnDefaultFreePlan: boolean;
  isCompanyAdmin: boolean;
  onboardingState: NavigatableOnboardingState;
  areProductRoutesAvailable: boolean;
  isConnectionsRouteAvailable: boolean;
  userName: string;
  userImage: ImageUpload | undefined;
  companyName: string;
  companyImage: ImageUpload | null;
  websiteName: string;
  serverSideType: WebsiteDetails["server_side_type"];
};

type WebsiteLayoutProps = {
  navigationData: NavigationData;
  showMigrationBanner?: boolean;
  children: React.ReactNode;
};

export const WebsiteLayout: React.FC<WebsiteLayoutProps> = ({
  navigationData,
  showMigrationBanner = true,
  children
}) => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const companyUrl = `/company/${companyId}`;
  const websiteUrl = `${companyUrl}/website/${websiteId}`;
  const managePlanUrl = `${websiteUrl}/settings/plan/manage`;

  return (
    <Wrapper>
      <div>
        <Navigation navigationData={navigationData} />
      </div>
      <div>
        <Content>
          {showMigrationBanner &&
          (!navigationData.isOnDefaultFreePlan ||
            navigationData.isV1Installed) &&
          navigationData.serverSideType !== "FULLY_MANAGED" ? (
            <BannerWrapper>
              {navigationData.serverSideType === "GTM" ? (
                <LinkExternal href="https://www.getelevar.com/book-a-migration-call-with-elevar-team">
                  <Banner
                    textColor={theme.palette.white}
                    backgroundColor={theme.palette.orange}
                    primaryText="Update on Your Plan"
                    secondaryText="Due to Shopify's ecosystem changes, we're migrating all merchants to server-side tracking using Elevar's server."
                    actionText="Book a Migration Call"
                  />
                </LinkExternal>
              ) : (
                <Link to={managePlanUrl}>
                  <Banner
                    textColor={theme.palette.white}
                    backgroundColor={theme.palette.orange}
                    primaryText="Update Your Plan"
                    secondaryText="New server-side tracking plans ensure maximum tracking and compatibility with Shopify changes."
                    actionText="Go to Plans"
                  />
                </Link>
              )}
            </BannerWrapper>
          ) : null}
          {children}
        </Content>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => props.theme.gridBase * 35}px minmax(0, 1fr);
`;

const Content = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.palette.grey7};
`;

const BannerWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
  margin-bottom: ${props => props.theme.gridBase * -0.5}px;
`;

/* ========================================================================== */

type BasePage = { label: string; icon?: React.ReactElement; exact?: boolean };
type PageLink = { to: string } | { href: string };
type LeafPage = BasePage & PageLink & { new?: boolean; onClick?: () => void };
type SubPage = LeafPage;
type Group<T> = { label?: string; items: Array<T> };
type SubMenu = Array<Group<SubPage>>;
type TopPageWithSubMenu = BasePage & { subMenu: SubMenu };
type TopPage = LeafPage | TopPageWithSubMenu;
type SubMenuState =
  | { open: false; content?: TopPageWithSubMenu }
  | { open: true; content: TopPageWithSubMenu };

type UseNavGroups = (args: NavigationData) => Array<Group<TopPage>>;

const useNavGroups: UseNavGroups = ({
  isV1Installed,
  isOnDefaultFreePlan,
  isCompanyAdmin,
  onboardingState,
  areProductRoutesAvailable,
  isConnectionsRouteAvailable,
  serverSideType
}) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const companyUrl = `/company/${companyId}`;
  const websiteUrl = `${companyUrl}/website/${websiteId}`;
  const isFullyManaged = serverSideType === "FULLY_MANAGED";

  return useMemo(
    () => [
      ...(onboardingState.name !== "DONE"
        ? [
            {
              items: [
                {
                  label: "My Tracking",
                  to: `${websiteUrl}/my-tracking`,
                  icon: <IconTarget size="24px" />
                },
                ...(onboardingState.name === "ONBOARDING" &&
                (onboardingState.info.step === "STORE_QUESTIONAIRE" ||
                  onboardingState.info.step === "REMOVE_PREVIOUS_TRACKING")
                  ? [
                      {
                        label: "Pre-Built Tags",
                        to: `${websiteUrl}/pre-built-tags`,
                        icon: <IconLabel size="24px" />
                      }
                    ]
                  : [])
              ]
            }
          ]
        : [
            {
              items: [
                {
                  label: "Dashboard",
                  to: websiteUrl,
                  icon: <IconHouse size="24px" />,
                  exact: true
                },
                ...(isFullyManaged || (!isV1Installed && !isOnDefaultFreePlan)
                  ? [
                      {
                        label: "My Tracking",
                        to: `${websiteUrl}/my-tracking`,
                        icon: <IconTarget size="24px" />
                      }
                    ]
                  : []),
                ...(isFullyManaged
                  ? [
                      {
                        label: "Real-Time Activity",
                        to: `${websiteUrl}/real-time-activity`,
                        icon: <IconActivity size="24px" />
                      }
                    ]
                  : []),
                {
                  label: "Personas",
                  href: "https://www.getelevar.com/customer-personas/",
                  icon: <IconPersonas size="24px" />,
                  onClick: () => track.crossSellLinkClick(),
                  new: true
                },
                {
                  label: "Churn Risk",
                  href: "https://www.getelevar.com/buxton/",
                  icon: <IconChurnRisk size="24px" />,
                  onClick: () => track.crossSellLinkClick(),
                  new: true
                }
              ]
            },
            {
              label: "Tagging",
              items: [
                {
                  label: "Event Builder",
                  to: `${websiteUrl}/event-builder`,
                  icon: <IconMonitor size="24px" />
                },
                {
                  label: "Pre-Built Tags",
                  to: `${websiteUrl}/pre-built-tags`,
                  icon: <IconLabel size="24px" />
                }
              ]
            },
            ...(isFullyManaged
              ? [
                  {
                    label: "Monitoring",
                    items: [
                      {
                        label: "Channel Accuracy",
                        to: `${websiteUrl}/channel-accuracy`,
                        icon: <IconCompass size="24px" />
                      },
                      {
                        label: "Attribution Feed",
                        to: `${websiteUrl}/attribution-feed`,
                        icon: <IconSite size="24px" />
                      }
                    ]
                  }
                ]
              : []),
            {
              label: "Optimization",
              items: [
                {
                  label: "CRO Ideas",
                  to: `${websiteUrl}/cro-ideas`,
                  icon: <IconLightBulb size="24px" />
                }
              ]
            }
          ]),
      {
        items: [
          ...(isCompanyAdmin
            ? [
                {
                  label: "Settings",
                  icon: <IconCog size="24px" />,
                  subMenu: [
                    {
                      label: "Website",
                      items: [
                        {
                          label: "Website Info",
                          to: `${websiteUrl}/settings/info`
                        },
                        {
                          label: "Plan & Usage",
                          to: `${websiteUrl}/settings/plan`
                        },
                        ...(areProductRoutesAvailable
                          ? [
                              {
                                label: "Audits & Setups",
                                to: `${websiteUrl}/settings/products`
                              }
                            ]
                          : []),
                        ...(isConnectionsRouteAvailable
                          ? [
                              {
                                label: "Connections",
                                to: `${websiteUrl}/settings/connections`
                              }
                            ]
                          : []),
                        {
                          label: "History",
                          to: `${websiteUrl}/settings/history`
                        }
                      ]
                    },
                    {
                      label: "Company",
                      items: [
                        {
                          label: "Company Info",
                          to: `${companyUrl}/settings/info`
                        },
                        {
                          label: "Team Members",
                          to: `${companyUrl}/settings/team`
                        },
                        {
                          label: "Billing",
                          to: `${companyUrl}/settings/billing`
                        }
                      ]
                    },
                    {
                      label: "Referral Program",
                      items: [
                        {
                          label: "🔥\u00a0\u00a0Refer a Friend & Earn $500",
                          href: "https://www.getelevar.com/refer-a-friend/?utm_source=elevar_app&utm_medium=in_app&utm_campaign=refer_a_friend"
                        }
                      ]
                    }
                  ]
                }
              ]
            : []),
          ...(isFullyManaged
            ? [
                {
                  label: "Support",
                  to: `${websiteUrl}/support`,
                  icon: <IconHelp size="24px" />
                }
              ]
            : [])
        ]
      }
    ],
    [
      isV1Installed,
      isOnDefaultFreePlan,
      isCompanyAdmin,
      onboardingState,
      areProductRoutesAvailable,
      isConnectionsRouteAvailable,
      websiteUrl,
      companyUrl,
      isFullyManaged
    ]
  );
};

/* ========================================================================== */

const slideDuration = 0.3;

type NavigationProps = {
  navigationData: NavigationData;
};

const Navigation: React.FC<NavigationProps> = ({ navigationData }) => {
  const theme = useTheme();
  const location = useLocation();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const groups = useNavGroups(navigationData);

  const initialSubMenu: SubMenuState = useMemo(() => {
    const websiteUrl = `/company/${companyId}/website/${websiteId}`;

    const websiteSettingsGroup = groups.find(group => {
      return group.items.some(item => item.label === "Settings");
    });

    const websiteSettingsSubMenu = websiteSettingsGroup
      ? websiteSettingsGroup.items[0]
      : undefined;

    if (
      location.pathname.startsWith(`${websiteUrl}/settings`) &&
      websiteSettingsSubMenu !== undefined &&
      "subMenu" in websiteSettingsSubMenu
    ) {
      return { open: true, content: websiteSettingsSubMenu };
    } else {
      return { open: false };
    }
  }, [companyId, websiteId, groups, location.pathname]);

  const [localGroups, setLocalGroups] = useState(groups);
  const [subMenu, setSubMenu] = useState(initialSubMenu);

  const subMenuRef = useRef<HTMLButtonElement | null>(null);
  const topMenuRef = useRef<HTMLButtonElement | null>(null);

  /**
   * NOTE 1:
   *
   * The below effect ensures the nav group content (including the sub-menu)
   * is always kept correct when `groups` changes. For example, `groups` can
   * currently change during mount if a Website transitions from a plan with
   * the `EXTERNAL_CONNECTIONS` service code to a plan without it (the item
   * for configuring "Connections" is shown based on the service code).
   *
   * This effect is required because `subMenu` saves a local copy of a menu
   * object, meaning that changes to `groups` aren't reflected in the object
   * saved in that state. It would be better if we could store a reference
   * to the menu in state instead of storing the whole object, but I can see
   * there being bigger problems with this approach (mainly that a reference
   * would likely be hard to keep correct if `groups` changes dramatically).
   *
   * Do note that the result of this effect isn't perfect UX wise, as it does
   * mean that there is potential for the menu that the user is currently on
   * to be changed to the default based on the route (via `initialSubMenu`),
   * but this certainly isn't the end of the world.
   *
   * NOTE 2:
   *
   * The below `setTimeout`s exist to allow the transition animations to
   * finish before focusing the content. If this wasn't done, the browser
   * would move the view to show the element that is still outside the view,
   * interrupting the animation, which would cause it to finish too early.
   */

  useEffect(() => {
    if (!isEqual(groups, localGroups)) {
      setLocalGroups(groups);
      setSubMenu(initialSubMenu);
      setTimeout(() => subMenuRef.current?.focus(), slideDuration * 1000);
    }
  }, [groups, localGroups, initialSubMenu]);

  const openSubMenu = (details: TopPageWithSubMenu) => {
    setSubMenu({ open: true, content: details });
    setTimeout(() => subMenuRef.current?.focus(), slideDuration * 1000);
  };

  const closeSubMenu = () => {
    setSubMenu({ ...subMenu, open: false });
    setTimeout(() => topMenuRef.current?.focus(), slideDuration * 1000);
  };

  return (
    <WebsiteNavigationWrapper>
      <Section>
        <CompaniesAndWebsitesLink to="/">
          <div>
            <HoverOverlay>
              <IconShuffle size="24px" color={theme.palette.white} />
            </HoverOverlay>
            <Avatar
              size="MEDIUM"
              type="COMPANY"
              imageSrc={navigationData.companyImage?.file}
            />
          </div>
          <div>
            <CompanyName>{navigationData.companyName}</CompanyName>
            <WebsiteName>{navigationData.websiteName}</WebsiteName>
          </div>
        </CompaniesAndWebsitesLink>
      </Section>
      <MenuContentWrapper>
        <MenuContent>
          <TopMenuDrawer show={!subMenu.open}>
            <ContentWrapper ref={topMenuRef} tabIndex={-1}>
              <div>
                <NavGroups openSubMenu={openSubMenu} groups={localGroups} />
              </div>
            </ContentWrapper>
          </TopMenuDrawer>
          <SubMenuDrawer show={subMenu.open}>
            <ContentWrapper>
              <ButtonWithIcon onClick={closeSubMenu} ref={subMenuRef}>
                <SquareIcon>
                  <IconChevronLeft size="16px" color={theme.palette.grey3} />
                </SquareIcon>
                Back to Menu
              </ButtonWithIcon>
              {subMenu.content ? (
                <>
                  <SubMenuHeader>
                    <SubMenuTitle id="submenu_header">
                      {subMenu.content.label}
                    </SubMenuTitle>
                  </SubMenuHeader>
                  <SubMenuContent>
                    <NavGroups
                      openSubMenu={openSubMenu}
                      groups={subMenu.content.subMenu}
                      isSubMenu={true}
                    />
                  </SubMenuContent>
                </>
              ) : null}
            </ContentWrapper>
          </SubMenuDrawer>
        </MenuContent>
      </MenuContentWrapper>
      <Section>
        <UserInfoWrapper>
          <UserButtonDropdown
            userImage={navigationData.userImage}
            userName={navigationData.userName}
            showAccountSettingsLink={true}
          />
        </UserInfoWrapper>
      </Section>
    </WebsiteNavigationWrapper>
  );
};

const WebsiteNavigationWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: ${props => props.theme.gridBase * 35}px;
  min-width: ${props => props.theme.gridBase * 35}px;
  height: 100vh;
  background: ${props => props.theme.palette.white};
  overflow-x: hidden;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.theme.gridBase * 10}px;
  padding: 0 ${props => props.theme.gridBase * 3}px;

  &:first-of-type {
    border-bottom: 1px solid ${props => props.theme.palette.grey6};
  }

  &:last-of-type {
    border-top: 1px solid ${props => props.theme.palette.grey6};
  }
`;

const HoverOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity ${props => props.theme.other.transition};
  background-color: ${props => transparentize(0.2, props.theme.palette.grey1)};
`;

const CompaniesAndWebsitesLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;

  > div:first-child {
    position: relative;
    margin-right: ${props => props.theme.gridBase * 2}px;
  }

  > div:last-child {
    overflow-x: hidden;
    max-width: ${props => props.theme.gridBase * 21}px;
  }

  &:hover {
    ${HoverOverlay} {
      opacity: 1;
    }
  }
`;

const CompanyName = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey3};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

const WebsiteName = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

const MenuContentWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow-x: hidden;
`;

const MenuContent = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.nav`
  outline: none;
  padding: ${props => props.theme.gridBase * 2}px
    ${props => props.theme.gridBase}px;

  & > * + * {
    margin-top: ${props => props.theme.gridBase * 2}px;
  }
`;

type DrawerProps = {
  show: boolean;
};

const drawerMixin = css<DrawerProps>`
  ${scrollbarMixin};
  margin: 0 8px;
  position: absolute;
  inset: 0;
  overflow-y: ${props => (props.show ? "auto" : "hidden")};
  opacity: ${props => (props.show ? 1 : 0)};
  visibility: ${props => (props.show ? "visible" : "hidden")};
  transition:
    transform ${slideDuration}s ease,
    opacity ${slideDuration}s ease,
    visibility ${slideDuration}s ease;
`;

const TopMenuDrawer = styled.div<DrawerProps>`
  ${drawerMixin};
  transform: translate3d(
    ${props => (props.show ? "0%" : "calc(-100% - 8px)")},
    0,
    0
  );
`;

const SubMenuDrawer = styled.div<DrawerProps>`
  ${drawerMixin};
  transform: translate3d(${props => (props.show ? "0%" : "100%")}, 0, 0);
`;

const ButtonWithIcon = styled.button`
  ${baseButtonStyles};
  ${smallButtonStyles};
  background-color: transparent;
  width: 100%;
  justify-content: start;
  color: ${props => props.theme.palette.grey3};
  line-height: ${props => props.theme.gridBase * 3}px;
  margin-top: -${props => props.theme.gridBase * 0.25}px;

  > div {
    transition: background-color ${props => props.theme.other.transition};
  }

  &:hover {
    background-color: transparent;

    > div {
      background-color: ${props => props.theme.palette.grey6};
    }
  }
`;

const SquareIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.theme.gridBase * 3}px;
  height: ${props => props.theme.gridBase * 3}px;
  margin-right: ${props => props.theme.gridBase}px;
  border-radius: ${props => props.theme.gridBase * 0.25}px;
  background-color: ${props => props.theme.palette.grey8};
`;

const SubMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${props => props.theme.gridBase * 2}px;
`;

const SubMenuTitle = styled.label`
  ${heading3Styles};
`;

const SubMenuContent = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const UserInfoWrapper = styled.div`
  position: relative;
`;

/* ========================================================================== */

type NavGroupsProps = {
  groups: Array<Group<TopPage | SubPage>>;
  isSubMenu?: boolean;
  openSubMenu: (details: TopPageWithSubMenu) => void;
};

const NavGroups: React.FC<NavGroupsProps> = ({
  groups,
  isSubMenu,
  openSubMenu
}) => {
  const theme = useTheme();

  const newStatus = (
    <Status
      reduceHeight={true}
      textColor={theme.palette.green}
      backgroundColor={transparentize(0.9, theme.palette.green)}
      text="New"
    />
  );

  return (
    <>
      {groups.map((group, index) => {
        const groupKey = group.label ? `${group.label}-${index}` : index;
        const groupTitleId = `nav_group_${index}`;
        const groupLabelPart1 = isSubMenu ? "submenu_header" : "";
        const groupLabelPart2 = group.label ? `nav_group_${index}` : "";
        const groupLabel = `${groupLabelPart1} ${groupLabelPart2}`.trim();

        return (
          <GroupWrapper key={groupKey}>
            {group.label ? (
              <GroupTitle id={groupTitleId}>{group.label}</GroupTitle>
            ) : null}

            <div aria-labelledby={groupLabel}>
              {group.items.map(groupItem => {
                if ("subMenu" in groupItem) {
                  return (
                    <OpenChildMenuButton
                      key={groupItem.label}
                      onClick={() => openSubMenu(groupItem)}
                    >
                      <div>
                        {groupItem.icon ?? null}
                        <span>{groupItem.label}</span>
                        <IconChevronRight size="16px" />
                      </div>
                    </OpenChildMenuButton>
                  );
                } else if ("to" in groupItem) {
                  return (
                    <NavLinkInternal
                      key={groupItem.label}
                      to={groupItem.to}
                      onClick={() => groupItem.onClick?.()}
                      exact={groupItem.exact}
                    >
                      <div>
                        {groupItem.icon ?? null}
                        <span>{groupItem.label}</span>
                        {groupItem.new ? newStatus : null}
                      </div>
                    </NavLinkInternal>
                  );
                } else {
                  return (
                    <NavLinkExternal
                      key={groupItem.label}
                      href={groupItem.href}
                      onClick={() => groupItem.onClick?.()}
                    >
                      <div>
                        {groupItem.icon ?? null}
                        <span>{groupItem.label}</span>
                        {groupItem.new ? newStatus : null}
                      </div>
                    </NavLinkExternal>
                  );
                }
              })}
            </div>
          </GroupWrapper>
        );
      })}
    </>
  );
};

const GroupWrapper = styled.div`
  &:not(:first-of-type) {
    margin-top: ${props => props.theme.gridBase * 4}px;
  }

  & > * + * {
    margin-top: ${props => props.theme.gridBase}px;
  }
`;

const GroupTitle = styled.label`
  ${subheadingStyles};
  display: inline-block;
  padding: 0 ${props => props.theme.gridBase * 2}px;
`;

const navButtonStyles = css`
  display: block;
  width: 100%;

  > div {
    ${baseButtonStyles};
    ${smallButtonStyles};
    ${normalBodyStyles};
    background-color: transparent;
    width: 100%;
    justify-content: start;
    align-items: center;
    color: ${props => props.theme.palette.grey2};
    padding-top: ${props => props.theme.gridBase * 0.75}px;
    padding-bottom: ${props => props.theme.gridBase * 0.75}px;
    padding-left: ${props => props.theme.gridBase * 2}px;

    text-align: left;

    > * + * {
      margin-left: ${props => props.theme.gridBase}px;
    }

    & > span {
      flex: 1;
    }
  }

  &:not(:first-child) {
    padding-top: ${props => props.theme.gridBase * 0.25}px;
  }

  &:not(:last-child) {
    padding-bottom: ${props => props.theme.gridBase * 0.25}px;
  }

  &:hover {
    > div {
      background-color: ${props => props.theme.palette.grey8};
    }
  }

  &.active {
    > div {
      color: ${props => props.theme.palette.blue1};
      background-color: ${props => props.theme.palette.grey8};
    }
  }
`;

const OpenChildMenuButton = styled.button`
  ${navButtonStyles};
`;

const NavLinkInternal = styled(NavLink)`
  ${navButtonStyles};

  > div {
    padding-right: ${props => props.theme.gridBase}px;
  }
`;

const NavLinkExternal = styled(LinkExternal)`
  ${navButtonStyles};

  > div {
    padding-right: ${props => props.theme.gridBase}px;
  }
`;
