import { uniq } from "lodash-es";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { IconSearch } from "elevar-design-system/src/icons";
import { type TabItem, Tabs } from "elevar-design-system/src/Tabs";
import { richTextStyles } from "elevar-design-system/src/typography/richText";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { type AppEducationSlideshowNode } from "../../api/handlers/appEducationSlideshows";
import { type ContainerDetails } from "../../api/handlers/containerDetails";
import { FeatureTipsButton } from "../../components/FeatureTipsButton";
import { PageCard } from "../../components/PageCard";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { track } from "../../utils/track";
import { ContainerTypeIcon } from "./ContainerTypeIcon";

/* ========================================================================== */

const useDebounce = <T,>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(timeoutId);
  }, [value, delay]);

  return debouncedValue;
};

/* ========================================================================== */

type PreBuiltTagsListProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
  containerDetailsList: Array<ContainerDetails>;
};

export const PreBuiltTagsList: React.FC<PreBuiltTagsListProps> = ({
  appEducationSlideshows,
  containerDetailsList
}) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const tabTitles = uniq(
    containerDetailsList.flatMap(c => c.type.map(item => item.name))
  );

  const [activeTabTitle, setActiveTabTitle] = useState(tabTitles[0]);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 200);
  const trackedQuery = useDebounce(query, 2000);

  useEffect(() => {
    if (trackedQuery !== "") {
      track.preBuiltTagsListSearch({ query: trackedQuery });
    }
  }, [trackedQuery]);

  const filteredContainers = containerDetailsList.filter(container => {
    const isInActiveTab = container.type.some(i => i.name === activeTabTitle);

    const normalizedQuery = debouncedQuery.toLowerCase().trim();

    const isIncludedInQuery =
      container.name.toLowerCase().includes(normalizedQuery) ||
      container.description.toLowerCase().includes(normalizedQuery) ||
      container.secondaryDescription.toLowerCase().includes(normalizedQuery) ||
      container.tagType.toLowerCase().includes(normalizedQuery);

    return isInActiveTab && isIncludedInQuery;
  });

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;

  return (
    <PageWrapper>
      <PageHeader>
        <PageHeading>Pre-Built Tags</PageHeading>
        <FeatureTipsButton
          data={appEducationSlideshows}
          route="PRE_BUILT_TAGS"
        />
      </PageHeader>
      <PageExplainer>
        Add a new marketing channel tag or Google Analytics custom event. These
        are pre-configured to our data layer. Simply download &gt; Import to GTM
        &gt; Update Account ID.
      </PageExplainer>
      <TabsWrapper>
        <Tabs
          items={tabTitles.map<TabItem>(title => ({
            title,
            isActive: title === activeTabTitle,
            type: "BUTTON",
            onClick: () => {
              setActiveTabTitle(title);
              track.tabChange({
                location: "preBuiltTags.list",
                tabName: title
              });
            }
          }))}
        />
      </TabsWrapper>
      <SearchWrapper>
        <IconSearch size="24px" />
        <SearchInput
          value={query}
          onChange={event => setQuery(event.target.value)}
          placeholder="Search..."
        />
      </SearchWrapper>
      {filteredContainers.length === 0 ? (
        <NoResultsText>No Results Found</NoResultsText>
      ) : (
        <CardsWrapper>
          {filteredContainers.map(container => (
            <Link
              key={container.id}
              to={`${websiteUrl}/pre-built-tags/${container.linkQueryParam}`}
            >
              <ContainerCardPageCard>
                <ContainerCardMainContent>
                  <ContainerCardHeader>
                    <div>
                      <ContainerTypeIcon type={container.typeIcon} />
                    </div>
                    <div>
                      <ContainerCardName>{container.name}</ContainerCardName>
                      <ContainerCardType>{container.tagType}</ContainerCardType>
                    </div>
                  </ContainerCardHeader>
                  <ContainerCardDescription
                    dangerouslySetInnerHTML={{
                      __html: container.description
                    }}
                  />
                </ContainerCardMainContent>
                <ContainerCardFooter>
                  <ContainerCardMoreInfoText>
                    More Info
                  </ContainerCardMoreInfoText>
                </ContainerCardFooter>
              </ContainerCardPageCard>
            </Link>
          ))}
        </CardsWrapper>
      )}
    </PageWrapper>
  );
};

/* ========================================================================== */

const PageWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3.25}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
  min-height: ${props => props.theme.gridBase * 5}px;
`;

const PageHeading = styled.div`
  ${heading2Styles};
`;

const PageExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  max-width: ${props => props.theme.gridBase * 75}px;
  margin-bottom: ${props => props.theme.gridBase * 5}px;
`;

const TabsWrapper = styled.div`
  margin-left: -${props => props.theme.gridBase * 2}px;
  margin-bottom: ${props => props.theme.gridBase * 3.5}px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;

  &:focus-within {
    color: ${props => props.theme.palette.grey1};
  }

  > ::placeholder {
    transition: ${props => props.theme.other.transition};
  }

  > svg {
    transition: ${props => props.theme.other.transition};
  }

  > :not(:first-child) {
    margin-left: ${props => props.theme.gridBase}px;
  }

  > :last-child {
    flex: 1;
  }
`;

const SearchInput = styled.input`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey1};
  border: 0;
  padding: 0;
  background: none;
  outline: none;

  &::placeholder {
    color: ${props => props.theme.palette.grey3};
  }

  &:focus::placeholder {
    color: ${props => props.theme.palette.grey5};
  }
`;

const NoResultsText = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
  text-align: center;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${props => props.theme.gridBase * 2}px;

  @media screen and (max-width: 1350px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ContainerCardPageCard = styled(PageCard)`
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContainerCardMainContent = styled.div`
  flex: 1;
  padding-top: ${props => props.theme.gridBase * 3}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
`;

const ContainerCardHeader = styled.header`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    display: flex;
    margin-right: ${props => props.theme.gridBase * 2}px;
  }
`;

const ContainerCardName = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const ContainerCardType = styled.div`
  ${subheadingStyles};
`;

const ContainerCardDescription = styled.div`
  ${normalBodyStyles};
  ${richTextStyles};
  color: ${props => props.theme.palette.grey2};
`;

const ContainerCardFooter = styled.div`
  border-top: 1px solid ${props => props.theme.palette.grey7};
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
`;

const ContainerCardMoreInfoText = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
`;
