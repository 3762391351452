import dayjs from "dayjs";

import {
  type CubeDateRange,
  type CubeGranularity
} from "../../context/MonitoringTimePeriod";

/* ========================================================================== */

export const nivoDateFormatter = (
  value: Date | string | number,
  cubeDateRange: CubeDateRange,
  cubeGranularity: CubeGranularity
) => {
  const date = dayjs(value);

  switch (cubeGranularity) {
    case "hour":
      return date.format("Do HH:mm");
    case "day":
      return date.format("MMM DD");
    case "week": {
      const lowerLimit = dayjs(cubeDateRange[0]);
      const upperLimit = dayjs(cubeDateRange[1]);
      const start = date.isBefore(lowerLimit) ? lowerLimit : date;
      const rawEnd = date.add(6, "days");
      const end = rawEnd.isAfter(upperLimit) ? upperLimit : rawEnd;
      return `${start.format("MMM DD")}-${end.format("DD")}`;
    }
  }
};
