import { useState } from "react";
import styled from "styled-components";

import { ButtonWhite } from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconChevronRight,
  IconCross,
  IconHelp
} from "elevar-design-system/src/icons";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { Modal } from "../../../../components/Modal";
import { type ManagePlanStep } from "./shared";

/* ========================================================================== */

type ProgressStepperProps = {
  activeStep: ManagePlanStep;
  setActiveStep: (activeStep: ManagePlanStep) => void;
  productStepState: "VISIBLE" | "HIDDEN";
};

export const ProgressStepper: React.FC<ProgressStepperProps> = ({
  activeStep,
  setActiveStep,
  productStepState
}) => {
  const [isFaqsModalVisible, setIsFaqsModalVisible] = useState(false);

  return (
    <>
      <Wrapper>
        <div>
          <StepButton
            state={activeStep === "SELECT_PLAN" ? "CURRENT" : "PAST"}
            onClick={() => setActiveStep("SELECT_PLAN")}
          >
            Plan & Services
          </StepButton>
          <StepSpacer>
            <IconChevronRight size="16px" />
          </StepSpacer>
          {productStepState !== "HIDDEN" ? (
            <>
              <StepButton
                state={
                  activeStep === "SELECT_PRODUCT"
                    ? "CURRENT"
                    : activeStep === "SELECT_PLAN"
                      ? "FUTURE"
                      : "PAST"
                }
                onClick={() => setActiveStep("SELECT_PRODUCT")}
              >
                Audits & Setups
              </StepButton>
              <StepSpacer>
                <IconChevronRight size="16px" />
              </StepSpacer>
            </>
          ) : null}
          <StepButton
            state={activeStep === "CHECKOUT" ? "CURRENT" : "FUTURE"}
            onClick={() => null}
          >
            Checkout
          </StepButton>
        </div>
        <div>
          <ButtonWhiteWithIcon
            variant="SMALL"
            onClick={() => setIsFaqsModalVisible(true)}
          >
            <IconHelp size="16px" />
            <span>FAQs</span>
          </ButtonWhiteWithIcon>
        </div>
      </Wrapper>
      <Modal
        isVisible={isFaqsModalVisible}
        onClose={() => setIsFaqsModalVisible(false)}
      >
        <ModalContents>
          <ModalHeader>
            <ModalTitle>Frequently Asked Questions</ModalTitle>
            <ModalCloseButton onClick={() => setIsFaqsModalVisible(false)}>
              <IconCross size="16px" />
            </ModalCloseButton>
          </ModalHeader>
          <ModalQuestionsWrapper>
            <ModalQuestion>
              <div>
                What happens if I have more orders than what's included in a
                plan?
              </div>
              <div>
                Your tracking will continue working. For every extra order, we
                charge a per order fee (starting at $0.25 & lower depending on
                the plan). If you have 75,000+ orders per month,{" "}
                <LinkExternal href="https://www.getelevar.com/book-call-with-sales/">
                  contact us
                </LinkExternal>
                . Total extra order charges per billing period is limited to
                $10,000.
              </div>
            </ModalQuestion>
            <ModalQuestion>
              <div>What counts as an order?</div>
              <div>
                If you have live server-side marketing destinations (like GA4,
                Facebook, etc.) where Elevar sends your orders to, we will only
                count orders sent to your destinations. If you're on the Starter
                plan and don't have any live server-side destinations, we count
                all orders created in your Shopify Store, capped at a max fee of
                $50.
              </div>
            </ModalQuestion>
            <ModalQuestion>
              <div>How does the free trial work?</div>
              <div>
                The free trial lasts for 15-days and begins immediately after
                the creation of your Elevar Website. During this time you'll get
                access to the features within your plan with unlimited order
                volume.
              </div>
            </ModalQuestion>
            <ModalQuestion>
              <div>What happens at the end of the free trial?</div>
              <div>
                At the end of your 15-day free trial, standard billing applies
                based upon your plan.
              </div>
            </ModalQuestion>
            <ModalQuestion>
              <div>When will I be charged?</div>
              <div>
                You'll start paying your monthly plan subscription after the
                trial period. Extra order fees will be billed at the end of your
                billing period.
              </div>
            </ModalQuestion>
            <ModalQuestion>
              <div>What if I decide to change my plan?</div>
              <div>
                You can change your plan at any time without affecting your free
                trial period.
              </div>
            </ModalQuestion>
          </ModalQuestionsWrapper>
        </ModalContents>
      </Modal>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 2}px;
  }
`;

const StepSpacer = styled.div`
  color: ${props => props.theme.palette.grey4};
`;

const ButtonWhiteWithIcon = styled(ButtonWhite)`
  display: flex;
  align-items: center;
  padding-right: ${props => props.theme.gridBase * 2.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  margin-left: ${props => props.theme.gridBase * 2}px;

  > span {
    margin-left: ${props => props.theme.gridBase}px;
  }
`;

const ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 80}px;
  position: relative;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const ModalCloseButton = styled.button`
  display: flex;
  border-radius: 2px;
  padding: ${props => props.theme.gridBase}px;
  background-color: ${props => props.theme.palette.grey8};
  color: ${props => props.theme.palette.grey3};
  transition: background-color ${props => props.theme.other.transition};

  &:hover {
    background-color: ${props => props.theme.palette.grey6};
  }
`;

const ModalTitle = styled.div`
  ${heading2Styles};
  text-align: center;
`;

const ModalQuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gridBase * 3}px;
`;

const ModalQuestion = styled.div`
  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};

    p:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 0.5}px;
    }

    a {
      ${linkStyles};
    }
  }
`;

/* ========================================================================== */

type StepButtonState = "CURRENT" | "PAST" | "FUTURE";

type StepButtonProps = {
  state: StepButtonState;
  onClick: () => void;
  children: React.ReactNode;
};

const StepButton: React.FC<StepButtonProps> = ({
  state,
  onClick,
  children
}) => {
  return (
    <StepButtonInternal
      state={state}
      onClick={onClick}
      disabled={state !== "PAST"}
    >
      {children}
    </StepButtonInternal>
  );
};

type StepButtonInternalProps = {
  state: StepButtonState;
};

const StepButtonInternal = styled.button<StepButtonInternalProps>`
  ${normalTextStyles};
  cursor: ${props => (props.disabled ? "inherit" : "pointer")};
  color: ${props =>
    (() => {
      switch (props.state) {
        case "CURRENT":
          return props.theme.palette.purple2;
        case "PAST":
        case "FUTURE":
          return props.theme.palette.grey1;
      }
    })()};
`;
