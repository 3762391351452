import { css } from "styled-components";

/* ========================================================================== */

const baseTypographyStyles = css`
  font-family: "Elevar_Inter", Helvetica, Arial, sans-serif;
  font-feature-settings: "cv06" 1;
`;

/* ========================================================================== */

export const heading1Styles = css`
  ${baseTypographyStyles};
  font-weight: 600;
  letter-spacing: 0.019px;
  font-size: ${props => props.theme.fontBase * 1.5}px;
  line-height: ${props => props.theme.gridBase * 4}px;
  color: ${props => props.theme.palette.grey1};
`;

export const heading2Styles = css`
  ${baseTypographyStyles};
  font-weight: 600;
  letter-spacing: 0.017px;
  font-size: ${props => props.theme.fontBase * 1.25}px;
  line-height: ${props => props.theme.gridBase * 3.5}px;
  color: ${props => props.theme.palette.grey1};
`;

export const heading3Styles = css`
  ${baseTypographyStyles};
  font-weight: 600;
  letter-spacing: 0.011px;
  font-size: ${props => props.theme.fontBase}px;
  line-height: ${props => props.theme.gridBase * 3}px;
  color: ${props => props.theme.palette.grey1};
`;

/* ========================================================================== */

export const subheadingStyles = css`
  ${baseTypographyStyles};
  font-weight: 500;
  letter-spacing: 1px;
  font-size: ${props => props.theme.fontBase * 0.75}px;
  line-height: ${props => props.theme.gridBase * 2}px;
  color: ${props => props.theme.palette.grey4};
  text-transform: uppercase;
`;

/* ========================================================================== */

export const largeTextStyles = css`
  ${baseTypographyStyles};
  font-weight: 400;
  letter-spacing: 0.011px;
  font-size: ${props => props.theme.fontBase}px;
  line-height: ${props => props.theme.gridBase * 3}px;
  color: ${props => props.theme.palette.grey1};
`;

export const normalTextStyles = css`
  ${baseTypographyStyles};
  font-weight: 400;
  letter-spacing: 0.007px;
  font-size: ${props => props.theme.fontBase * 0.875}px;
  line-height: ${props => props.theme.gridBase * 2.5}px;
  color: ${props => props.theme.palette.grey1};
`;

export const normalBodyStyles = css`
  ${normalTextStyles};
  line-height: ${props => props.theme.gridBase * 3}px;
`;

export const smallTextStyles = css`
  ${baseTypographyStyles};
  font-weight: 400;
  letter-spacing: 0;
  font-size: ${props => props.theme.fontBase * 0.75}px;
  line-height: ${props => props.theme.gridBase * 2}px;
  color: ${props => props.theme.palette.grey1};
`;
