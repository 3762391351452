import { type Annotation, type ResultSet } from "@cubejs-client/core";
import { type UseQueryResult } from "@tanstack/react-query";
import { isEqual, sortBy } from "lodash-es";
import { useMemo, useState } from "react";
import styled from "styled-components";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tooltip } from "elevar-design-system/src/Tooltip";

import {
  addDefaultFilter,
  ButtonCell,
  Table,
  TablePreHeader,
  type TableRow,
  type TextFilterValue,
  useTableFilters
} from "../../components/Table";

/* ========================================================================== */

type TableColumn = {
  key: string;
  title: Annotation["title"];
  type: "integer" | "text";
  format: Annotation["format"];
};

const tableColumnsWithType = <T,>(resultSet: ResultSet<T> | undefined) => {
  if (!resultSet) return [];

  const annotation = resultSet.annotation();

  const getAnnotation = (field: string) => {
    return (
      annotation.measures[field] ??
      annotation.dimensions[field] ??
      annotation.timeDimensions[field]!
    );
  };

  const { measures, ...rest } = annotation;

  return [
    ...Object.values(rest).flatMap(Object.keys),
    ...Object.keys(measures)
  ].map(
    field =>
      ({
        key: field,
        title: getAnnotation(field).shortTitle,
        type: getAnnotation(field).type === "number" ? "integer" : "text",
        format: getAnnotation(field).format
      }) as const
  );
};

/* ========================================================================== */

type CubeTableProps = {
  queryResult: UseQueryResult<{ resultSet: ResultSet<unknown> }>;
  renderActions?: () => React.ReactNode;
  visibleRows?: number;
  columnMapper?: (column: TableColumn) => TableColumn | null;
  rowMapper?: (row: TableRow) => TableRow;
  defaultFilters?: Parameters<typeof useTableFilters>[0];
  defaultRowSorters?: Parameters<typeof sortBy>[1];
};

export const CubeTable: React.FC<CubeTableProps> = ({
  queryResult,
  renderActions,
  visibleRows,
  columnMapper = c => c,
  rowMapper,
  defaultFilters = [],
  defaultRowSorters
}) => {
  const { filters, updateFilter, resetFilters } =
    useTableFilters(defaultFilters);

  const [filtersOpen, setFiltersOpen] = useState(true);

  const columns = useMemo(
    () => tableColumnsWithType(queryResult.data?.resultSet),
    [queryResult.data?.resultSet]
  );

  const columnsWithFilters = useMemo(() => {
    return columns
      .map(columnMapper)
      .filter(v => v !== null)
      .map(column =>
        addDefaultFilter(
          column,
          filters.find(filter => filter.key === column.key)?.value,
          updateFilter
        )
      )
      .map(column => ({
        ...column,
        ...(column.type === "text"
          ? {
              render: (value: TextFilterValue | null) =>
                value !== null ? (
                  <Tooltip text="Add as Filter" placement="top">
                    <ButtonCell
                      onClick={() => updateFilter(String(column.key), value)}
                    >
                      {value}
                    </ButtonCell>
                  </Tooltip>
                ) : null
            }
          : {})
      }));
  }, [columns, columnMapper, filters, updateFilter]);

  const tableRows = useMemo(() => {
    return (
      queryResult.data?.resultSet
        .tablePivot()
        .map((item, index) => ({ key: index, ...item })) ?? []
    );
  }, [queryResult.data?.resultSet]);

  const defaultSortedTableRows = defaultRowSorters
    ? sortBy(tableRows, defaultRowSorters).reverse()
    : tableRows;

  const defaultSortedMappedTableRows = rowMapper
    ? defaultSortedTableRows.map(rowMapper)
    : defaultSortedTableRows;

  return (
    <div>
      <TablePreHeader
        filtersOpen={filtersOpen}
        filtersChanged={!isEqual(filters, defaultFilters)}
        setFiltersOpen={setFiltersOpen}
        resetFilters={resetFilters}
        renderActions={renderActions}
      />
      <CubeTableWrapper>
        {queryResult.error ? (
          <CenteredWrapper>
            <ErrorOccurred />
          </CenteredWrapper>
        ) : queryResult.data === undefined ? (
          <CenteredWrapper>
            <Spinner size="24px" />
          </CenteredWrapper>
        ) : (
          <Table
            maxVisible={visibleRows}
            filtersOpen={filtersOpen}
            columns={columnsWithFilters}
            rows={defaultSortedMappedTableRows}
          />
        )}
      </CubeTableWrapper>
    </div>
  );
};

const CubeTableWrapper = styled.div`
  position: relative;
`;

const CenteredWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${props => props.theme.gridBase * 20}px;
`;
