import { transparentize } from "polished";
import { useState } from "react";
import styled, { css } from "styled-components";

import { largeTextStyles, normalTextStyles } from "./typography/typography";

/* ========================================================================== */

type OptionType<T> = { name: string; value: T };
type OptionVariant = "SMALL" | "LARGE";

/* ========================================================================== */

type OptionProps<T> = {
  variant: OptionVariant;
  firstOption: OptionType<T>;
  secondOption: OptionType<T>;
  activeOption: OptionType<T>;
  setActiveOption: (activeOption: OptionType<T>) => void;
  disabled?: boolean;
};

export const Option = <T,>({
  variant,
  firstOption,
  secondOption,
  activeOption,
  setActiveOption,
  disabled = false
}: OptionProps<T>): ReturnType<React.FC> => {
  const [firstHovered, setFirstHovered] = useState(false);
  const [secondHovered, setSecondHovered] = useState(false);

  const nonActiveOptionHovered =
    (firstHovered && activeOption !== firstOption) ||
    (secondHovered && activeOption !== secondOption);

  return (
    <OptionWrapper
      nonActiveOptionHovered={nonActiveOptionHovered}
      isDisabled={disabled}
    >
      <OptionButton
        variant={variant}
        isActiveOption={activeOption === firstOption}
        onClick={() => setActiveOption(firstOption)}
        onMouseEnter={() => setFirstHovered(true)}
        onMouseLeave={() => setFirstHovered(false)}
        disabled={disabled}
      >
        {firstOption.name}
      </OptionButton>
      <OptionButton
        variant={variant}
        isActiveOption={activeOption === secondOption}
        onClick={() => setActiveOption(secondOption)}
        onMouseEnter={() => setSecondHovered(true)}
        onMouseLeave={() => setSecondHovered(false)}
        disabled={disabled}
      >
        {secondOption.name}
      </OptionButton>
    </OptionWrapper>
  );
};

/* ========================================================================== */

type OptionWrapperProps = {
  nonActiveOptionHovered: boolean;
  isDisabled: boolean;
};

const OptionWrapper = styled.div<OptionWrapperProps>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  border-radius: 4px;
  transition: background-color ${props => props.theme.other.transition};
  background-color: ${props =>
    props.nonActiveOptionHovered
      ? props.theme.palette.grey7
      : props.theme.palette.grey8};
  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 2;
    background-color: ${props =>
      transparentize(0.4, props.theme.palette.white)};
    visibility: ${props => (props.isDisabled ? "visible" : "hidden")};
    opacity: ${props => (props.isDisabled ? 1 : 0)};
    transition:
      opacity ${props => props.theme.other.transition},
      visibility ${props => props.theme.other.transition};
  }

  ${props =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
    `};
`;

type OptionButtonProps = {
  variant: OptionVariant;
  isActiveOption: boolean;
};

const OptionButton = styled.button<OptionButtonProps>`
  ${props =>
    props.variant === "SMALL" &&
    css`
      ${normalTextStyles};
      padding-top: ${props => props.theme.gridBase * 1.25 - 1}px;
      padding-bottom: ${props => props.theme.gridBase * 1.25 - 1}px;
      padding-left: ${props => props.theme.gridBase * 2 - 1}px;
      padding-right: ${props => props.theme.gridBase * 2 - 1}px;
    `};

  ${props =>
    props.variant === "LARGE" &&
    css`
      ${largeTextStyles};
      padding-top: ${props => props.theme.gridBase * 1.5 - 1}px;
      padding-bottom: ${props => props.theme.gridBase * 1.5 - 1}px;
      padding-left: ${props => props.theme.gridBase * 3 - 1}px;
      padding-right: ${props => props.theme.gridBase * 3 - 1}px;
    `};

  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  transition:
    background-color ${props => props.theme.other.transition},
    border-color ${props => props.theme.other.transition},
    color ${props => props.theme.other.transition};
  border-color: ${props =>
    props.isActiveOption
      ? props.theme.palette.blue1
      : props.theme.palette.grey8};
  background-color: ${props =>
    props.isActiveOption
      ? props.theme.palette.white
      : props.theme.palette.grey8};
  color: ${props =>
    props.isActiveOption
      ? props.theme.palette.blue1
      : props.theme.palette.grey3};

  &:hover {
    border-color: ${props =>
      props.isActiveOption
        ? props.theme.palette.blue1
        : props.theme.palette.grey7};
    background-color: ${props =>
      props.isActiveOption
        ? props.theme.palette.white
        : props.theme.palette.grey7};
  }
`;
