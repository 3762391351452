import { captureException } from "@sentry/browser";
import { camelCase, mapValues, startCase } from "lodash-es";
import { pathToRegexp } from "path-to-regexp";

import {
  type AccountDetails,
  type DerivedEventKey,
  type EventKey as BaseEventKey,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import { type AppEducationSlideshowNode } from "../api/handlers/appEducationSlideshows";
import { type ContainerDetails } from "../api/handlers/containerDetails";
import { type CroIdea } from "../api/handlers/croIdeas";
import { type Product } from "../api/handlers/products";
import { type CancelPlanReason } from "../api/types";
import { type MonitoringTimePeriod } from "../context/MonitoringTimePeriod";
import {
  type OnboardingInfo,
  type OnboardingState
} from "../context/OnboardingDetails";
import { type UserContext } from "../context/User";
import { type Destination } from "../routes/myTracking/data";
import { type ManageStoreOptionQuestionKey } from "../routes/myTracking/MyTracking";
import { type InitialQuestionKey } from "../routes/website/InitialQuestions";
import {
  type AddOn,
  type Plan,
  type Product as ApiProduct
} from "../routes/website/settings/managePlan/shared";
import { pushToElevarDataLayer, pushToGtmDataLayer } from "./dataLayer";
import { segment } from "./segment";
import { isUrl } from "./validate";

/* ========================================================================== */

const urlToHttps = (url: string) => {
  if (url.startsWith("https://")) return url;
  if (url.startsWith("http://")) return url.replace("http://", "https://");
  if (isUrl(url)) return `https://${url}`;
  else throw Error(`Url parameter is not an url: ${url}`);
};

type PathToFeaturePathReturn =
  | { status: "success"; featurePath: string; additionalPath: string }
  | { status: "error" };

const pathToFeaturePath = (path: string): PathToFeaturePathReturn => {
  const pattern = "/company/:companyId/website/:websiteId/:feature?/(.*)?";
  const featurePathRegexp = pathToRegexp(pattern);
  const splitPath = featurePathRegexp.exec(path);

  if (splitPath) {
    const featurePath = splitPath[3]!;
    const additionalPath = splitPath[4]!;
    return { status: "success", featurePath, additionalPath };
  } else {
    return { status: "error" };
  }
};

type EventKey = BaseEventKey | DerivedEventKey;

type StrippedPlan = Pick<Plan, "id" | "name" | "dollarPrice" | "interval">;
type StrippedWebsite = Pick<
  WebsiteDetails,
  "id" | "name" | "url" | "plan" | "has_hubspot_id" | "subscription_type"
>;

type MyTrackingStep = "WELCOME_SCREEN" | OnboardingInfo["step"];

const getCheckoutItems = (args: {
  plan: StrippedPlan;
  addOn: AddOn | null;
  product: ApiProduct | null;
}) => {
  return [
    {
      id: args.plan.id,
      name: args.plan.name,
      brand: "Elevar",
      category: "plan",
      variant: args.plan.name,
      price: args.plan.dollarPrice.toFixed(2),
      quantity: "1",
      list: "",
      product_id: args.plan.id,
      variant_id: args.plan.id,
      image: ""
    },
    ...(args.addOn
      ? [
          {
            id: args.addOn.id,
            name: args.addOn.name,
            brand: "Elevar",
            category: "add-on",
            variant: args.addOn.name,
            price: args.addOn.dollarPrice.toFixed(2),
            quantity: "1",
            list: "",
            product_id: args.addOn.id,
            variant_id: args.addOn.id,
            image: ""
          }
        ]
      : []),
    ...(args.product
      ? [
          {
            id: args.product.id,
            name: args.product.name,
            brand: "Elevar",
            category: "product",
            variant: args.product.name,
            price: args.product.dollarPrice.toFixed(2),
            quantity: "1",
            list: "",
            product_id: args.product.id,
            variant_id: args.product.id,
            image: ""
          }
        ]
      : [])
  ];
};

const handleError =
  <Args extends Array<unknown>>(fn: (...args: Args) => void) =>
  (...args: Args) => {
    if (import.meta.env.DEV) {
      return fn(...args);
    } else {
      try {
        return fn(...args);
      } catch (error) {
        captureException(error);
      }
    }
  };

/* ========================================================================== */

const createTrackInstance = () => {
  let checkoutId: string | null = null;

  const trackObject = {
    error: () => segment.track("Error"),

    pageView: (args: { pathname: string; user: UserContext }) => {
      void window.ElevarInvalidateContext?.();

      pushToElevarDataLayer({
        event: "dl_user_data",
        cart_total: "0.00",
        user_properties: args.user.isLoggedIn
          ? {
              visitor_type: "logged_in",
              customer_id: String(args.user.accountDetails.id),
              customer_email: args.user.accountDetails.email,
              customer_first_name: args.user.accountDetails.first_name,
              customer_last_name: args.user.accountDetails.last_name,
              customer_tags: ""
            }
          : { visitor_type: "guest" },
        ecommerce: { currencyCode: "USD", cart_contents: { products: [] } }
      });

      segment.page();
      const pageviewPath = pathToFeaturePath(args.pathname);

      if (pageviewPath.status === "success" && !pageviewPath.additionalPath) {
        segment.track(`Pageview: ${pageviewPath.featurePath}`);
      }
    },

    userAuth: (accountDetails: AccountDetails) => {
      segment.identify(String(accountDetails.id), () => ({
        firstName: accountDetails.first_name,
        lastName: accountDetails.last_name,
        email: accountDetails.email
      }));
      pushToGtmDataLayer({
        event: "user",
        action: "auth",
        user: { id: accountDetails.id, email: accountDetails.email }
      });
    },

    userGoogleAuthInitiate: () => {
      segment.track("Google OAuth - Login Initiate");
      pushToGtmDataLayer({ event: "user.googleOAuth", action: "initiate" });
    },

    userGoogleAuthSuccess: () => {
      segment.track("Google OAuth - Login Success");
      pushToGtmDataLayer({ event: "user.googleOAuth", action: "success" });
    },

    userLogin: () => {
      segment.track("Signed In");
      pushToGtmDataLayer({ event: "user", action: "login" });
    },

    userLogout: () => {
      segment.track("Signed Out");
      pushToGtmDataLayer({ event: "user", action: "logout" });
    },

    userCreate: (args: { user: AccountDetails }) => {
      segment.identify(String(args.user.id), () => ({
        firstName: args.user.first_name,
        lastName: args.user.last_name,
        email: args.user.email
      }));
      segment.track("Signed Up", args.user);
      pushToGtmDataLayer({
        event: "user",
        action: "create",
        user: { id: args.user.id, email: args.user.email }
      });
    },

    userDelete: () => {
      segment.track("Account Deleted");
      pushToGtmDataLayer({ event: "user", action: "delete" });
    },

    companySelect: (args: { ownerEmail: string }) => {
      pushToGtmDataLayer({
        event: "company",
        action: "select",
        company: { owner_email: args.ownerEmail }
      });
    },

    companyCreate: (args: { name: string }) => {
      segment.track("Company Created", args);
      pushToGtmDataLayer({ event: "company", action: "create", company: args });
    },

    companyMemberAdd: () => {
      segment.track("Company Member Added");
      pushToGtmDataLayer({ event: "company.member", action: "add" });
    },

    websiteView: (args: { userEmail: string; website: StrippedWebsite }) => {
      if (!args.website.has_hubspot_id) {
        try {
          const { name, id } = args.website;
          const websiteUrl = isUrl(name) ? name : `${id}.getelevar.com`;
          segment.group(urlToHttps(websiteUrl), String(id), args.userEmail);
        } catch (error) {
          captureException(error);
        }
      }

      segment.identify(undefined, currentTraits => ({
        ...currentTraits,
        planName: args.website.plan.name,
        subscriptionType: args.website.subscription_type
      }));

      pushToGtmDataLayer({
        website: {
          id: args.website.id,
          name: args.website.name,
          plan_name: args.website.plan.name,
          plan_amount: args.website.plan.amount
        }
      });
    },

    websiteCreate: (args: { name: string }) => {
      segment.track("Website created", args);
      pushToGtmDataLayer({
        event: "website",
        action: "create",
        website: { name: args.name }
      });
    },

    dashboardViewFullReportClick: () => {
      segment.track("Dashboard - View Full Report Click");
      pushToGtmDataLayer({ event: "dashboard.report.fullClick" });
    },

    dashboardViewInReportClick: (args: { name: Destination["name"] }) => {
      segment.track("Dashboard - View in Report Click", args);
      pushToGtmDataLayer({ event: "dashboard.report.inClick" });
    },

    dashboardWhatsNextCtaClick: (args: {
      title: string;
      context: string | null;
    }) => {
      segment.track("Dashboard - Whats Next CTA Click", args);
      pushToGtmDataLayer({ event: "dashboard.whatsNext.ctaClick" });
    },

    dashboardUpdateCtaClick: () => {
      segment.track("Dashboard - Update CTA Click");
      pushToGtmDataLayer({ event: "dashboard.update.ctaClick" });
    },

    connectionsShopifySuccess: (args: { websiteId: number }) => {
      segment.track("Connections - Shopify Success");
      pushToGtmDataLayer({
        event: "connections.shopifySuccess",
        website: { id: args.websiteId }
      });
    },

    connectionsGtmWebSuccess: (args: { websiteId: number }) => {
      segment.track("Connections - GTM (Web) Success");
      pushToGtmDataLayer({
        event: "connections.gtmWebSuccess",
        website: { id: args.websiteId }
      });
    },

    productsExploreCategorySelect: (category: Product["category"]) => {
      segment.track(`Products Explore - Category Select: ${category}`);
      pushToGtmDataLayer({
        event: "products.explore.category",
        action: "select",
        feature: { products: { explore: { category } } }
      });
    },

    productsExploreProductSelect: (product: Product) => {
      segment.track(`Products Explore - Product Select: ${product.name}`);
      pushToGtmDataLayer({
        event: "products.explore.product",
        action: "select",
        feature: { products: { explore: { name: product.name } } }
      });
    },

    featureTipsOpen: (args: AppEducationSlideshowNode) => {
      segment.track(`Feature Tips - Open: ${args.page}`);
      pushToGtmDataLayer({
        event: "appEducationSlideshow",
        action: "open",
        feature: { featureTips: { page: args.page } }
      });
    },

    featureTipsControlsClick: (args: AppEducationSlideshowNode) => {
      segment.track(`Feature Tips - Controls Click: ${args.page}`);
      pushToGtmDataLayer({
        event: "featureTips",
        action: "controlsClick",
        feature: { featureTips: { page: args.page } }
      });
    },

    featureTipsEmbedView: (args: AppEducationSlideshowNode) => {
      segment.track(`Feature Tips - Embed View: ${args.page}`);
      pushToGtmDataLayer({
        event: "featureTips",
        action: "embedView",
        feature: { featureTips: { page: args.page } }
      });
    },

    tableSortClick: () => {
      pushToGtmDataLayer({ event: "table.sort", action: "click" });
    },

    tableFilterShow: () => {
      pushToGtmDataLayer({ event: "table.filter", action: "show" });
    },

    tableFilterHide: () => {
      pushToGtmDataLayer({ event: "table.filter", action: "hide" });
    },

    tabChange: (args: { location: string; tabName: string }) => {
      pushToGtmDataLayer({
        event: "tab",
        action: "change",
        interface: { tab: { location: args.location, tabName: args.tabName } }
      });
    },

    preBuiltTagsListSearch: (args: { query: string }) => {
      segment.track("Pre-Built Tags List - Search", args);
      pushToGtmDataLayer({
        event: "preBuiltTags.list",
        action: "search",
        feature: { preBuiltTags: { query: args.query } }
      });
    },

    preBuiltTagsItemView: (args: ContainerDetails) => {
      segment.track(`Pre-Built Tags Item - View: ${args.name}`, args);
      pushToGtmDataLayer({
        event: "preBuiltTags.item",
        action: "view",
        feature: { preBuiltTags: { name: args.name } }
      });
    },

    preBuiltTagsItemDownload: (args: ContainerDetails) => {
      segment.track("Pre-Built Tags Item - Download");
      segment.track(`Pre-Built Tags Item - Download: ${args.name}`, args);
      pushToGtmDataLayer({
        event: "preBuiltTags.item",
        action: "download",
        feature: { preBuiltTags: { name: args.name } }
      });
    },

    preBuiltTagsItemDownloadWithParameters: (args: ContainerDetails) => {
      segment.track(
        `Pre-Built Tags Item - Download: ${args.name} - With Parameters`,
        args
      );
      pushToGtmDataLayer({
        event: "preBuiltTags.item",
        action: "download.withParameters",
        feature: { preBuiltTags: { name: args.name } }
      });
    },

    preBuiltTagsItemDownloadAgain: (args: ContainerDetails) => {
      segment.track(
        `Pre-Built Tags Item - Download: ${args.name} - Download Again`,
        args
      );
      pushToGtmDataLayer({
        event: "preBuiltTags.item",
        action: "download.again",
        feature: { preBuiltTags: { name: args.name } }
      });
    },

    croIdeasListSort: (args: { sort: string }) => {
      segment.track(`CRO Ideas List - Sort: ${args.sort}`);
      pushToGtmDataLayer({
        event: "croIdeas.list",
        action: "sort",
        feature: { croIdeas: { sort: args.sort } }
      });
    },

    croIdeasListFilter: (args: { filter: string }) => {
      segment.track(`CRO Ideas List - Filter: ${args.filter}`);
      pushToGtmDataLayer({
        event: "croIdeas.list",
        action: "filter",
        feature: { croIdeas: { filter: args.filter } }
      });
    },

    croIdeasItemView: (args: CroIdea) => {
      segment.track(`CRO Ideas Item - View: ${args.title}`);
      pushToGtmDataLayer({
        event: "croIdeas.item",
        action: "view",
        feature: { croIdeas: { title: args.title } }
      });
    },

    croIdeasItemVideoPlay: (args: CroIdea) => {
      segment.track(`CRO Ideas Item - Video Play: ${args.title}`);
      pushToGtmDataLayer({
        event: "croIdeas.item",
        action: "videoPlay",
        feature: { croIdeas: { title: args.title } }
      });
    },

    croIdeasItemSubmitIdea: (args: CroIdea) => {
      segment.track(`CRO Ideas Item - Submit Idea: ${args.title}`);
      pushToGtmDataLayer({
        event: "croIdeas.item",
        action: "submitIdea",
        feature: { croIdeas: { title: args.title } }
      });
    },

    croIdeasItemCalculatorTouch: (args: CroIdea) => {
      segment.track(`CRO Ideas Item - Calculator Touched: ${args.title}`);
      pushToGtmDataLayer({
        event: "croIdeas.item",
        action: "calculatorTouched",
        feature: { croIdeas: { title: args.title } }
      });
    },

    extensionSyncLogin: () => {
      segment.track("Extension Sync - Login");
      pushToGtmDataLayer({ event: "extensionSync.login" });
    },

    shopifyOAuthLogin: () => {
      segment.track("Shopify OAuth - Login");
      pushToGtmDataLayer({ event: "shopifyOAuth.login" });
    },

    shopifyOAuthChoosePlan: () => {
      segment.track("Shopify OAuth - Choose Plan");
      pushToGtmDataLayer({ event: "shopifyOAuth.choosePlan" });
    },

    shopifyOAuthConversion: () => {
      segment.track("Shopify OAuth - Conversion");
      pushToGtmDataLayer({ event: "shopifyOAuth.conversion" });
    },

    destinationGetStarted: (sh: Destination["shorthand"]) => {
      const shU = sh.toUpperCase();
      segment.track(`My Tracking - ${shU} Destination - Get Started`);
      pushToGtmDataLayer({ event: `myTracking.${sh}Destination.getStarted` });
    },

    destinationDownload: (sh: Destination["shorthand"]) => {
      const shU = sh.toUpperCase();
      segment.track(`My Tracking - ${shU} Destination - Download`);
      pushToGtmDataLayer({ event: `myTracking.${sh}Destination.download` });
    },

    destinationGoLive: (sh: Destination["shorthand"]) => {
      const shU = sh.toUpperCase();
      segment.track(`My Tracking - ${shU} Destination - Go Live`);
      pushToGtmDataLayer({ event: `myTracking.${sh}Destination.goLive` });
    },

    destinationGoOffline: (sh: Destination["shorthand"]) => {
      const shU = sh.toUpperCase();
      segment.track(`My Tracking - ${shU} Destination - Go Offline`);
      pushToGtmDataLayer({ event: `myTracking.${sh}Destination.goOffline` });
    },

    destinationRename: (sh: Destination["shorthand"]) => {
      const shU = sh.toUpperCase();
      segment.track(`My Tracking - ${shU} Destination - Rename`);
      pushToGtmDataLayer({ event: `myTracking.${sh}Destination.rename` });
    },

    destinationEventsRecommendedRadioSelect: (args: {
      destinationName: Destination["name"];
      hasStepBeenPreviouslyCompleted: boolean;
    }) => {
      segment.track("Destination Events - Recommended Radio Select", args);
      pushToGtmDataLayer({
        event: "destinationEvents.recommendedRadioSelect",
        feature: { myTracking: { destinationName: args.destinationName } }
      });
    },

    destinationEventsCustomizeRadioSelect: (args: {
      destinationName: Destination["name"];
      hasStepBeenPreviouslyCompleted: boolean;
    }) => {
      segment.track("Destination Events - Customize Radio Select", args);
      pushToGtmDataLayer({
        event: "destinationEvents.customizeRadioSelect",
        feature: { myTracking: { destinationName: args.destinationName } }
      });
    },

    destinationEventsCheckBoxClick: (args: {
      destinationName: Destination["name"];
      eventType: "SERVER" | "WEB";
      eventName: EventKey;
      eventEnabled: boolean;
    }) => {
      segment.track("Destination Events - Check Box Click", args);
      pushToGtmDataLayer({
        event: "destinationEvents.checkBoxClick",
        feature: {
          myTracking: {
            destinationName: args.destinationName,
            event: {
              type: args.eventType,
              name: args.eventName,
              isEnabled: args.eventEnabled
            }
          }
        }
      });
    },

    destinationSpecificMarketsSelect: (args: {
      destinationName: Destination["name"];
      destinationLabel: string | null;
      marketGroupCount: number;
    }) => {
      segment.track("Destination - Specific Markets Select", args);
      pushToGtmDataLayer({ event: "destination.specificMarketsSelect" });
    },

    stepSectionSetupGuideClick: () => {
      segment.track("Step Section - Setup Guide Click");
      pushToGtmDataLayer({ event: "stepSection.setupGuideClick" });
    },

    notificationsEnableDaily: () => {
      segment.track("Notifications - Enable Daily");
      pushToGtmDataLayer({ event: "notifications.enableDaily" });
    },

    notificationsEnableWeekly: () => {
      segment.track("Notifications - Enable Weekly");
      pushToGtmDataLayer({ event: "notifications.enableWeekly" });
    },

    notificationsEnableRealTime: () => {
      segment.track("Notifications - Enable Real-time");
      pushToGtmDataLayer({ event: "notifications.enableRealTime" });
    },

    notificationsDisableDaily: () => {
      segment.track("Notifications - Disable Daily");
      pushToGtmDataLayer({ event: "notifications.disableDaily" });
    },

    notificationsDisableWeekly: () => {
      segment.track("Notifications - Disable Weekly");
      pushToGtmDataLayer({ event: "notifications.disableWeekly" });
    },

    notificationsDisableRealTime: () => {
      segment.track("Notifications - Disable Real-time");
      pushToGtmDataLayer({ event: "notifications.disableRealTime" });
    },

    conversionAccuracyTargetUpdate: () => {
      segment.track("Channel Accuracy - Target Update");
      pushToGtmDataLayer({ event: "channelAccuracy.targetUpdate" });
    },

    attributionFeedExport: () => {
      segment.track("Attribution Feed - Export");
      pushToGtmDataLayer({ event: "attributionFeed.export" });
    },

    initialQuestionsSet: (key: InitialQuestionKey, isNewUser: boolean) => {
      segment.track(`Initial Questions - Set ${startCase(key)}`, { isNewUser });
      pushToGtmDataLayer({ event: `initialQuestions.set${camelCase(key)}` });
    },

    initialQuestionsServerExplainerToggle: () => {
      segment.track("Initial Questions - Server Explainer Toggle");
      pushToGtmDataLayer({ event: "initialQuestions.serverExplainerToggle" });
    },

    initialQuestionsServerExplainerLinkClick: () => {
      segment.track("Initial Questions - Server Explainer Link Click");
      pushToGtmDataLayer({
        event: "initialQuestions.serverExplainerLinkClick"
      });
    },

    contactCalloutExternalLinkClick: () => {
      segment.track("Contact Callout - External Link Click");
      pushToGtmDataLayer({ event: "contactCallout.externalLinkClick" });
    },

    managePlanEnterprisePlansLinkClick: (args: {
      onboardingState: OnboardingState["name"];
    }) => {
      segment.track("Manage Plan - Enterpise Plans Link Click", args);
      pushToGtmDataLayer({
        event: "managePlan.enterprisePlansLinkClick",
        website: { onboarding_state: args.onboardingState }
      });
    },

    managePlanPlanSelected: (args: {
      plan: StrippedPlan;
      onboardingState: OnboardingState["name"];
    }) => {
      segment.track("Manage Plan - Plan Selected", args);
      pushToGtmDataLayer({
        event: "managePlan.planSelected",
        website: { onboarding_state: args.onboardingState },
        interface: { plan: { name: args.plan.name } }
      });
    },

    managePlanBeginCheckout: (args: {
      plan: StrippedPlan;
      user: Extract<UserContext, { isLoggedIn: true }>;
    }) => {
      checkoutId = crypto.randomUUID();

      pushToElevarDataLayer({
        event: "dl_begin_checkout",
        event_id: `dl_begin_checkout_${checkoutId}`,
        user_properties: {
          visitor_type: "logged_in",
          customer_id: String(args.user.accountDetails.id),
          customer_email: args.user.accountDetails.email,
          customer_first_name: args.user.accountDetails.first_name,
          customer_last_name: args.user.accountDetails.last_name
        },
        ecommerce: {
          currencyCode: "USD",
          checkout: {
            actionField: { step: "1" },
            products: [
              {
                id: args.plan.id,
                name: args.plan.name,
                brand: "Elevar",
                category: "Plan",
                variant: args.plan.name,
                price: args.plan.dollarPrice.toFixed(2),
                quantity: "1",
                list: "",
                product_id: args.plan.id,
                variant_id: args.plan.id,
                image: ""
              }
            ]
          }
        }
      });
    },

    managePlanInitialProductsView: (isNewUser: boolean) => {
      segment.track("Manage Plan - Initial Products View", { isNewUser });
      pushToGtmDataLayer({ event: "managePlan.initialProductsView" });
    },

    managePlanInitialCheckoutView: (isNewUser: boolean) => {
      segment.track("Manage Plan - Initial Checkout View", { isNewUser });
      pushToGtmDataLayer({ event: "managePlan.initialCheckoutView" });
    },

    managePlanAddPaymentInfo: (args: {
      plan: StrippedPlan;
      addOn: AddOn | null;
      product: ApiProduct | null;
      user: Extract<UserContext, { isLoggedIn: true }>;
    }) => {
      if (!checkoutId) throw new Error("Checkout ID not already set");

      pushToElevarDataLayer({
        event: "dl_add_payment_info",
        event_id: `dl_add_payment_info_${checkoutId}`,
        user_properties: {
          visitor_type: "logged_in",
          customer_id: String(args.user.accountDetails.id),
          customer_email: args.user.accountDetails.email,
          customer_first_name: args.user.accountDetails.first_name,
          customer_last_name: args.user.accountDetails.last_name
        },
        ecommerce: {
          currencyCode: "USD",
          checkout: {
            actionField: { step: "2" },
            products: getCheckoutItems(args)
          }
        }
      });
    },

    managePlanAddOnButtonClick: (args: {
      onboardingState: OnboardingState["name"];
    }) => {
      segment.track("Manage Plan - AddOn Button Click", args);
      pushToGtmDataLayer({
        event: "managePlan.addOnButtonClick",
        website: { onboarding_state: args.onboardingState }
      });
    },

    managePlanProductButtonClick: () => {
      segment.track("Manage Plan - Product Button Click");
      pushToGtmDataLayer({ event: "managePlan.productButtonClick" });
    },

    managePlanProductDescriptionToggle: (name: string) => {
      segment.track("Manage Plan - Product Description Toggle", { name });
      pushToGtmDataLayer({
        event: "managePlan.productDescriptionToggle",
        interface: { product: { name } }
      });
    },

    managePlanCheckoutComplete: (args: {
      oldPlan: StrippedPlan | null;
      newPlan: StrippedPlan;
      selectedAddOn: AddOn | null;
      selectedProduct: ApiProduct | null;
      user: Extract<UserContext, { isLoggedIn: true }>;
      websiteId: number;
    }) => {
      if (args.oldPlan === null) {
        segment.track("Manage Plan - Initial Checkout Complete", {
          plan: args.newPlan
        });
      } else {
        segment.track("Manage Plan - Subsequent Checkout Complete", {
          oldPlan: args.oldPlan,
          newPlan: args.newPlan
        });
      }

      if (!checkoutId) throw new Error("Checkout ID not already set");

      const items = getCheckoutItems({
        plan: args.newPlan,
        addOn: args.selectedAddOn,
        product: args.selectedProduct
      });

      const revenue = (
        args.newPlan.dollarPrice +
        (args.selectedAddOn?.dollarPrice ?? 0) +
        (args.selectedProduct?.dollarPrice ?? 0)
      ).toFixed(2);

      pushToElevarDataLayer({
        event: "dl_purchase",
        user_properties: {
          visitor_type: "logged_in",
          customer_id: String(args.user.accountDetails.id),
          customer_email: args.user.accountDetails.email,
          customer_first_name: args.user.accountDetails.first_name,
          customer_last_name: args.user.accountDetails.last_name
        },
        ecommerce: {
          currencyCode: "USD",
          purchase: {
            actionField: {
              id: `${args.websiteId}_${checkoutId}`,
              revenue,
              tax: "0.00",
              shipping: "0.00",
              sub_total: revenue,
              product_sub_total: revenue,
              discount_amount: "0.00"
            },
            products: items.map((item, index) => ({
              ...item,
              position: String(index)
            }))
          }
        },
        marketing: { landing_site: "" }
      });
    },

    managePlanRecommendedProductNotPurchased: (args: {
      recommendedProductName: string;
      purchasedProductName: string | null;
    }) => {
      segment.track("Manage Plan - Recommended Product Not Purchased", args);
      pushToGtmDataLayer({
        event: "managePlan.recommendedProductNotPurchased"
      });
    },

    productPurchase: (args: {
      productName: string;
      onboardingState: OnboardingState["name"];
    }) => {
      segment.track("Product - Purchase", args);
      pushToGtmDataLayer({
        event: "product.purchase",
        website: { onboarding_state: args.onboardingState },
        interface: { product: { name: args.productName } }
      });
    },

    cancelPlanReasonSelect: (args: { reason: CancelPlanReason }) => {
      segment.track(`Cancel Plan - Reason Select: ${args.reason}`);
      pushToGtmDataLayer({ event: "cancelPlan.reasonSelect", website: args });
    },

    cancelPlanComplete: () => {
      segment.track("Cancel Plan - Complete");
      pushToGtmDataLayer({ event: "cancelPlan.complete" });
    },

    myTrackingExploreProductsLinkClick: (step: MyTrackingStep) => {
      segment.track("My Tracking - Explore Products Link Click", { step });
      pushToGtmDataLayer({
        event: "myTracking.exploreProductsLinkClick",
        feature: { myTracking: { onboardingStep: step } }
      });
    },

    myTrackingCreateSupportTicketLinkClick: (step: MyTrackingStep) => {
      segment.track("My Tracking - Create Support Ticket Link Click", { step });
      pushToGtmDataLayer({
        event: "myTracking.createSupportTicketLinkClick",
        feature: { myTracking: { onboardingStep: step } }
      });
    },

    myTrackingHowElevarWorksVideoPlay: (step: MyTrackingStep) => {
      segment.track("My Tracking - How Elevar Works Video Play", { step });
      pushToGtmDataLayer({
        event: "myTracking.howElevarWorksVideoPlay",
        feature: { myTracking: { onboardingStep: step } }
      });
    },

    myTrackingKnowledgeBaseLinkClick: (step: MyTrackingStep) => {
      segment.track("My Tracking - Knowledge Base Link Click", { step });
      pushToGtmDataLayer({
        event: "myTracking.knowledgeBaseLinkClick",
        feature: { myTracking: { onboardingStep: step } }
      });
    },

    myTrackingHowElevarWorksModalOpen: (step: MyTrackingStep) => {
      segment.track("My Tracking - How Elevar Works Modal Open", { step });
      pushToGtmDataLayer({
        event: "myTracking.howElevarWorksModalOpen",
        feature: { myTracking: { onboardingStep: step } }
      });
    },

    myTrackingAddDestinationButtonClick: () => {
      segment.track("Manage Plan - Add Destination Button Click");
      pushToGtmDataLayer({ event: "managePlan.addDestinationButtonClick" });
    },

    myTrackingDestinationNextStepButtonClick: () => {
      segment.track("Manage Plan - Destination Next Step Button Click");
      pushToGtmDataLayer({
        event: "managePlan.destinationNextStepButtonClick"
      });
    },

    manageStoreOptionsGuideLinkClick: (args: {
      question: ManageStoreOptionQuestionKey;
    }) => {
      segment.track("Manage Store Options - Guide Link Click", args);
      pushToGtmDataLayer({ event: "manageStoreOptions.guideLinkClick" });
    },

    manageStoreOptionsMarkAsComplete: (args: {
      question: ManageStoreOptionQuestionKey;
      answer: boolean;
    }) => {
      segment.track("Manage Store Options - Mark as Complete", args);
      pushToGtmDataLayer({ event: "manageStoreOptions.markAsComplete" });
    },

    webContainerSetupVideoPlay: () => {
      segment.track("Web Container Setup - Video Play");
      pushToGtmDataLayer({ event: "webContainerSetup.videoPlay" });
    },

    webContainerSetupImportLinkClick: () => {
      segment.track("Web Container Setup - Import Link Click");
      pushToGtmDataLayer({ event: "webContainerSetup.importLinkClick" });
    },

    webContainerSetupPublishLinkClick: () => {
      segment.track("Web Container Setup - Publish Link Click");
      pushToGtmDataLayer({ event: "webContainerSetup.publishLinkClick" });
    },

    webContainerSetupDownloadButtonClick: () => {
      segment.track("Web Container Setup - Download Button Click");
      pushToGtmDataLayer({ event: "webContainerSetup.downloadButtonClick" });
    },

    removePreviousTrackingVideoPlay: () => {
      segment.track("Remove Previous Tracking - Video Play");
      pushToGtmDataLayer({ event: "removePreviousTracking.videoPlay" });
    },

    removePreviousTrackingGuideLinkClick: () => {
      segment.track("Remove Previous Tracking - Guide Link Click");
      pushToGtmDataLayer({ event: "removePreviousTracking.guideLinkClick" });
    },

    removePreviousTrackingMarkAsComplete: () => {
      segment.track("Remove Previous Tracking - Mark As Complete");
      pushToGtmDataLayer({ event: "removePreviousTracking.markAsComplete" });
    },

    shopifyEnableMarkets: (args: { marketGroupCount: number }) => {
      segment.track("Shopify - Enable Markets", { args });
      pushToGtmDataLayer({ event: "shopify.enableMarkets" });
    },

    shopifyGoToClick: (args: { state: "success" | "error" }) => {
      segment.track("Shopify - Go to Shopify", args);
      pushToGtmDataLayer({
        event: "shopify.goToShopify",
        action: "click",
        interface: { state: args.state }
      });
    },

    shopifyTryAgain: (args: { state: "success" | "error" }) => {
      segment.track("Shopify - Try Again", args);
      pushToGtmDataLayer({
        event: "shopify.tryAgain",
        action: "click",
        interface: { state: args.state }
      });
    },

    shopifyDownloadLegacySnippets: () => {
      segment.track("Shopify - Download Legacy Snippets");
      pushToGtmDataLayer({ event: "shopify.downloadLegacySnippets" });
    },

    nonShopifySubdomainsMarkAsComplete: () => {
      segment.track("Non-Shopify Subdomains - Mark as Complete");
      pushToGtmDataLayer({ event: "nonShopifySubdomains.markAsComplete" });
    },

    crossSellLinkClick: () => {
      segment.track("Cross-Sell Link - Click");
      pushToGtmDataLayer({ event: "crossSellLink.click" });
    },

    monitoringTimePeriodChange: (args: { period: MonitoringTimePeriod }) => {
      segment.track("Monitoring Time Period - Change", args);
      pushToGtmDataLayer({ event: "crossSellLink.click" });
    },

    noneExplainerMyTrackingCtaClick: () => {
      segment.track("None Explainer - My Tracking CTA Click");
      pushToGtmDataLayer({ event: "noneExplainer.myTracking.ctaClick" });
    },

    noneExplainerSupportCtaClick: () => {
      segment.track("None Explainer - Support CTA Click");
      pushToGtmDataLayer({ event: "noneExplainer.support.ctaClick" });
    }
  } as const;

  return mapValues(trackObject, handleError) as unknown as typeof trackObject;
};

export const track = createTrackInstance();
