import { produce } from "immer";
import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonPrimaryAsLink,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconCheckMark,
  IconCircledInfo,
  IconCircledPlus,
  IconClipboard,
  IconCross,
  IconKey
} from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import {
  fetchShopifyApexDomain,
  useCustomPagesSnippetQuery,
  useShopifyShopDetailsMutation
} from "../../api/handlers/shopify";
import {
  rotateSigningKey,
  useEventsConnectorConfigQuery,
  useWebsiteOnboardingQuestionsMutation
} from "../../api/handlers/website";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import {
  type MyTrackingDetailsCustomPagesSource,
  useMyTrackingDetails
} from "../../context/MyTrackingDetails";
import { useOnboardingDetails } from "../../context/OnboardingDetails";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { toast } from "../../utils/toast";
import { track } from "../../utils/track";
import { ConfigSummary } from "./ConfigSummary";
import { sourceCustomPages } from "./data";

/* ========================================================================== */

type SourceCustomPagesProps = {
  isCompanyAdmin: boolean;
  apexDomains: Array<string>;
};

export const SourceCustomPages: React.FC<SourceCustomPagesProps> = ({
  isCompanyAdmin,
  apexDomains
}) => {
  const eventsConnectorConfig = useEventsConnectorConfigQuery();

  if (eventsConnectorConfig.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (eventsConnectorConfig.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <SourceCustomPagesInner
      isCompanyAdmin={isCompanyAdmin}
      apexDomains={apexDomains}
      eventsConnectorConfig={eventsConnectorConfig.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type SetupFlowContext = {
  apexDomains: Array<string>;
  sourceDetails: MyTrackingDetailsCustomPagesSource;
};

const { SetupFlow, useSetupFlowDetails, useSetupFlowContext } =
  createSetupFlow<SetupFlowContext>().source(sourceCustomPages);

type SourceCustomPagesInnerProps = {
  isCompanyAdmin: boolean;
  apexDomains: Array<string>;
  eventsConnectorConfig: EventsConnectorConfig;
};

const SourceCustomPagesInner: React.FC<SourceCustomPagesInnerProps> = ({
  isCompanyAdmin,
  apexDomains,
  eventsConnectorConfig
}) => {
  const { sourceInfo } = useMyTrackingDetails();

  const { mutateAsync: shopifyShopDetailsMutation } =
    useShopifyShopDetailsMutation();
  const { mutateAsync: websiteOnboardingQuestionsMutation } =
    useWebsiteOnboardingQuestionsMutation();

  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      eventsConnectorConfig={eventsConnectorConfig}
      steps={[
        { details: { type: "CUSTOM", name: "Domain Details" } },
        {
          details: { type: "CUSTOM", name: "Prepare Your Pages" },
          enforceOnUpgradeOrUpdate: true
        }
      ]}
      context={{ apexDomains, sourceDetails: sourceInfo.customPages! }}
      onRemove={async () => {
        await Promise.all([
          shopifyShopDetailsMutation({ apex_domains: [] }),
          websiteOnboardingQuestionsMutation({ has_custom_pages: false })
        ]);
      }}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  switch (setupFlow.currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
  }
};

/* ========================================================================== */

const Step0: React.FC = () => {
  const { sourceDetails } = useSetupFlowContext();

  if (sourceDetails.state === "SETUP") {
    return <Step0Setup />;
  } else {
    return <Step0NotSetup />;
  }
};

/* -------------------------------------------------------------------------- */

const Step0Setup: React.FC = () => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const setupFlow = useSetupFlowDetails();
  const { apexDomains } = useSetupFlowContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isTokenModalShown, setIsTokenModalShown] = useState(false);

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;

  return (
    <>
      <PageCard>
        <Step0SetupHeader>
          <div>
            <div>
              <IconCheckMark size="24px" color={theme.palette.green} />
            </div>
            <div>Marked as Complete</div>
          </div>
          <div>You've completed the required steps to set up this source.</div>
        </Step0SetupHeader>
        <Step0SetupMainContent>
          <ConfigSummary
            isLoading={isLoading}
            initialIsOpen={false}
            setCurrentStep={setupFlow.setCurrentStep}
            items={[
              {
                step: 1,
                type: "CUSTOM",
                render: () =>
                  apexDomains.length === 1 ? (
                    <>
                      Your Overarching Domain is: <span>{apexDomains[0]}</span>
                    </>
                  ) : (
                    <>
                      Your Overarching Domains are:{" "}
                      <span>
                        {apexDomains.slice(0, -1).join(", ")} &{" "}
                        {apexDomains.at(-1)}
                      </span>
                    </>
                  )
              }
            ]}
          />
        </Step0SetupMainContent>
        <Step0SetupFooter>
          <ButtonPrimaryAsLink variant="SMALL" to={myTrackingUrl}>
            Go to My Tracking
          </ButtonPrimaryAsLink>
          <Step0SetupTokenButton onClick={() => setIsTokenModalShown(true)}>
            <div>
              <IconKey size="24px" />
            </div>
            <div>Generate New Access Token</div>
          </Step0SetupTokenButton>
        </Step0SetupFooter>
      </PageCard>
      <Modal
        isVisible={isTokenModalShown}
        onClose={() => setIsTokenModalShown(false)}
        disallowClose={isLoading}
      >
        <ModalContents>
          <ModalTitle>Generate New Access Token</ModalTitle>
          <ModalBody>
            By continuing, a new access token will be created to send your
            events to Elevar, and your old access token will be invalidated
            shortly after. Only do this if Elevar's support team has advised you
            to.
          </ModalBody>
          <ModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setIsTokenModalShown(false)}
            >
              Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                setIsLoading(true);
                await rotateSigningKey({ websiteId });
                toast.success("New access token generated");
                setIsTokenModalShown(false);
                setIsLoading(false);
              }}
            >
              Generate New Token
            </ButtonPrimary>
          </ModalButtons>
        </ModalContents>
      </Modal>
    </>
  );
};

const Step0SetupHeader = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 2.5}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey7};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    display: flex;
    gap: ${props => props.theme.gridBase}px;
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;

    > div:last-child {
      ${heading3Styles};
    }
  }

  > div:last-child {
    ${normalBodyStyles};
  }
`;

const Step0SetupMainContent = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey7};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const Step0SetupFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Step0SetupTokenButton = styled.button`
  ${iconTextButtonStyles};
`;

const ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 45}px;
  position: relative;
`;

const ModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > a {
    ${linkStyles};
  }
`;

const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* -------------------------------------------------------------------------- */

const Step0NotSetup: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageCard>
      <Step0NotSetupHeader>
        <div>Overview</div>
        <div>
          Set up this source to know shoppers' entire journeys by capturing
          attribution data from non-Shopify subdomains. E.g. a WordPress blog,
          third-party landing pages, or a headless website.
        </div>
      </Step0NotSetupHeader>
      <Step0NotSetupIntegrationNotes>
        <div>
          <div>
            <IconCircledInfo size="16px" />
          </div>
          <div>Integration Notes</div>
        </div>
        <div>
          <p>
            You will still need to build a custom data layer for headless
            websites.{" "}
            <LinkExternal href="https://docs.getelevar.com/docs/headless-overview">
              How do I do this?
            </LinkExternal>
          </p>
          <p>
            This will not work for cross-domain tracking. For instance, if your
            subdomain is blog.website.com and your e-commerce URL is
            shopwebsite.com.
          </p>
        </div>
      </Step0NotSetupIntegrationNotes>
      {!setupFlow.isStepCompleted ? (
        <Step0NotSetupButton
          variant="SMALL"
          state={isLoading ? "LOADING" : "IDLE"}
          onClick={async () => {
            setIsLoading(true);
            await setupFlow.configMutation({});
          }}
        >
          Get Started
        </Step0NotSetupButton>
      ) : null}
    </PageCard>
  );
};

const Step0NotSetupHeader = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey6};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};
  }
`;

const Step0NotSetupIntegrationNotes = styled.div`
  > div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: ${props => props.theme.gridBase}px;

    > div:first-child {
      display: flex;
      color: ${props => props.theme.palette.orange};
      margin-right: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      font-weight: 500;
    }
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};

    p:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    a {
      ${linkStyles};
    }
  }
`;

const Step0NotSetupButton = styled(ButtonPrimary)`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const websiteId = useWebsiteId();
  const setupFlow = useSetupFlowDetails();
  const { apexDomains: initialApexDomains } = useSetupFlowContext();

  const { mutateAsync: shopifyShopDetailsMutation } =
    useShopifyShopDetailsMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [apexDomains, setApexDomains] = useState(initialApexDomains);

  useEffect(() => {
    const effectHandler = async () => {
      if (apexDomains.length === 0) {
        setApexDomains([await fetchShopifyApexDomain({ websiteId })]);
      }
    };
    void effectHandler();
  }, [apexDomains, websiteId]);

  return (
    <PageCard>
      <Step1Header>
        <div>Domain Details</div>
      </Step1Header>
      <Step1Explainer>
        <p>Please provide the overarching domain(s) of your website.</p>
        <p>
          Note: this will not work for cross-domain tracking. For instance, if
          your subdomain is <span>blog.website.com</span> and your e-commerce
          URL is <span>shopwebsite.com</span>. When adding multiple domains, the
          attribution information will be shared across subdomains, not across
          domains.
        </p>
      </Step1Explainer>
      <Step1InputsWrapper>
        <Step1InputWrapper
          labelText="Overarching Domain(s)"
          disabled={isLoading}
          tooltip={{
            maxWidth: `${theme.gridBase * 52.5}px`,
            render: () => (
              <Step1InputWrapperTooltipContent>
                <p>
                  For instance, if your subdomain is{" "}
                  <span>blog.website.com</span>, your overarching domain is{" "}
                  <span>website.com</span>. We will automatically reformat to
                  exclude http, https, & subdomains.
                </p>
                <StyledLinkExternal
                  href="https://docs.getelevar.com/docs/how-to-find-main-overarching-domain"
                  text="Where do I find this?"
                />
              </Step1InputWrapperTooltipContent>
            )
          }}
        >
          <InputFieldText
            variant="SMALL"
            disabled={apexDomains.length === 0 || isLoading}
            value={apexDomains[0] ?? ""}
            onChange={event => {
              setApexDomains(
                produce(apexDomains, draft => {
                  draft[0] = event.target.value;
                })
              );
            }}
            placeholder={
              apexDomains.length === 0 ? "Loading..." : "website.com"
            }
            spellCheck={false}
            autoCapitalize="off"
          />
        </Step1InputWrapper>
        {apexDomains.length > 1
          ? apexDomains.slice(1).map((domain, index) => (
              <Step1RemovalInputWrapper key={index}>
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={domain}
                  onChange={event => {
                    setApexDomains(
                      produce(apexDomains, draft => {
                        draft[index + 1] = event.target.value;
                      })
                    );
                  }}
                  placeholder={`website${index + 2}.com`}
                  spellCheck={false}
                  autoCapitalize="off"
                />
                <button
                  onClick={() => {
                    setApexDomains(apexDomains.toSpliced(index + 1, 1));
                  }}
                >
                  <IconCross size="16px" />
                </button>
              </Step1RemovalInputWrapper>
            ))
          : null}
        <Step1AddAnotherButton
          onClick={() => setApexDomains([...apexDomains, ""])}
        >
          <div>
            <IconCircledPlus size="24px" />
          </div>
          <div>Add Another</div>
        </Step1AddAnotherButton>
      </Step1InputsWrapper>
      <Step1ButtonWrapper>
        <ButtonPrimary
          variant="SMALL"
          state={
            isLoading
              ? "LOADING"
              : apexDomains.length === 0 ||
                  apexDomains.some(apexDomain => apexDomain.length === 0)
                ? "DISABLED"
                : "IDLE"
          }
          onClick={async () => {
            setIsLoading(true);

            try {
              const result = await shopifyShopDetailsMutation({
                apex_domains: apexDomains
              });

              if (setupFlow.isStepCompleted) {
                toast.success("Source updated");
              } else {
                await setupFlow.configMutation({});
              }

              setApexDomains(result.apex_domains);
            } catch {
              toast.errorExpected(
                "You've entered an invalid domain. Please check your domains for spelling errors"
              );
            } finally {
              setIsLoading(false);
            }
          }}
        >
          {setupFlow.isStepCompleted ? "Save" : "Save & Continue"}
        </ButtonPrimary>
      </Step1ButtonWrapper>
    </PageCard>
  );
};

const Step1Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.gridBase}px;

  > div:first-child {
    ${heading3Styles};
  }
`;

const Step1Explainer = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;

  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};

    &:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > a {
      ${linkStyles};
    }

    > span {
      font-weight: 500;
      color: ${props => props.theme.palette.grey1};
    }
  }
`;

const Step1InputsWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const Step1InputWrapper = styled(InputWrapper)`
  max-width: ${props => props.theme.gridBase * 40}px;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step1RemovalInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
  max-width: ${props => props.theme.gridBase * 44}px;
  margin-bottom: ${props => props.theme.gridBase}px;

  > button {
    ${iconButtonStyles};
  }
`;

const Step1AddAnotherButton = styled.button`
  ${iconTextButtonStyles};
  margin-top: ${props => props.theme.gridBase * 1.5}px;
`;

const Step1ButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const { questions } = useOnboardingDetails();
  const setupFlow = useSetupFlowDetails();
  const { sourceDetails } = useSetupFlowContext();

  const { mutateAsync: websiteOnboardingQuestionsMutation } =
    useWebsiteOnboardingQuestionsMutation();

  const customPagesSnippet = useCustomPagesSnippetQuery();

  const [isLoading, setIsLoading] = useState(false);

  const customPagesTemplate =
    customPagesSnippet.data && "template" in customPagesSnippet.data
      ? customPagesSnippet.data.template.trim()
      : null;

  return (
    <PageCard>
      {sourceDetails.state === "UPGRADE_REQUIRED" ? (
        <Step2UpgradeInfo>
          <div>Welcome to the {sourceCustomPages.name} Source!</div>
          <div>
            <p>
              You had the "Non-Shopify Attribution Tracking" step of the old
              Data Layer source setup, so as a part of upgrading, you'll likely
              need to upgrade to the new "{sourceCustomPages.name}" source.
            </p>
            <p>
              If you still have non-shopify subdomains that need tracking,
              please continue with the upgrade process to ensure your tracking
              continues working correctly. Otherwise, you can remove this source
              and ignore this part of the upgrade process via the "..." menu in
              the top right of the page.
            </p>
            <p>
              <span>Note:</span> If you're headless, or sending your own data
              layer events or additional parameters to data layer events, you'll
              need to update those customizations to be compatible with the new
              source.{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/how-to-upgrade-data-layer-customizations-to-be-compatible-with-new-shopify-source">
                Learn more
              </LinkExternal>
              .
            </p>
          </div>
        </Step2UpgradeInfo>
      ) : null}
      <Step2Heading>Configure Pages</Step2Heading>
      <Step2List>
        <Step2ListItem>
          <Step2Text>
            <span>1.</span> Copy the code below:
          </Step2Text>
          <Step2InputFieldTextArea
            variant="SMALL"
            disabled={customPagesTemplate === null}
            value={customPagesTemplate ?? "Loading..."}
            spellCheck={false}
            autoCapitalize="off"
            readOnly={true}
          />
          <Step2IconButton
            variant="SMALL"
            state={customPagesTemplate === null ? "DISABLED" : "IDLE"}
            onClick={async () => {
              if (customPagesTemplate !== null) {
                await navigator.clipboard.writeText(customPagesTemplate);
                toast.info("Code copied to clipboard");
              }
            }}
          >
            <div>
              <IconClipboard size="24px" />
            </div>
            <div>Copy to Clipboard</div>
          </Step2IconButton>
        </Step2ListItem>
        <Step2ListItem>
          <Step2Text>
            <span>2.</span> Open your subdomain codebase.
          </Step2Text>
        </Step2ListItem>
        <Step2ListItem>
          <Step2Text>
            <span>3.</span> Paste the code from above into the &lt;head&gt;
            section on every non-shopify subdomain page that you want to track
            (e.g. the header of your wordpress blog or a non-shopify landing
            page).
          </Step2Text>
        </Step2ListItem>
        <Step2ListItem>
          <Step2Text>
            <span>4.</span> Be sure to save your changes!
          </Step2Text>
        </Step2ListItem>
      </Step2List>
      {!setupFlow.isStepCompleted ? (
        <Step2FinishButtonWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsLoading(true);
              await setupFlow.configMutation({});

              if (!questions.has_custom_pages) {
                await websiteOnboardingQuestionsMutation({
                  has_custom_pages: true
                });
              }

              track.nonShopifySubdomainsMarkAsComplete();
            }}
          >
            Mark as Complete
          </ButtonPrimary>
        </Step2FinishButtonWrapper>
      ) : null}
    </PageCard>
  );
};

const Step2UpgradeInfo = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey6};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    > p {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase}px;
      }

      > span {
        font-weight: 500;
        color: ${props => props.theme.palette.grey1};
      }

      > a {
        ${linkStyles};
      }
    }
  }
`;

const Step2Heading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step2List = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const Step2ListItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2.5}px;
  }

  > :not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

const Step2Text = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > span {
    color: ${props => props.theme.palette.grey1};
    font-weight: 500;
  }

  > a {
    ${linkStyles};
  }
`;

const Step2InputFieldTextArea = styled(InputFieldTextArea)`
  white-space: pre;
`;

const Step2IconButton = styled(ButtonPrimary)`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  margin-top: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    display: flex;
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

const Step2FinishButtonWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;
