import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconCheckMark } from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { PageCard } from "../../../../components/PageCard";

/* ========================================================================== */

type SaveButtonClickParams = { newHowDidYouHearAboutUs: string };

type PageCardReferralProps = {
  isExpanded: boolean;
  globalHowDidYouHearAboutUs: string;
  onSaveButtonClick: (params: SaveButtonClickParams) => void;
  onEditButtonClick: () => void;
};

export const PageCardReferral: React.FC<PageCardReferralProps> = ({
  isExpanded,
  globalHowDidYouHearAboutUs,
  onSaveButtonClick,
  onEditButtonClick
}) => {
  if (isExpanded) {
    return (
      <PageCardReferralExpanded
        globalHowDidYouHearAboutUs={globalHowDidYouHearAboutUs}
        onSaveButtonClick={onSaveButtonClick}
      />
    );
  } else if (globalHowDidYouHearAboutUs) {
    return (
      <PageCardReferralCollapsed
        globalHowDidYouHearAboutUs={globalHowDidYouHearAboutUs}
        onEditButtonClick={onEditButtonClick}
      />
    );
  } else {
    return null;
  }
};

/* ========================================================================== */

type PageCardReferralExpandedProps = {
  globalHowDidYouHearAboutUs: string;
  onSaveButtonClick: (params: SaveButtonClickParams) => void;
};

const PageCardReferralExpanded: React.FC<PageCardReferralExpandedProps> = ({
  globalHowDidYouHearAboutUs,
  onSaveButtonClick
}) => {
  const [localHowDidYouHearAboutUs, setLocalHowDidYouHearAboutUs] = useState(
    globalHowDidYouHearAboutUs
  );

  const enableSaveButton = localHowDidYouHearAboutUs !== "";

  return (
    <PageCardReferralOpenWrapper>
      <div>Enter Referral Info</div>
      <PageCardReferralInputWrapper labelText="How Did You Hear About Us?">
        <InputFieldText
          variant="LARGE"
          value={localHowDidYouHearAboutUs}
          onChange={event => setLocalHowDidYouHearAboutUs(event.target.value)}
          spellCheck={false}
          autoCapitalize="off"
        />
      </PageCardReferralInputWrapper>
      <Tooltip
        text="Don't forget to provide referral info"
        placement="top"
        disabled={enableSaveButton}
      >
        <TooltipContentsWrapper>
          <ButtonPrimary
            variant="LARGE"
            state={enableSaveButton ? "IDLE" : "DISABLED"}
            onClick={() => {
              onSaveButtonClick({
                newHowDidYouHearAboutUs: localHowDidYouHearAboutUs
              });
            }}
          >
            Save
          </ButtonPrimary>
        </TooltipContentsWrapper>
      </Tooltip>
    </PageCardReferralOpenWrapper>
  );
};

const PageCardReferralOpenWrapper = styled(PageCard)`
  display: flex;
  flex-direction: column;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const PageCardReferralInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const TooltipContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

type PageCardReferralCollapsedProps = {
  globalHowDidYouHearAboutUs: string;
  onEditButtonClick: () => void;
};

const PageCardReferralCollapsed: React.FC<PageCardReferralCollapsedProps> = ({
  globalHowDidYouHearAboutUs,
  onEditButtonClick
}) => {
  const theme = useTheme();

  return (
    <PageCardReferralCollapsedWrapper>
      <div>
        <div>
          <span>
            <IconCheckMark size="24px" color={theme.palette.green} />
          </span>
          <span>Referral Info</span>
        </div>
        <button onClick={() => onEditButtonClick()}>Edit</button>
      </div>
      <div>
        <div>
          <span>How Did You Hear About Us?</span>
          <span>{globalHowDidYouHearAboutUs}</span>
        </div>
      </div>
    </PageCardReferralCollapsedWrapper>
  );
};

const PageCardReferralCollapsedWrapper = styled(PageCard)`
  > div:first-child {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${props => props.theme.gridBase}px;

    > div {
      display: flex;

      > span:first-child {
        display: flex;
        margin-right: ${props => props.theme.gridBase}px;
      }

      > span:last-child {
        ${heading3Styles};
      }
    }

    > button {
      ${normalBodyStyles};
      ${linkStyles};
    }
  }

  > div:last-child {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey2};
    margin-top: ${props => props.theme.gridBase * 2}px;

    > div {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase}px;
      }

      > span:last-child {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
