import Tippy, { type TippyProps } from "@tippyjs/react/headless";
import { motion, useSpring } from "framer-motion";
import { createContext, useContext } from "react";
import styled, { useTheme } from "styled-components";
import { followCursor as followCursorPlugin } from "tippy.js";

import { smallTextStyles } from "./typography/typography";

/* ========================================================================== */

const tooltipAppendToContext = createContext<TippyProps["appendTo"]>(undefined);

export const useTooltipAppendTo = () => {
  return useContext(tooltipAppendToContext);
};

/**
 * Use this React context provider to set the `appendTo` prop of all
 * descendent `Tooltip` components. All `Tooltip` components must have a
 * ancestor `TooltipAppendToProvider`.
 */

export const TooltipAppendToProvider = tooltipAppendToContext.Provider;

/* ========================================================================== */

/**
 * NOTE:
 *
 * As mentioned in [1], in order for Tippy to work, its child (the top level
 * element that is being wrapped by the tooltip) needs to have a `ref` prop.
 * Unfortunately, TypeScript doesn't allow us (as of TS 3.7) to enforce this
 * at compile time, as TypeScript doesn't currently handle (J|T)SX that well
 * (all JSX elements are techically treated the same by the TS compiler). In
 * the future, if one can enforce child types in (J|T)SX, this would be nice
 * to add to this component.
 *
 * It's worth noting that as mentioned in [2], Tippy won't display its
 * tooltip if its child has been disabled (if the `disabled` attribute is set
 * to true). If and when possible (as explained above), this is another type
 * constraint that should be considered (by checking `disabled` to be `false`
 * or `never`).
 *
 * It's worth noting that as shown in [3], the TypeScript team are actively
 * working on this, so we should check future release notes for the feature.
 *
 * References:
 * ----------
 * [1] https://github.com/atomiks/tippy.js-react#component-children
 * [2] https://atomiks.github.io/tippyjs/creating-tooltips/#disabled-elements
 * [3] https://github.com/microsoft/TypeScript/pull/29818
 */

export type PopOutRenderArgs = { hidePopOut: () => void };

type PopOutBaseProps = {
  render: (args: PopOutRenderArgs) => React.ReactNode;
  maxWidth?: string;
  placement: TippyProps["placement"];
  offset: number;
  hideOnClick?: boolean;
  disabled?: TippyProps["disabled"];
  delay?: TippyProps["delay"];
  interactive?: TippyProps["interactive"];
  trigger?: TippyProps["trigger"];
  animate?: boolean;
  followCursor?: boolean;
  children: React.ReactElement;
};

export const PopOutBase: React.FC<PopOutBaseProps> = ({
  render,
  maxWidth = "auto",
  placement,
  offset,
  hideOnClick = true,
  disabled = false,
  delay = 0,
  interactive = false,
  trigger = "mouseenter focus",
  animate = true,
  followCursor = false,
  children
}) => {
  const tooltipAppendTo = useTooltipAppendTo();
  const opacity = useSpring(0, { duration: animate ? 300 : 0 });

  return (
    <Tippy
      appendTo={tooltipAppendTo}
      animation={true}
      onMount={() => opacity.set(1)}
      onHide={instance => {
        const cleanup = opacity.on("change", value => {
          if (value <= 0) {
            cleanup();
            instance.unmount();
          }
        });
        opacity.set(0);
      }}
      render={(attrs, _content, instance) => (
        <motion.div style={{ opacity, maxWidth }} {...attrs}>
          {render({ hidePopOut: () => instance?.hide() })}
        </motion.div>
      )}
      placement={placement}
      offset={[0, offset]}
      hideOnClick={hideOnClick}
      disabled={disabled}
      delay={delay}
      interactive={interactive}
      trigger={trigger}
      plugins={[followCursorPlugin]}
      followCursor={followCursor}
      zIndex={1999} // above modals but below `LargeViewportOnly` and toasts
    >
      {children}
    </Tippy>
  );
};

/* ========================================================================== */

type TooltipProps = {
  text: string;
  maxWidth?: string;
  placement: TippyProps["placement"];
  offset?: number;
  hideOnClick?: boolean;
  disabled?: TippyProps["disabled"];
  delay?: TippyProps["delay"];
  followCursor?: boolean;
  children: React.ReactElement;
};

export const Tooltip: React.FC<TooltipProps> = ({
  text,
  maxWidth,
  placement,
  offset,
  hideOnClick,
  disabled,
  delay,
  followCursor,
  children
}) => {
  const theme = useTheme();

  return (
    <PopOutBase
      render={() => <TooltipContainer>{text}</TooltipContainer>}
      maxWidth={maxWidth}
      placement={placement}
      offset={offset ?? theme.gridBase * 0.5}
      hideOnClick={hideOnClick}
      disabled={disabled}
      delay={delay}
      followCursor={followCursor}
    >
      {children}
    </PopOutBase>
  );
};

const TooltipContainer = styled.div`
  ${smallTextStyles};
  line-height: ${props => props.theme.gridBase * 2.25}px;
  color: ${props => props.theme.palette.white};
  background-color: ${props => props.theme.palette.grey1};
  padding-top: ${props => props.theme.gridBase * 0.5}px;
  padding-bottom: ${props => props.theme.gridBase * 0.5}px;
  padding-left: ${props => props.theme.gridBase}px;
  padding-right: ${props => props.theme.gridBase}px;
  border-radius: 4px;
  text-align: center;
`;

/* ========================================================================== */

type TooltipBigProps = {
  render: () => React.ReactNode;
  maxWidth?: string;
  placement: TippyProps["placement"];
  offset?: number;
  hideOnClick?: boolean;
  disabled?: TippyProps["disabled"];
  delay?: TippyProps["delay"];
  children: React.ReactElement;
};

export const TooltipBig: React.FC<TooltipBigProps> = ({
  render,
  maxWidth,
  placement,
  offset,
  hideOnClick,
  disabled,
  delay = [150, null],
  children
}) => {
  const theme = useTheme();

  return (
    <PopOutBase
      render={() => <TooltipBigContainer>{render()}</TooltipBigContainer>}
      maxWidth={maxWidth}
      placement={placement}
      offset={offset ?? theme.gridBase}
      hideOnClick={hideOnClick}
      disabled={disabled}
      delay={delay}
      interactive={true}
    >
      {children}
    </PopOutBase>
  );
};

const TooltipBigContainer = styled.div`
  background-color: ${props => props.theme.palette.white};
  box-shadow: ${props => props.theme.other.boxShadowDropdown};
  border-radius: 4px;
`;
