import { sortBy } from "lodash-es";
import { transparentize } from "polished";
import { useId, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { z } from "zod";

import {
  type CompanyDetails,
  type CompanyRole
} from "elevar-common-ts/src/apiTypes";

import { Avatar } from "elevar-design-system/src/Avatar";
import {
  ButtonBase,
  type ButtonBaseProps
} from "elevar-design-system/src/buttons/ButtonBase";
import { ButtonDropdown } from "elevar-design-system/src/buttons/ButtonDropdown";
import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonWarning
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconCircledInfo,
  IconCircledPlus,
  IconCog,
  IconDotsHorizontal,
  IconShuffle,
  IconTrash
} from "elevar-design-system/src/icons";
import {
  InputFieldSelect,
  type Option
} from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LabeledCheckBoxMulti } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxMulti";
import { LabeledRadio } from "elevar-design-system/src/labeledRadios/LabeledRadio";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Radio } from "elevar-design-system/src/Radio";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tag } from "elevar-design-system/src/Tag";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import {
  type CompanyInvite,
  type CompanyMember,
  type CompanyWebsites,
  useCompanyDetailsQuery,
  useCompanyInvitesCancelMutation,
  useCompanyInvitesQuery,
  useCompanyInvitesResendMutation,
  useCompanyInvitesSendMutation,
  useCompanyMembersQuery,
  useCompanyMembersRemoveMutation,
  useCompanyMembersUpdateMutation,
  useCompanyTransferOwnershipMutation,
  useCompanyWebsitesQuery
} from "../../../api/handlers/company";
import { Drawer } from "../../../components/Drawer";
import { PageCard } from "../../../components/PageCard";
import { useUserRequired } from "../../../context/User";
import { toast } from "../../../utils/toast";

/* ========================================================================== */

export const TeamMembers: React.FC = () => {
  const companyDetails = useCompanyDetailsQuery();
  const companyWebsites = useCompanyWebsitesQuery();
  const companyMembers = useCompanyMembersQuery();
  const companyInvites = useCompanyInvitesQuery();

  if (
    companyDetails.error !== null ||
    companyWebsites.error !== null ||
    companyMembers.error !== null ||
    companyInvites.error !== null
  ) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    companyDetails.data === undefined ||
    companyWebsites.data === undefined ||
    companyMembers.data === undefined ||
    companyInvites.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <TeamMembersContents
      companyDetails={companyDetails.data}
      companyWebsites={companyWebsites.data}
      companyMembers={companyMembers.data}
      companyInvites={companyInvites.data}
    />
  );
};

const CenteredWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type EmailError = "ALREADY_A_MEMBER" | "INVALID_FORMAT" | null;

type DrawerContents =
  | "CHANGE_OWNERSHIP"
  | "ADD_TEAM_MEMBER"
  | "EDIT_TEAM_MEMBER";

type LoadingForm =
  | "UPDATE_OWNERSHIP"
  | "SEND_INVITE"
  | "UPDATE_MEMBER"
  | "DELETE_MEMBER";

type TeamMembersContentsProps = {
  companyDetails: CompanyDetails;
  companyWebsites: CompanyWebsites;
  companyMembers: Array<CompanyMember>;
  companyInvites: Array<CompanyInvite>;
};

const TeamMembersContents: React.FC<TeamMembersContentsProps> = ({
  companyDetails,
  companyWebsites,
  companyMembers,
  companyInvites
}) => {
  const theme = useTheme();
  const { accountDetails } = useUserRequired();
  const history = useHistory();
  const radioGroupName = useId();

  const { mutateAsync: companyInvitesCancelMutation } =
    useCompanyInvitesCancelMutation();
  const { mutateAsync: companyInvitesResendMutation } =
    useCompanyInvitesResendMutation();
  const { mutateAsync: companyInvitesSendMutation } =
    useCompanyInvitesSendMutation();
  const { mutateAsync: companyTransferOwnershipMutation } =
    useCompanyTransferOwnershipMutation(accountDetails.id);
  const { mutateAsync: companyMembersUpdateMutation } =
    useCompanyMembersUpdateMutation(accountDetails.id);
  const { mutateAsync: companyMembersRemoveMutation } =
    useCompanyMembersRemoveMutation(accountDetails.id);

  const [isDrawerShown, setIsDrawerShown] = useState(false);
  const [emailError, setEmailError] = useState<EmailError>(null);
  const [teamMemberToEdit, setTeamMemberToEdit] =
    useState<CompanyMember | null>(null);
  const [drawerContents, setDrawerContents] = useState<DrawerContents | null>(
    null
  );
  const [loadingFormType, setLoadingFormType] = useState<LoadingForm | null>(
    null
  );
  const [activeNewOwnerId, setActiveNewOwnerId] = useState<number | null>(null);
  const [emailAddress, setEmailAddress] = useState("");
  const [accountRole, setAccountRole] = useState<CompanyRole>("Member");
  const [websitesChecked, setWebsitesChecked] = useState<CompanyWebsites>([]);
  const [inviteLoading, setInviteLoading] = useState<CompanyInvite | null>(
    null
  );
  const [isRemoveMemberWarningShown, setIsRemoveMemberWarningShown] =
    useState(false);

  const orderedTeamMembers = sortBy(companyMembers, [
    member => member.role !== "Owner",
    member => member.role !== "Admin",
    member => member.first_name,
    member => member.last_name
  ]);

  const newOwnerOptions = orderedTeamMembers
    .filter(member => member.role === "Admin")
    .map<Option<number>>(member => ({
      name: `${member.first_name} ${member.last_name}`,
      value: member.id
    }));

  const activeNewOwnerOption =
    newOwnerOptions.find(o => o.value === activeNewOwnerId) ?? null;

  const orderedInvites = sortBy(companyInvites, [invite => invite.email]);
  const orderedWebsites = sortBy(companyWebsites, [website => website.name]);

  return (
    <>
      <TeamMembersPageCard>
        <ul>
          {orderedTeamMembers.map(member => (
            <UserListItem key={member.id}>
              <div>
                <UserImageWrapper>
                  <Avatar
                    size="MEDIUM"
                    type="USER"
                    imageSrc={member.picture?.file}
                  />
                </UserImageWrapper>
                <UserName>
                  {member.first_name} {member.last_name}
                </UserName>
              </div>
              <div>
                <TagWithMargin
                  text={member.role}
                  textColor={(() => {
                    switch (member.role) {
                      case "Owner":
                        return theme.palette.purple2;
                      case "Admin":
                        return theme.palette.blue1;
                      case "Member":
                        return theme.palette.orange;
                    }
                  })()}
                />

                {member.role === "Owner" ? (
                  <ConfigureMemberButton
                    onClick={() => {
                      setTeamMemberToEdit(member);
                      setDrawerContents("CHANGE_OWNERSHIP");
                      setIsDrawerShown(true);
                    }}
                  >
                    <IconShuffle size="24px" color={theme.palette.grey4} />
                  </ConfigureMemberButton>
                ) : (
                  <ConfigureMemberButton
                    onClick={() => {
                      setTeamMemberToEdit(member);
                      setDrawerContents("EDIT_TEAM_MEMBER");
                      setAccountRole(member.role);
                      setWebsitesChecked(
                        companyWebsites.filter(website =>
                          member.websites.some(
                            memberWebsiteId => memberWebsiteId === website.id
                          )
                        )
                      );
                      setIsRemoveMemberWarningShown(false);
                      setIsDrawerShown(true);
                    }}
                  >
                    <IconCog size="24px" color={theme.palette.grey4} />
                  </ConfigureMemberButton>
                )}
              </div>
            </UserListItem>
          ))}
        </ul>
        <AddNewTeamMemberButtonWrapper>
          <Tooltip
            text="To add a team member, create a Website first"
            placement="top"
            disabled={orderedWebsites.length > 0}
          >
            <span>
              <AddNewTeamMemberButton
                disabled={orderedWebsites.length === 0}
                onClick={() => {
                  if (drawerContents !== "ADD_TEAM_MEMBER") {
                    setEmailAddress("");
                    setAccountRole("Member");
                    setWebsitesChecked([]);
                    setDrawerContents("ADD_TEAM_MEMBER");
                  }
                  setIsDrawerShown(true);
                }}
              >
                <div>
                  <IconCircledPlus size="24px" />
                </div>
                <div>Add New Team Member</div>
              </AddNewTeamMemberButton>
            </span>
          </Tooltip>
        </AddNewTeamMemberButtonWrapper>
      </TeamMembersPageCard>
      {orderedInvites.length > 0 ? (
        <>
          <CardHeading>Invited</CardHeading>
          <TeamMembersPageCard>
            <ul>
              {orderedInvites.map(invite => (
                <UserListItem key={invite.id}>
                  <div>
                    <UserImageWrapper>
                      <Avatar size="MEDIUM" type="USER" />
                    </UserImageWrapper>
                    <UserName>{invite.email}</UserName>
                  </div>
                  <div>
                    <TagWithMargin
                      text="Invited"
                      textColor={theme.palette.green}
                    />
                    <InviteButtonDropdown
                      buttonProps={{ disabled: inviteLoading !== null }}
                      dropdownPlacement="bottom-end"
                      dropdownSections={[
                        {
                          options: [
                            {
                              value: "Cancel Invite",
                              type: "BUTTON",
                              onClick: async () => {
                                setInviteLoading(invite);
                                await companyInvitesCancelMutation(invite.id);
                                toast.success("Invite cancelled");
                                setInviteLoading(null);
                              }
                            },
                            {
                              value: "Resend Invite",
                              type: "BUTTON",
                              onClick: async () => {
                                setInviteLoading(invite);
                                await companyInvitesResendMutation(invite.id);
                                toast.success("Invite resent");
                                setInviteLoading(null);
                              }
                            }
                          ]
                        }
                      ]}
                    >
                      {invite === inviteLoading ? (
                        <Spinner size="24px" color={theme.palette.grey4} />
                      ) : (
                        <IconDotsHorizontal
                          size="24px"
                          color={theme.palette.grey4}
                        />
                      )}
                    </InviteButtonDropdown>
                  </div>
                </UserListItem>
              ))}
            </ul>
          </TeamMembersPageCard>
        </>
      ) : null}
      <Drawer
        isVisible={isDrawerShown}
        onClose={() => setIsDrawerShown(false)}
        disallowClose={
          loadingFormType === "UPDATE_OWNERSHIP" ||
          loadingFormType === "SEND_INVITE" ||
          loadingFormType === "UPDATE_MEMBER" ||
          loadingFormType === "DELETE_MEMBER"
        }
      >
        {drawerContents === "CHANGE_OWNERSHIP" && teamMemberToEdit ? (
          <>
            <DrawerSubheading>{teamMemberToEdit.username}</DrawerSubheading>
            <DrawerTitle>
              {teamMemberToEdit.first_name} {teamMemberToEdit.last_name}
            </DrawerTitle>
            <OwnershipDrawerSubtitle>
              Transfer Company Ownership
            </OwnershipDrawerSubtitle>
            <OwnershipDrawerWarningNotice>
              <div>
                <IconCircledInfo size="16px" />
              </div>
              <div>This action will take immmediate effect.</div>
            </OwnershipDrawerWarningNotice>
            <OwnershipDrawerExplainer>
              <div>
                <p>Note: we send out certain emails to owners only.</p>
              </div>
              <div>
                <p>
                  To transer ownership, select a new owner from the list. The
                  current owner will become an admin after ownership is
                  transferred.
                </p>
                <p>
                  To remove an owner from a Company, or to delete the owner's
                  account, please transfer the Company ownership first.
                </p>
              </div>
            </OwnershipDrawerExplainer>
            <OwnershipDrawerInputWrapper
              labelText="New Owner"
              disabled={loadingFormType === "UPDATE_OWNERSHIP"}
            >
              <InputFieldSelect
                variant="SMALL"
                disabled={loadingFormType === "UPDATE_OWNERSHIP"}
                value={activeNewOwnerOption}
                setValue={option => {
                  if (option !== activeNewOwnerOption) {
                    setActiveNewOwnerId(option.value);
                  }
                }}
                options={newOwnerOptions}
                placeholder="Select an Admin"
                contentsWhenEmpty={() => (
                  <OwnershipDrawerInputEmptyContentWrapper>
                    This Company has no admin members. If you wish to transfer
                    ownership to another user, please ensure that the user is an
                    admin of the Company.
                  </OwnershipDrawerInputEmptyContentWrapper>
                )}
              />
            </OwnershipDrawerInputWrapper>
            <ButtonPrimary
              variant="SMALL"
              state={
                loadingFormType === "UPDATE_OWNERSHIP"
                  ? "LOADING"
                  : activeNewOwnerOption === null
                    ? "DISABLED"
                    : "IDLE"
              }
              onClick={async () => {
                if (activeNewOwnerId !== null) {
                  setLoadingFormType("UPDATE_OWNERSHIP");
                  await companyTransferOwnershipMutation(activeNewOwnerId);
                  setActiveNewOwnerId(null);
                  setIsDrawerShown(false);
                  setLoadingFormType(null);
                  toast.success("Company owner updated");
                }
              }}
            >
              Transfer Ownership
            </ButtonPrimary>
          </>
        ) : drawerContents === "ADD_TEAM_MEMBER" ? (
          <>
            <DrawerSubheading>{companyDetails.name}</DrawerSubheading>
            <DrawerTitle>Add Team Member</DrawerTitle>
            <DrawerInputWrapper
              labelText="Email Address"
              disabled={loadingFormType === "SEND_INVITE"}
              error={emailError !== null}
              errorText={
                emailError === "ALREADY_A_MEMBER"
                  ? "Already a Member"
                  : emailError === "INVALID_FORMAT"
                    ? "Invalid Format"
                    : ""
              }
            >
              <InputFieldText
                value={emailAddress}
                onChange={event => setEmailAddress(event.target.value)}
                variant="SMALL"
                placeholder="Enter Email Address"
                disabled={loadingFormType === "SEND_INVITE"}
                error={emailError !== null}
              />
            </DrawerInputWrapper>
            <DrawerInputWrapper
              labelText="Company Role"
              asDiv={true}
              disabled={loadingFormType === "SEND_INVITE"}
            >
              <AccountRoleRadiosWrapper>
                <div>
                  <LabeledRadioDetailed
                    groupName={radioGroupName}
                    isSelected={accountRole === "Admin"}
                    setIsSelected={() => setAccountRole("Admin")}
                    heading="Admin"
                    explainer="Has access to the settings of this Company, as well as the settings of the Websites they have been given access to below."
                  />
                </div>
                <div>
                  <LabeledRadioDetailed
                    groupName={radioGroupName}
                    isSelected={accountRole === "Member"}
                    setIsSelected={() => setAccountRole("Member")}
                    heading="Member"
                    explainer="Does not have access to the settings of this Company, or the settings of any Websites in this Company."
                  />
                </div>
              </AccountRoleRadiosWrapper>
            </DrawerInputWrapper>
            <DrawerInputWrapper
              labelText="Allow access to:"
              asDiv={true}
              disabled={loadingFormType === "SEND_INVITE"}
            >
              {orderedWebsites.length > 1 ? (
                <AllSelectionButton
                  onClick={() => {
                    setWebsitesChecked(
                      websitesChecked.length < orderedWebsites.length
                        ? orderedWebsites
                        : []
                    );
                  }}
                >
                  {websitesChecked.length < orderedWebsites.length
                    ? "Select All"
                    : "Deselect All"}
                </AllSelectionButton>
              ) : null}
              {orderedWebsites.map(website => (
                <LabeledCheckBoxMulti
                  key={website.id}
                  variant="NORMAL"
                  isChecked={websitesChecked.includes(website)}
                  setIsChecked={isChecked => {
                    if (isChecked) {
                      setWebsitesChecked([...websitesChecked, website]);
                    } else {
                      setWebsitesChecked(
                        websitesChecked.filter(
                          checkedWebsite => checkedWebsite !== website
                        )
                      );
                    }
                  }}
                  text={website.name}
                  isDisabled={loadingFormType === "SEND_INVITE"}
                />
              ))}
            </DrawerInputWrapper>
            <SendInviteButtonWrapper>
              <ButtonPrimary
                variant="SMALL"
                state={
                  loadingFormType === "SEND_INVITE"
                    ? "LOADING"
                    : emailAddress.length === 0 || websitesChecked.length === 0
                      ? "DISABLED"
                      : "IDLE"
                }
                onClick={async () => {
                  setLoadingFormType("SEND_INVITE");

                  try {
                    await companyInvitesSendMutation({
                      email: emailAddress,
                      role: accountRole,
                      websites: websitesChecked.map(w => w.id)
                    });

                    setIsDrawerShown(false);
                    setEmailAddress("");
                    setAccountRole("Member");
                    setWebsitesChecked([]);
                    setLoadingFormType(null);
                    setEmailError(null);

                    toast.success("Invite sent");
                  } catch (error) {
                    const expectedErrorSchema = z.object({
                      cause: z.object({
                        errors: z.object({ email: z.array(z.string()) })
                      })
                    });

                    const parsedError = expectedErrorSchema.safeParse(error);

                    if (parsedError.success) {
                      const alreadyAMemberText = "Is already a member";
                      const invalidEmailText = "Enter a valid email address.";

                      const emailErrors = parsedError.data.cause.errors.email;

                      if (emailErrors.includes(alreadyAMemberText)) {
                        setEmailError("ALREADY_A_MEMBER");
                      } else if (emailErrors.includes(invalidEmailText)) {
                        setEmailError("INVALID_FORMAT");
                      } else {
                        toast.errorUnexpected(error);
                      }
                    } else {
                      toast.errorUnexpected(error);
                    }

                    setLoadingFormType(null);
                  }
                }}
              >
                Send Invite
              </ButtonPrimary>
            </SendInviteButtonWrapper>
          </>
        ) : teamMemberToEdit ? (
          <>
            <DrawerSubheading>{teamMemberToEdit.username}</DrawerSubheading>
            <DrawerTitle>
              {teamMemberToEdit.first_name} {teamMemberToEdit.last_name}
            </DrawerTitle>
            <DrawerInputWrapper
              labelText="Company Role"
              asDiv={true}
              disabled={
                loadingFormType === "UPDATE_MEMBER" ||
                loadingFormType === "DELETE_MEMBER"
              }
            >
              <AccountRoleRadiosWrapper>
                <div>
                  <LabeledRadioDetailed
                    groupName={radioGroupName}
                    isSelected={accountRole === "Admin"}
                    setIsSelected={() => setAccountRole("Admin")}
                    heading="Admin"
                    explainer="Has access to the settings of this Company, as well as the settings of the Websites they have been given access to below."
                  />
                </div>
                <div>
                  <LabeledRadioDetailed
                    groupName={radioGroupName}
                    isSelected={accountRole === "Member"}
                    setIsSelected={() => setAccountRole("Member")}
                    heading="Member"
                    explainer="Does not have access to the settings of this Company, or the settings of any Websites in this Company."
                  />
                </div>
              </AccountRoleRadiosWrapper>
            </DrawerInputWrapper>
            <DrawerInputWrapper
              labelText="Allow access to:"
              asDiv={true}
              disabled={
                loadingFormType === "UPDATE_MEMBER" ||
                loadingFormType === "DELETE_MEMBER"
              }
            >
              {orderedWebsites.length > 1 ? (
                <AllSelectionButton
                  onClick={() => {
                    setWebsitesChecked(
                      websitesChecked.length < orderedWebsites.length
                        ? orderedWebsites
                        : []
                    );
                  }}
                >
                  {websitesChecked.length < orderedWebsites.length
                    ? "Select All"
                    : "Deselect All"}
                </AllSelectionButton>
              ) : null}
              {orderedWebsites.length === 0 ? (
                <NoWebsites>
                  There are no Websites inside this Company to select.
                </NoWebsites>
              ) : (
                orderedWebsites.map(website => (
                  <LabeledCheckBoxMulti
                    key={website.id}
                    variant="NORMAL"
                    isChecked={websitesChecked.includes(website)}
                    setIsChecked={isChecked => {
                      if (isChecked) {
                        setWebsitesChecked([...websitesChecked, website]);
                      } else {
                        setWebsitesChecked(
                          websitesChecked.filter(
                            checkedWebsite => checkedWebsite !== website
                          )
                        );
                      }
                    }}
                    text={website.name}
                    isDisabled={
                      loadingFormType === "UPDATE_MEMBER" ||
                      loadingFormType === "DELETE_MEMBER"
                    }
                  />
                ))
              )}
            </DrawerInputWrapper>
            <SaveOrRemoveButtonWrapper>
              <ButtonPrimary
                variant="SMALL"
                state={
                  loadingFormType === "UPDATE_MEMBER"
                    ? "LOADING"
                    : loadingFormType === "DELETE_MEMBER" ||
                        (orderedWebsites.length > 0 &&
                          websitesChecked.length === 0)
                      ? "DISABLED"
                      : "IDLE"
                }
                onClick={async () => {
                  setLoadingFormType("UPDATE_MEMBER");
                  await companyMembersUpdateMutation({
                    memberId: teamMemberToEdit.id,
                    data: {
                      role: accountRole,
                      websites: websitesChecked.map(({ id }) => id)
                    }
                  });
                  toast.success("Member updated");

                  if (
                    teamMemberToEdit.id === accountDetails.id &&
                    accountRole === "Member"
                  ) {
                    history.push("/");
                  } else {
                    setIsDrawerShown(false);
                    setLoadingFormType(null);
                  }
                }}
              >
                Save Changes
              </ButtonPrimary>
              {isRemoveMemberWarningShown ? (
                <RemoveMemberWarningWrapper>
                  <div>Are you sure?</div>
                  <ButtonWarning
                    variant="SMALL"
                    state={
                      loadingFormType === "DELETE_MEMBER"
                        ? "LOADING"
                        : loadingFormType === "UPDATE_MEMBER"
                          ? "DISABLED"
                          : "IDLE"
                    }
                    onClick={async () => {
                      setLoadingFormType("DELETE_MEMBER");
                      await companyMembersRemoveMutation(teamMemberToEdit.id);
                      toast.success("Member removed");

                      if (teamMemberToEdit.id === accountDetails.id) {
                        history.push("/");
                      } else {
                        setIsDrawerShown(false);
                        setLoadingFormType(null);
                      }
                    }}
                  >
                    Yes
                  </ButtonWarning>
                </RemoveMemberWarningWrapper>
              ) : (
                <ButtonTransparent
                  variant="SMALL"
                  state={
                    loadingFormType === "DELETE_MEMBER" ||
                    loadingFormType === "UPDATE_MEMBER"
                      ? "DISABLED"
                      : "IDLE"
                  }
                  onClick={() => {
                    setIsRemoveMemberWarningShown(true);
                  }}
                >
                  <div>
                    <IconTrash size="24px" />
                  </div>
                  <div>Remove Member</div>
                </ButtonTransparent>
              )}
            </SaveOrRemoveButtonWrapper>
          </>
        ) : null}
      </Drawer>
    </>
  );
};

const TeamMembersPageCard = styled(PageCard)`
  padding: 0;
`;

const CardHeading = styled.h2`
  ${heading3Styles};
  margin-top: ${props => props.theme.gridBase * 4}px;
  margin-bottom: ${props => props.theme.gridBase * 2.25}px;
`;

const UserListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding-top: ${props => props.theme.gridBase * 3}px;
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 2.5}px;

  &:not(:first-child) {
    border-top: 2px solid ${props => props.theme.palette.grey8};
  }

  > div {
    display: flex;
    align-items: center;
  }
`;

const UserImageWrapper = styled.div`
  margin-right: ${props => props.theme.gridBase * 2}px;
`;

const UserName = styled.div`
  ${normalBodyStyles};
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  max-width: ${props => props.theme.gridBase * 32}px;
`;

const TagWithMargin = styled(Tag)`
  margin-right: ${props => props.theme.gridBase * 0.5}px;
`;

const ConfigureMemberButton = styled.button`
  ${iconButtonStyles};
  margin-left: ${props => props.theme.gridBase * 2}px;
`;

const AddNewTeamMemberButtonWrapper = styled.div`
  border-top: 2px solid ${props => props.theme.palette.grey8};
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
`;

const AddNewTeamMemberButton = styled.button`
  ${iconTextButtonStyles};
`;

const InviteButtonDropdown = styled(ButtonDropdown)`
  ${iconButtonStyles};
  margin-left: ${props => props.theme.gridBase * 2}px;
`;

const DrawerSubheading = styled.div`
  ${subheadingStyles};
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const DrawerTitle = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const OwnershipDrawerSubtitle = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const OwnershipDrawerWarningNotice = styled.div`
  ${smallTextStyles};
  line-height: ${props => props.theme.gridBase * 2.25}px;
  color: ${props => props.theme.palette.orange};
  background-color: ${props => transparentize(0.9, props.theme.palette.orange)};
  display: flex;
  padding-top: ${props => props.theme.gridBase * 0.5}px;
  padding-bottom: ${props => props.theme.gridBase * 0.5}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
  width: max-content;

  > div:first-child {
    display: flex;
    align-items: center;
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

const OwnershipDrawerExplainer = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;

  > div:first-child {
    padding-bottom: ${props => props.theme.gridBase * 2}px;
    border-bottom: 1px solid ${props => props.theme.palette.grey7};
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }

  > div {
    > p {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
    }

    > p:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const OwnershipDrawerInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const OwnershipDrawerInputEmptyContentWrapper = styled.div`
  ${smallTextStyles};
  line-height: ${props => props.theme.gridBase * 2.5}px;
  color: ${props => props.theme.palette.grey3};
  padding: ${props => props.theme.gridBase}px;
`;

const DrawerInputWrapper = styled(InputWrapper)`
  position: relative;
  margin-top: ${props => props.theme.gridBase * 4}px;
`;

const AccountRoleRadiosWrapper = styled.div`
  > div:first-child {
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

const AllSelectionButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  ${normalTextStyles};
  ${linkStyles};
`;

const NoWebsites = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey4};
`;

const SendInviteButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 4}px;
`;

const SaveOrRemoveButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 4}px;
  display: flex;

  > :first-child {
    margin-right: ${props => props.theme.gridBase * 2}px;
  }
`;

const RemoveMemberWarningWrapper = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey3};
    margin-left: ${props => props.theme.gridBase}px;
    margin-right: ${props => props.theme.gridBase * 1.75}px;
  }
`;

const ButtonTransparent = styled(ButtonBase).attrs<
  Omit<ButtonBaseProps, "colors">,
  ButtonBaseProps
>(props => ({
  variant: props.variant,
  colors: {
    normalText: props.theme.palette.grey3,
    normalBackground: props.theme.palette.white,
    hoveredText: props.theme.palette.grey3,
    hoveredBackground: props.theme.palette.grey8,
    disabledText: props.theme.palette.grey5,
    disabledBackground: props.theme.palette.white
  }
}))`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase}px;

  > div:first-child {
    display: flex;
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

/* ========================================================================== */

type LabeledRadioDetailedProps = {
  groupName: string;
  isSelected: boolean;
  setIsSelected: () => void;
  heading: string;
  explainer: string;
  isDisabled?: boolean;
};

const LabeledRadioDetailed: React.FC<LabeledRadioDetailedProps> = ({
  groupName,
  isSelected,
  setIsSelected,
  heading,
  explainer,
  isDisabled
}) => {
  return (
    <StyledLabeledRadio
      groupName={groupName}
      isSelected={isSelected}
      setIsSelected={setIsSelected}
      isDisabled={isDisabled}
    >
      {({ isHovered, isFocused }) => (
        <StyledLabeledRadioInner isSelected={isSelected}>
          <div>
            <Radio
              isSelected={isSelected}
              isHovered={isHovered}
              isFocused={isFocused}
            />
            <StyledLabeledRadioHeading isSelected={isSelected}>
              {heading}
            </StyledLabeledRadioHeading>
          </div>
          <div>
            <StyledLabeledRadioExplainer>
              {explainer}
            </StyledLabeledRadioExplainer>
          </div>
        </StyledLabeledRadioInner>
      )}
    </StyledLabeledRadio>
  );
};

const StyledLabeledRadio = styled(LabeledRadio)`
  background-color: ${props => props.theme.palette.white};
`;

type StyledLabeledRadioInnerProps = {
  isSelected: boolean;
};

const StyledLabeledRadioInner = styled.div<StyledLabeledRadioInnerProps>`
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.isSelected ? props.theme.palette.blue1 : props.theme.palette.grey6};
  transition: border-color ${props => props.theme.other.transition};

  > div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }
`;

type StyledLabeledRadioHeadingProps = {
  isSelected: boolean;
};

const StyledLabeledRadioHeading = styled.div<StyledLabeledRadioHeadingProps>`
  ${normalTextStyles};
  font-weight: 500;
  margin-left: ${props => props.theme.gridBase}px;
  color: ${props =>
    props.isSelected ? props.theme.palette.blue1 : props.theme.palette.grey1};
  transition: color ${props => props.theme.other.transition};
`;

const StyledLabeledRadioExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
`;
