import { useState } from "react";
import styled from "styled-components";

import {
  ButtonSecondary,
  ButtonWarning
} from "elevar-design-system/src/buttons/ButtonVariants";
import { LabeledCheckBoxSingle } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxSingle";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { useCompanyId, useWebsiteId } from "../utils/idHooks";
import { Modal } from "./Modal";

/* ========================================================================== */

type ConsentWarningModalProps = {
  type: "WRITER" | "READER";
  isVisible: boolean;
  onClose: () => void;
  onEnable: () => void;
};

export const ConsentWarningModal: React.FC<ConsentWarningModalProps> = ({
  type,
  isVisible,
  onClose,
  onEnable
}) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const exploreProductsUrl = `${websiteUrl}/settings/products/explore`;
  const consentUrl = `${exploreProductsUrl}?product_id=consent-integration`;

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <ConsentWarningModalContents>
        <ConsentWarningModalHeading>
          {type === "WRITER"
            ? "Enable Elevar's Google Consent Mode Integration"
            : "Enable Consent Mode"}
        </ConsentWarningModalHeading>
        <ConsentWarningModalExplainer>
          <div>
            By continuing, your marketing data processing will depend on:
          </div>
          <ul>
            <li>Your consent platform configuration</li>
            <li>How your visitors interact with the cookie banner</li>
            <li>What geolocation they visit the store from</li>
          </ul>
          <p>
            That's why you may see a <span>decrease in data</span> across your
            marketing platforms and{" "}
            <span>lower marketing campaign performance</span>.
          </p>
          <p>
            <span>For EU stores:</span> If you are making your tracking setup
            consent compliant for the first time, you may see a 10-80% decrease
            in data across all your marketing platforms.
          </p>
          {type === "WRITER" ? (
            <p>
              <span>Note:</span> Please ensure the geolocation targeting is set
              correctly in your consent platform. This will help avoid negative
              effects on your marketing data. You can{" "}
              <LinkExternal href={consentUrl}>
                hire Elevar experts to set up Consent
              </LinkExternal>
              .
            </p>
          ) : null}
        </ConsentWarningModalExplainer>
        <ConsentWarningModalActions>
          {type === "WRITER" ? (
            <div>
              <LabeledCheckBoxSingle
                variant="NORMAL"
                isChecked={isCheckboxChecked}
                setIsChecked={setIsCheckboxChecked}
              >
                I accept the risks of enabling Elevar's Google Consent Mode
                Integration
              </LabeledCheckBoxSingle>
            </div>
          ) : null}
          <div>
            <ButtonWarning
              variant="SMALL"
              state={
                isCheckboxChecked || type === "READER" ? "IDLE" : "DISABLED"
              }
              onClick={() => {
                onEnable();
                onClose();
              }}
            >
              Enable
            </ButtonWarning>
            <ButtonSecondary variant="SMALL" onClick={onClose}>
              Go Back
            </ButtonSecondary>
          </div>
        </ConsentWarningModalActions>
      </ConsentWarningModalContents>
    </Modal>
  );
};

/* ========================================================================== */

const ConsentWarningModalContents = styled.div`
  width: ${props => props.theme.gridBase * 64.5}px;
`;

const ConsentWarningModalHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ConsentWarningModalExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > div {
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > ul {
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;
    padding-left: ${props => props.theme.gridBase * 2}px;

    > li {
      list-style: disc;
      margin-bottom: ${props => props.theme.gridBase * 0.5}px;
    }
  }

  > p {
    > span {
      color: ${props => props.theme.palette.grey1};
      font-weight: 600;
    }

    > a {
      ${linkStyles};
    }

    &:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 1.5}px;
    }

    &:last-child {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const ConsentWarningModalActions = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }

  > div:last-child {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props => props.theme.gridBase * 2}px;
  }
`;
