import { useId, useState } from "react";
import styled, { useTheme } from "styled-components";
import { type PartialDeep } from "type-fest";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";
import {
  reChargeChannel,
  unifiedCheckoutChannel
} from "elevar-common-ts/src/channels";
import { type OptionalPromise } from "elevar-common-ts/src/utils";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LabeledRadioText } from "elevar-design-system/src/labeledRadios/LabeledRadioText";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { type ConfigMutationOptions } from "../../context/SetupFlowDetails";
import { type Destination } from "./data";
import {
  firstTimeSubscriptionTagName,
  recurringSubscriptionTagName
} from "./shared";
import { StepSection, type StepSectionProps } from "./StepSection";

/* ========================================================================== */

type PredefinedSubscriptionApp = {
  name: string;
  tagName: string;
  description: string;
};

const predefinedSubscriptionApps: Array<PredefinedSubscriptionApp> = [
  {
    name: "ReCharge / OrderGroove",
    tagName: recurringSubscriptionTagName,
    description: `These both use "${recurringSubscriptionTagName}" as the order tag on recurring subscription orders.`
  }
];

type SubscriptionAppSelection =
  | { type: "PREDEFINED"; app: PredefinedSubscriptionApp }
  | { type: "OTHER" };

type SellsSubscriptionsState = "YES_UNIFIED" | "YES_SEPARATE" | "NO" | null;
type DestinationConfigs = Pick<EventsConnectorConfig, Destination["configKey"]>;

type SubscriptionDetails = {
  [Key in keyof DestinationConfigs]: {
    key: Key;
    config: DestinationConfigs[Key][number];
    onSave: (
      data: Omit<PartialDeep<DestinationConfigs[Key][number]>, "completedStep">,
      options: ConfigMutationOptions
    ) => OptionalPromise<void>;
  };
}[keyof DestinationConfigs];

const configMutationOptions: ConfigMutationOptions = {
  disableGtmReimportTrigger: true
};

/* ========================================================================== */

type SubscriptionsProps = {
  isLoading: boolean;
  isStepCompleted: boolean;
  setupGuideHref: StepSectionProps["setupGuideHref"];
  media?: StepSectionProps["media"];
  details: SubscriptionDetails;
};

export const Subscriptions: React.FC<SubscriptionsProps> = ({
  isLoading,
  isStepCompleted,
  setupGuideHref,
  media,
  details
}) => {
  const theme = useTheme();
  const radioGroup1Name = useId();
  const radioGroup2Name = useId();
  const radioGroup3Name = useId();
  const radioGroup4Name = useId();
  const radioGroup5Name = useId();
  const { eventsConnectorConfig } = useMyTrackingDetails();

  const { marketsEnabled } = eventsConnectorConfig.globalConfig;
  const initialUsesUnifiedCheckout = details.config.uses_unified_checkout;
  const initialSubscriptionTagName = details.config.subscriptionTagName;
  const orderFilters = details.config.orderFilters;

  const initialSellsSubscriptionsState: SellsSubscriptionsState =
    isStepCompleted
      ? initialUsesUnifiedCheckout
        ? "YES_UNIFIED"
        : initialSubscriptionTagName !== null
          ? "YES_SEPARATE"
          : "NO"
      : null;

  const initialSubscriptionApp = predefinedSubscriptionApps.find(
    app => app.tagName === details.config.subscriptionTagName
  );

  const initialFiltersFirstTimeTransactions =
    isStepCompleted && initialSellsSubscriptionsState === "YES_SEPARATE"
      ? orderFilters.tags.includes(firstTimeSubscriptionTagName)
      : null;

  const initialBlocksFirstTimeTransactions =
    orderFilters.condition === "ALLOW"
      ? !initialFiltersFirstTimeTransactions
      : initialFiltersFirstTimeTransactions;

  const initialFiltersRecurringTransactions = isStepCompleted
    ? (initialSellsSubscriptionsState === "YES_UNIFIED" &&
        orderFilters.channels.includes(unifiedCheckoutChannel.code) &&
        orderFilters.channels.includes(reChargeChannel.code) &&
        orderFilters.tags.includes(recurringSubscriptionTagName)) ||
      (initialSellsSubscriptionsState === "YES_SEPARATE" &&
        initialSubscriptionTagName !== null &&
        orderFilters.tags.includes(initialSubscriptionTagName))
    : details.key === "ga4"
      ? true
      : null;

  const initialBlockRecurringTransactions =
    orderFilters.condition === "ALLOW"
      ? !initialFiltersRecurringTransactions
      : initialFiltersRecurringTransactions;

  const [sellsSubscriptionsState, setSellsSubscriptionsState] = useState(
    initialSellsSubscriptionsState
  );
  const [subscriptionAppSelection, setSubscriptionAppSelection] =
    useState<SubscriptionAppSelection | null>(
      isStepCompleted && initialSubscriptionTagName !== null
        ? initialSubscriptionApp
          ? { type: "PREDEFINED", app: initialSubscriptionApp }
          : { type: "OTHER" }
        : null
    );
  const [otherInputValue, setOtherInputValue] = useState(
    initialSubscriptionTagName !== null && !initialSubscriptionApp
      ? initialSubscriptionTagName
      : ""
  );
  const [blockFirstTimeTransactions, setBlockFirstTimeTransactions] = useState(
    initialBlocksFirstTimeTransactions
  );
  const [blockRecurringTransactions, setBlockRecurringTransactions] = useState(
    initialBlockRecurringTransactions
  );
  const [attributionState, setAttributionState] = useState(() => {
    switch (details.key) {
      case "ga4": {
        const primaryOverride =
          initialSellsSubscriptionsState === "YES_UNIFIED"
            ? details.config.dataConfig.channelSourceOverrides.find(
                o => o.channelName === unifiedCheckoutChannel.code
              )
            : details.config.dataConfig.tagSourceOverrides.find(
                o => o.tagName === initialSubscriptionTagName
              );
        return {
          isEnabled:
            isStepCompleted && initialBlockRecurringTransactions === false
              ? Boolean(primaryOverride)
              : true,
          campaignSource: primaryOverride?.utm_source ?? "shopify",
          campaignMedium: primaryOverride?.utm_medium ?? "recurring_order",
          campaignName: primaryOverride?.utm_campaign ?? "recurring_order"
        };
      }
      default: {
        return null;
      }
    }
  });

  const isAppSelectionValid =
    sellsSubscriptionsState !== "YES_SEPARATE" ||
    subscriptionAppSelection !== null;

  const isOtherSelected =
    sellsSubscriptionsState === "YES_SEPARATE" &&
    subscriptionAppSelection?.type === "OTHER";

  const isOtherValid = !isOtherSelected || otherInputValue !== "";

  const isAttributionValid =
    !attributionState?.isEnabled ||
    (attributionState.campaignSource !== "" &&
      attributionState.campaignMedium !== "" &&
      attributionState.campaignName !== "");

  return (
    <SubscriptionsWrapper>
      <PageCard>
        <StepSection
          title="Do you sell subscription products?"
          setupGuideHref={setupGuideHref}
          description={
            <SubscriptionsExplainer>
              Subscription products are products you sell on a recurring basis
              (recurring orders). You may have installed subscription apps like
              ReCharge, OrderGroove, etc.
            </SubscriptionsExplainer>
          }
          media={media}
        >
          <SubscriptionsSectionInnerWrapper>
            <div>
              <LabeledRadioText
                groupName={radioGroup1Name}
                isSelected={sellsSubscriptionsState === "YES_UNIFIED"}
                setIsSelected={() => setSellsSubscriptionsState("YES_UNIFIED")}
                text="Yes, using unified Shopify checkout"
                isDisabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 37}px`,
                  render: () => (
                    <SubscriptionsTooltipContent>
                      The unified Shopify checkout is managed directly in the
                      Shopify Admin.
                    </SubscriptionsTooltipContent>
                  )
                }}
              />
              {initialSellsSubscriptionsState === "YES_SEPARATE" ? (
                <LabeledRadioText
                  groupName={radioGroup1Name}
                  isSelected={sellsSubscriptionsState === "YES_SEPARATE"}
                  setIsSelected={() => {
                    setSellsSubscriptionsState("YES_SEPARATE");
                  }}
                  text="Yes, using separate checkout"
                  isDisabled={isLoading}
                  tooltip={{
                    maxWidth: `${theme.gridBase * 39.5}px`,
                    render: () => (
                      <SubscriptionsTooltipContent>
                        Separate checkouts are managed in a 3rd party app like
                        Recharge, Ordergroove, etc.
                      </SubscriptionsTooltipContent>
                    )
                  }}
                />
              ) : null}
              <LabeledRadioText
                groupName={radioGroup1Name}
                isSelected={sellsSubscriptionsState === "NO"}
                setIsSelected={() => setSellsSubscriptionsState("NO")}
                text="No"
                isDisabled={isLoading}
              />
            </div>
            {sellsSubscriptionsState === "NO" ? (
              <SubscriptionsActions1Wrapper>
                <ButtonPrimary
                  variant="SMALL"
                  state={isLoading ? "LOADING" : "IDLE"}
                  onClick={() => {
                    const common = {
                      uses_unified_checkout: false,
                      subscriptionTagName: null,
                      orderFilters: {
                        channels: orderFilters.channels.filter(
                          c =>
                            c !== unifiedCheckoutChannel.code &&
                            c !== reChargeChannel.code
                        ),
                        tags: orderFilters.tags.filter(
                          t =>
                            t !== initialSubscriptionTagName &&
                            t !== firstTimeSubscriptionTagName &&
                            t !== recurringSubscriptionTagName
                        )
                      }
                    };

                    switch (details.key) {
                      case "ga4": {
                        const initialChannelSourceOverrides =
                          details.config.dataConfig.channelSourceOverrides;
                        const initialTagSourceOverrides =
                          details.config.dataConfig.tagSourceOverrides;

                        return details.onSave(
                          {
                            ...common,
                            dataConfig: {
                              channelSourceOverrides:
                                initialChannelSourceOverrides.filter(
                                  o =>
                                    o.channelName !==
                                      unifiedCheckoutChannel.code &&
                                    o.channelName !== reChargeChannel.code
                                ),
                              tagSourceOverrides:
                                initialTagSourceOverrides.filter(
                                  o =>
                                    o.tagName !== initialSubscriptionTagName &&
                                    o.tagName !== recurringSubscriptionTagName
                                )
                            }
                          },
                          configMutationOptions
                        );
                      }

                      case "facebook": {
                        const initialActionSourceChannelMap =
                          details.config.actionSourceMaps.channelMap;
                        const initialActionSourceTagMap =
                          details.config.actionSourceMaps.tagMap;

                        return details.onSave(
                          {
                            ...common,
                            actionSourceMaps: {
                              channelMap: initialActionSourceChannelMap.filter(
                                i =>
                                  i.channel !== unifiedCheckoutChannel.code &&
                                  i.channel !== reChargeChannel.code
                              ),
                              tagMap: initialActionSourceTagMap.filter(
                                i =>
                                  i.tag !== initialSubscriptionTagName &&
                                  i.tag !== recurringSubscriptionTagName
                              )
                            }
                          },
                          configMutationOptions
                        );
                      }

                      default: {
                        return details.onSave(common, configMutationOptions);
                      }
                    }
                  }}
                >
                  {isStepCompleted ? "Save" : "Save & Continue"}
                </ButtonPrimary>
              </SubscriptionsActions1Wrapper>
            ) : null}
          </SubscriptionsSectionInnerWrapper>
        </StepSection>
      </PageCard>
      {sellsSubscriptionsState === "YES_UNIFIED" ||
      sellsSubscriptionsState === "YES_SEPARATE" ? (
        <PageCard>
          {sellsSubscriptionsState === "YES_SEPARATE" ? (
            <StepSection
              title="What Shopify subscription app do you use?"
              description={
                <SubscriptionsExplainer>
                  Please select the subscription app that you have installed on
                  your store. If your subscription app is not listed, please
                  select "Other" and enter the tag used on all recurring orders
                  that are automatically created.
                </SubscriptionsExplainer>
              }
            >
              <div>
                {predefinedSubscriptionApps.map((app, index) => (
                  <LabeledRadioText
                    key={index}
                    groupName={radioGroup2Name}
                    isSelected={
                      subscriptionAppSelection?.type === "PREDEFINED" &&
                      subscriptionAppSelection.app === app
                    }
                    setIsSelected={() => {
                      setSubscriptionAppSelection({ type: "PREDEFINED", app });
                    }}
                    text={app.name}
                    isDisabled={isLoading}
                    tooltip={
                      app.description
                        ? {
                            maxWidth: `${theme.gridBase * 38.5}px`,
                            render: () => (
                              <SubscriptionsTooltipContent>
                                {app.description}
                              </SubscriptionsTooltipContent>
                            )
                          }
                        : null
                    }
                  />
                ))}
                <SubscriptionsRadioItemOtherWrapper>
                  <div>
                    <LabeledRadioText
                      groupName={radioGroup2Name}
                      isSelected={isOtherSelected}
                      setIsSelected={() => {
                        setSubscriptionAppSelection({ type: "OTHER" });
                      }}
                      text="Other"
                      isDisabled={isLoading}
                    />
                    {isOtherSelected && setupGuideHref ? (
                      <StyledLinkExternal
                        href={setupGuideHref}
                        text="Where do I find this?"
                      />
                    ) : null}
                  </div>
                  {isOtherSelected ? (
                    <div>
                      <InputFieldText
                        variant="SMALL"
                        disabled={isLoading}
                        value={otherInputValue}
                        onChange={event =>
                          setOtherInputValue(event.target.value)
                        }
                        placeholder="Recurring Order Tag"
                        spellCheck={false}
                        autoCapitalize="off"
                      />
                    </div>
                  ) : null}
                </SubscriptionsRadioItemOtherWrapper>
              </div>
            </StepSection>
          ) : null}
          {sellsSubscriptionsState === "YES_SEPARATE" &&
          subscriptionAppSelection !== null ? (
            <StepSection
              showTopSeperator={true}
              title="Would you like to send first-time orders?"
              description={
                <SubscriptionsExplainer>
                  <p>
                    First-time orders are the first purchases from the
                    subscription sequence. You can choose to send recurring
                    orders to this destination or to block them.
                  </p>
                  {marketsEnabled ? (
                    <p>
                      To send these events when Shopify Markets is enabled, you
                      must select "All Markets" or include the "No Market ID"
                      option when specifying Market Groups in this destinations
                      Settings step, as these events come in without a Market
                      ID.
                    </p>
                  ) : null}
                </SubscriptionsExplainer>
              }
            >
              <div>
                <LabeledRadioText
                  groupName={radioGroup3Name}
                  isSelected={blockFirstTimeTransactions === false}
                  setIsSelected={() => {
                    setBlockFirstTimeTransactions(false);
                  }}
                  text="Yes, send"
                  isDisabled={isLoading}
                  tooltip={{
                    maxWidth: `${theme.gridBase * 27.5}px`,
                    render: () => (
                      <SubscriptionsTooltipContent>
                        We will send orders with a "
                        {firstTimeSubscriptionTagName}" order tag to this
                        destination.
                      </SubscriptionsTooltipContent>
                    )
                  }}
                />
                <LabeledRadioText
                  groupName={radioGroup3Name}
                  isSelected={blockFirstTimeTransactions === true}
                  setIsSelected={() => {
                    setBlockFirstTimeTransactions(true);
                  }}
                  text="No, block"
                  isDisabled={isLoading}
                />
              </div>
            </StepSection>
          ) : null}
          {sellsSubscriptionsState === "YES_UNIFIED" ||
          (subscriptionAppSelection !== null &&
            blockFirstTimeTransactions !== null) ? (
            <StepSection
              showTopSeperator={sellsSubscriptionsState === "YES_SEPARATE"}
              title="Would you like to send recurring orders?"
              description={
                <SubscriptionsExplainer>
                  <p>
                    Recurring orders are the scheduled repeating purchases that
                    your shopper has subscribed to. You can choose to send
                    recurring orders to this destination or to block them.
                  </p>
                  {marketsEnabled ? (
                    <p>
                      To send these events when Shopify Markets is enabled, you
                      must select "All Markets" or include the "No Market ID"
                      option when specifying Market Groups in this destinations
                      Settings step, as these events come in without a Market
                      ID.
                    </p>
                  ) : null}
                </SubscriptionsExplainer>
              }
            >
              <div>
                <LabeledRadioText
                  groupName={radioGroup4Name}
                  isSelected={blockRecurringTransactions === false}
                  setIsSelected={() => {
                    setBlockRecurringTransactions(false);
                  }}
                  text="Yes, send"
                  isDisabled={isLoading}
                  tooltip={
                    details.key === "facebook"
                      ? {
                          maxWidth: `${theme.gridBase * 39.5}px`,
                          render: () => (
                            <SubscriptionsTooltipContent>
                              All recurring transactions will be sent with an
                              action source of "System Generated". As explained{" "}
                              <LinkExternal href="https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/server-event#action-source">
                                here
                              </LinkExternal>
                              , this is recommended by Facebook - as it will
                              prevent warnings.
                            </SubscriptionsTooltipContent>
                          )
                        }
                      : null
                  }
                />
                <LabeledRadioText
                  groupName={radioGroup4Name}
                  isSelected={blockRecurringTransactions === true}
                  setIsSelected={() => {
                    setBlockRecurringTransactions(true);
                  }}
                  text="No, block"
                  isDisabled={isLoading}
                  tooltip={
                    details.key === "ga4"
                      ? {
                          maxWidth: `${theme.gridBase * 44.5}px`,
                          render: () => (
                            <SubscriptionsTooltipContent>
                              We recommend not sending recurring orders to GA4
                              as they will show up as "(not set)" in your
                              Acquisition reports - this being because there is
                              no session associated with them.
                            </SubscriptionsTooltipContent>
                          )
                        }
                      : null
                  }
                  tag={
                    details.key === "ga4"
                      ? { text: "Recommended", color: theme.palette.green }
                      : null
                  }
                />
              </div>
            </StepSection>
          ) : null}
          {(sellsSubscriptionsState === "YES_UNIFIED" ||
            (subscriptionAppSelection !== null &&
              blockFirstTimeTransactions !== null)) &&
          blockRecurringTransactions === false &&
          attributionState ? (
            <StepSection
              showTopSeperator={true}
              title="Would you like to customize the attribution source/medium of the recurring orders?"
              description={
                <SubscriptionsExplainer>
                  You can customize the source/medium of your recurring orders
                  so that you can identify & track your recurring order volume.
                  The original attribution option only works with Recharge & if
                  the "Save Order Notes" Data Layer event is enabled.
                </SubscriptionsExplainer>
              }
              media={{
                type: "VIDEO",
                url: "https://player.vimeo.com/video/618245346"
              }}
            >
              <div>
                <LabeledRadioText
                  groupName={radioGroup5Name}
                  isSelected={!attributionState.isEnabled}
                  setIsSelected={() => {
                    setAttributionState({
                      ...attributionState,
                      isEnabled: false
                    });
                  }}
                  text="Use original attribution"
                  isDisabled={isLoading}
                  tooltip={{
                    maxWidth: `${theme.gridBase * 28}px`,
                    render: () => (
                      <SubscriptionsTooltipContent>
                        Only works with Recharge & if the "Save Order Notes"
                        Data Layer event is enabled.
                      </SubscriptionsTooltipContent>
                    )
                  }}
                />
                <LabeledRadioText
                  groupName={radioGroup5Name}
                  isSelected={attributionState.isEnabled}
                  setIsSelected={() => {
                    setAttributionState({
                      ...attributionState,
                      isEnabled: true
                    });
                  }}
                  text="Use source/medium override"
                  isDisabled={isLoading}
                  tag={{ text: "Recommended", color: theme.palette.green }}
                />
              </div>
              {attributionState.isEnabled ? (
                <SubscriptionsCustomizeAttributionWrapper>
                  <SubscriptionsInputWrapper
                    labelText="Campaign Source"
                    disabled={isLoading}
                  >
                    <InputFieldText
                      variant="SMALL"
                      disabled={isLoading}
                      value={attributionState.campaignSource}
                      onChange={event => {
                        setAttributionState({
                          ...attributionState,
                          campaignSource: event.target.value
                        });
                      }}
                      placeholder="Source"
                      spellCheck={false}
                      autoCapitalize="off"
                    />
                  </SubscriptionsInputWrapper>
                  <SubscriptionsInputWrapper
                    labelText="Campaign Medium"
                    disabled={isLoading}
                  >
                    <InputFieldText
                      variant="SMALL"
                      disabled={isLoading}
                      value={attributionState.campaignMedium}
                      onChange={event => {
                        setAttributionState({
                          ...attributionState,
                          campaignMedium: event.target.value
                        });
                      }}
                      placeholder="Medium"
                      spellCheck={false}
                      autoCapitalize="off"
                    />
                  </SubscriptionsInputWrapper>
                  <SubscriptionsInputWrapper
                    labelText="Campaign Name"
                    disabled={isLoading}
                  >
                    <InputFieldText
                      variant="SMALL"
                      disabled={isLoading}
                      value={attributionState.campaignName}
                      onChange={event => {
                        setAttributionState({
                          ...attributionState,
                          campaignName: event.target.value
                        });
                      }}
                      placeholder="Name"
                      spellCheck={false}
                      autoCapitalize="off"
                    />
                  </SubscriptionsInputWrapper>
                </SubscriptionsCustomizeAttributionWrapper>
              ) : null}
            </StepSection>
          ) : null}
          {(sellsSubscriptionsState !== "YES_SEPARATE" ||
            blockFirstTimeTransactions !== null) &&
          blockRecurringTransactions !== null ? (
            <SubscriptionsActions2Wrapper>
              {details.key === "smartly" ? (
                <SubscriptionsActions2Note>
                  Note: By default, we send all transactions to Smartly. If you
                  add a customization in the Smartly Settings step, we'll send
                  recurring orders with only those source/mediums.
                </SubscriptionsActions2Note>
              ) : null}
              <Tooltip
                placement="right"
                text={
                  !isAppSelectionValid
                    ? "Select a subscription app to continue"
                    : !isOtherValid
                      ? 'Please provide a "Recurring Order Tag"'
                      : !isAttributionValid
                        ? "Fill in all campaign attribution fields"
                        : ""
                }
                disabled={
                  isAppSelectionValid && isOtherValid && isAttributionValid
                }
              >
                <SubscriptionsActions2TooltipInner>
                  <ButtonPrimary
                    variant="SMALL"
                    state={
                      isLoading
                        ? "LOADING"
                        : !isAppSelectionValid ||
                            !isOtherValid ||
                            !isAttributionValid
                          ? "DISABLED"
                          : "IDLE"
                    }
                    onClick={() => {
                      const subscriptionTagName =
                        sellsSubscriptionsState === "YES_SEPARATE"
                          ? subscriptionAppSelection?.type === "PREDEFINED"
                            ? subscriptionAppSelection.app.tagName
                            : otherInputValue
                          : recurringSubscriptionTagName;

                      const common = {
                        uses_unified_checkout:
                          sellsSubscriptionsState === "YES_UNIFIED",
                        subscriptionTagName,
                        orderFilters: {
                          channels: [
                            ...orderFilters.channels.filter(
                              c =>
                                c !== unifiedCheckoutChannel.code &&
                                c !== reChargeChannel.code
                            ),
                            ...(sellsSubscriptionsState === "YES_UNIFIED" &&
                            ((orderFilters.condition === "ALLOW" &&
                              !blockRecurringTransactions) ||
                              (orderFilters.condition === "BLOCK" &&
                                blockRecurringTransactions))
                              ? [
                                  unifiedCheckoutChannel.code,
                                  reChargeChannel.code
                                ]
                              : [])
                          ],
                          tags: [
                            ...orderFilters.tags.filter(
                              t =>
                                t !== subscriptionTagName &&
                                t !== firstTimeSubscriptionTagName &&
                                t !== initialSubscriptionTagName
                            ),
                            ...(sellsSubscriptionsState === "YES_SEPARATE" &&
                            ((orderFilters.condition === "ALLOW" &&
                              !blockFirstTimeTransactions) ||
                              (orderFilters.condition === "BLOCK" &&
                                blockFirstTimeTransactions))
                              ? [firstTimeSubscriptionTagName]
                              : []),
                            ...((orderFilters.condition === "ALLOW" &&
                              !blockRecurringTransactions) ||
                            (orderFilters.condition === "BLOCK" &&
                              blockRecurringTransactions)
                              ? [subscriptionTagName]
                              : [])
                          ]
                        }
                      };

                      switch (details.key) {
                        case "ga4": {
                          const initialChannelSourceOverrides =
                            details.config.dataConfig.channelSourceOverrides;
                          const initialTagSourceOverrides =
                            details.config.dataConfig.tagSourceOverrides;

                          return details.onSave(
                            {
                              ...common,
                              dataConfig: {
                                channelSourceOverrides: [
                                  ...initialChannelSourceOverrides.filter(
                                    o =>
                                      o.channelName !==
                                        unifiedCheckoutChannel.code &&
                                      o.channelName !== reChargeChannel.code
                                  ),
                                  ...(!blockRecurringTransactions &&
                                  sellsSubscriptionsState === "YES_UNIFIED" &&
                                  attributionState?.isEnabled
                                    ? [
                                        {
                                          channelName:
                                            unifiedCheckoutChannel.code,
                                          utm_campaign:
                                            attributionState.campaignName,
                                          utm_medium:
                                            attributionState.campaignMedium,
                                          utm_source:
                                            attributionState.campaignSource
                                        },
                                        {
                                          channelName: reChargeChannel.code,
                                          utm_campaign:
                                            attributionState.campaignName,
                                          utm_medium:
                                            attributionState.campaignMedium,
                                          utm_source:
                                            attributionState.campaignSource
                                        }
                                      ]
                                    : [])
                                ],
                                tagSourceOverrides: [
                                  ...initialTagSourceOverrides.filter(
                                    o =>
                                      o.tagName !== subscriptionTagName &&
                                      o.tagName !== initialSubscriptionTagName
                                  ),
                                  ...(!blockRecurringTransactions &&
                                  attributionState?.isEnabled
                                    ? [
                                        {
                                          tagName: subscriptionTagName,
                                          utm_campaign:
                                            attributionState.campaignName,
                                          utm_medium:
                                            attributionState.campaignMedium,
                                          utm_source:
                                            attributionState.campaignSource
                                        }
                                      ]
                                    : [])
                                ]
                              }
                            },
                            configMutationOptions
                          );
                        }

                        case "facebook": {
                          const initialActionSourceChannelMap =
                            details.config.actionSourceMaps.channelMap;
                          const initialActionSourceTagMap =
                            details.config.actionSourceMaps.tagMap;

                          return details.onSave(
                            {
                              ...common,
                              actionSourceMaps: {
                                channelMap: [
                                  ...initialActionSourceChannelMap.filter(
                                    i =>
                                      i.channel !==
                                        unifiedCheckoutChannel.code &&
                                      i.channel !== reChargeChannel.code
                                  ),
                                  ...(!blockRecurringTransactions &&
                                  sellsSubscriptionsState === "YES_UNIFIED"
                                    ? ([
                                        {
                                          channel: unifiedCheckoutChannel.code,
                                          actionSource: "system_generated"
                                        },
                                        {
                                          channel: reChargeChannel.code,
                                          actionSource: "system_generated"
                                        }
                                      ] as const)
                                    : [])
                                ],
                                tagMap: [
                                  ...initialActionSourceTagMap.filter(
                                    i =>
                                      i.tag !== subscriptionTagName &&
                                      i.tag !== initialSubscriptionTagName
                                  ),
                                  ...(!blockRecurringTransactions
                                    ? ([
                                        {
                                          tag: subscriptionTagName,
                                          actionSource: "system_generated"
                                        }
                                      ] as const)
                                    : [])
                                ]
                              }
                            },
                            configMutationOptions
                          );
                        }

                        default: {
                          return details.onSave(common, configMutationOptions);
                        }
                      }
                    }}
                  >
                    {isStepCompleted ? "Save" : "Save & Continue"}
                  </ButtonPrimary>
                </SubscriptionsActions2TooltipInner>
              </Tooltip>
            </SubscriptionsActions2Wrapper>
          ) : null}
        </PageCard>
      ) : null}
    </SubscriptionsWrapper>
  );
};

/* ========================================================================== */

const SubscriptionsWrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const SubscriptionsExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > p:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const SubscriptionsSectionInnerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const SubscriptionsTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const SubscriptionsActions1Wrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const SubscriptionsRadioItemOtherWrapper = styled.div`
  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
  }

  > div:nth-child(2) {
    margin-top: ${props => props.theme.gridBase}px;
    margin-left: ${props => props.theme.gridBase * 5}px;
  }
`;

const SubscriptionsCustomizeAttributionWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const SubscriptionsInputWrapper = styled(InputWrapper)`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const SubscriptionsActions2Wrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 3}px;
`;

const SubscriptionsActions2Note = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const SubscriptionsActions2TooltipInner = styled.span`
  display: inline-block;
`;
