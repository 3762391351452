import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import { PageCard } from "../../components/PageCard";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { ConsentMode } from "./ConsentMode";
import { destinationItrb as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-setup-elevars-iterable-integration";

type SetupFlowContext = { shopifyOAuthScopes: ShopifyOAuthScopes };

const {
  SetupFlow,
  useConfigRequired,
  useSetupFlowDetails,
  useSetupFlowContext
} = createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationItrbProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationItrb: React.FC<DestinationItrbProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      eventsConnectorConfig={eventsConnectorConfig}
      steps={[
        { details: { type: "SETTINGS" } },
        { details: { type: "EVENTS" } },
        { details: { type: "CONSENT_MODE" } },
        { details: { type: "FILTER_TRANSACTIONS" } },
        { details: { type: "SUBSCRIPTIONS" } }
      ]}
      context={{ shopifyOAuthScopes }}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
  }
};

/* ========================================================================== */

type Events = ReturnType<typeof useConfigRequired>["enabledEvents"];

const recommendedEvents: Events = {
  addToCart: true,
  viewItem: true,
  viewItemList: true,
  pageView: false,
  beginCheckout: false,
  purchase: false
};

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              The Iterable API Key is <span>configured</span>
            </>
          )
        },
        {
          step: 2,
          type: "EVENTS",
          selectedEvents: {
            enabledEvents: config.enabledEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          },
          recommendedEvents: getRecommendedEventState({
            destination,
            enabledEvents: recommendedEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          })
        },
        {
          step: 3,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 4,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        },
        {
          step: 5,
          type: "SUBSCRIPTIONS",
          filters: config.orderFilters,
          tagName: config.subscriptionTagName
        }
      ]}
      description={
        <Step0Explainer>
          Set up this server-side tracking integration to:
          <ul>
            <li>Improve Abandonment Flow performance</li>
            <li>
              Identify abandoners that your client-side tracking misses due to
              cookie limitations
            </li>
          </ul>
          <StyledLinkExternal
            href="https://www.getelevar.com/how-to/improve-klaviyo-abandoned-flow-performance/"
            text="Learn more"
          />
        </Step0Explainer>
      }
      whatsNextOverrides={{
        title: "Set Up Your Split Test",
        explainer: (
          <>
            Send messages to only the net new abandoners identified by Elevar so
            that you can see the net new revenue generated.
          </>
        ),
        media: { type: "NONE", spanContent: true },
        link: {
          href: "https://docs.getelevar.com/docs/how-to-calculate-additional-revenue-from-iterable-server-side-tracking",
          text: "How to Setup Iterable Split Test Journeys"
        }
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > ul {
    margin-top: ${props => props.theme.gridBase}px;
    margin-bottom: ${props => props.theme.gridBase * 2}px;
    padding-left: ${props => props.theme.gridBase * 2}px;

    > li {
      list-style: disc;

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase * 0.25}px;
      }
    }
  }
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [accessToken, setAccessToken] = useState(config.accessToken);

  const areIterableFieldsFilledIn = accessToken !== "";

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="Iterable Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              In order to send your data to this destination, we need the
              following information from your account.
            </Step1Explainer>
          }
        >
          <InputWrapper
            labelText="Iterable API Key"
            disabled={setupFlow.isLoading}
            tooltip={{
              maxWidth: `${theme.gridBase * 40.5}px`,
              render: () => (
                <Step1InputWrapperTooltipContent>
                  <p>
                    This is a unique identifier for your Iterable account. It
                    allows for secure authentication.
                  </p>
                  <StyledLinkExternal
                    href="https://support.iterable.com/hc/en-us/articles/360043464871-API-Keys-#creating-api-keys"
                    text="How do I create this?"
                  />
                </Step1InputWrapperTooltipContent>
              )
            }}
          >
            <InputFieldText
              variant="SMALL"
              disabled={setupFlow.isLoading}
              value={accessToken}
              onChange={event => setAccessToken(event.target.value)}
              spellCheck={false}
              autoCapitalize="off"
              placeholder="aa111aaa1a1a11a1a11aa111aa111aaa"
            />
          </InputWrapper>
        </StepSection>
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={setupFlow.isLoading}
          isStepCompleted={setupFlow.isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !areIterableFieldsFilledIn ? "Please fill out all fields" : null
          }
          onSave={async data => {
            await setupFlow.configMutation({ ...data, accessToken });
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1InputWrapperTooltipContent = styled.div`
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }

  > a {
    ${linkStyles};
  }
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { shopifyOAuthScopes } = useSetupFlowContext();

  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: {},
    webhookOverrides: config.webhookOverrides
  });
  const [eventMapping, setEventMapping] = useState(config.eventMapping);

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to Iterable.
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={setupFlow.isLoading}
        isStepCompleted={setupFlow.isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        showBreakdownByDefault={true}
        details={{
          eventState,
          setEventState,
          destinationName: destination.name,
          eventMapping,
          setEventMapping
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: {}
        }}
        onSave={options => setupFlow.configMutation(eventState, options)}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={(data, options) => setupFlow.configMutation(data, options)}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <Subscriptions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};
